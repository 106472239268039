import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function GearsDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This welcome animation doubles as a "site loading" message, and would be
        a great way to hide your page until all content is ready to go.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        Site loading messages work best in situations where your target users
        may not have high speed connections (if your website is meant to be used
        primarily by mobile users), or if you are making network requests for
        site content from several different sources.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I roll the gears into view, then spin them up into a repeating rotation,
        which would be extended until the page content is ready.
      </Typography>
    </DemoDescription>
  );
}

export default GearsDescription;
