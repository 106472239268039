import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Intro from "./Intro";
import SpaceshipDiagram from "./SpaceshipDiagram";
import MissionFlightPlan from "./MissionFlightPlan";
import Credits from "./Credits";
import ScrollTop from "../ScrollTop";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
    visibility: "hidden",
  },
}));

function PioneerTen(props) {
  const classes = useStyles();
  const animationContainerRef = React.useRef();
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = "manual";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const animationContainer = animationContainerRef.current;
    gsap.set(animationContainer, {
      autoAlpha: 1,
    });
  }, []);

  return (
    <div ref={animationContainerRef} className={classes.root}>
      <Header />
      <Intro />
      <SpaceshipDiagram />
      <MissionFlightPlan />
      <Credits />
      <ScrollTop />
    </div>
  );
}

export default PioneerTen;
