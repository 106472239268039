import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
import CustomBounce from "gsap/CustomBounce";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/welcomes/boxes.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomBounce);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    [theme.breakpoints.up("xs")]: {
      width: theme.sizes.timelineControlsContainerWidth.xSmallUp,
    },
    [theme.breakpoints.only("md")]: {
      width: theme.sizes.timelineControlsContainerWidth.medium,
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.sizes.timelineControlsContainerWidth.large,
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.sizes.timelineControlsContainerWidth.xLarge,
    },
    height: "100%",
    position: "absolute",
    transform: "translateZ(0)",
    overflow: "visible",
    visibility: "hidden",
    "& #welcome": {
      fill: theme.palette.background.paper,
    },
    "& .box": {
      fill: theme.palette.animations.welcomeBoxes.boxes,
    },
  },
}));

let mainTL;

function Boxes() {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  CustomBounce.create("myBounce", {
    strength: 0.5,
    squash: 3,
    squashID: "myBounce-squash",
  });

  function createTimeline() {
    const timelineControlsElement = timelineControlsRef.current;
    const svgElement = svgRef.current;
    const boxElements = Array.prototype.slice.call(
      document.querySelectorAll("#boxesWelcome .box")
    );

    mainTL = gsap.timeline({
      onComplete: () => {
        gsap.to(timelineControlsElement, {
          opacity: 1,
          duration: 0.4,
          delay: 0.5,
        });
        // handle window resize and initial block placement
        window.addEventListener("resize", handleResize);
      },
    });

    const stagger = 0.1;
    const duration = 1.1;
    const shuffledElements = Utilities.sattoloShuffleCopy(boxElements);

    const distance = getDistance(shuffledElements);
    gsap.set(shuffledElements, { y: -distance + "px" });

    mainTL.set(svgElement, { autoAlpha: 1 });
    mainTL.add("start");
    mainTL.to(
      shuffledElements,
      {
        y: 0,
        duration: duration,
        stagger: stagger,
        ease: "myBounce",
        transformOrigin: "50% 50%",
      },
      "start"
    );

    mainTL.to(
      shuffledElements,
      {
        duration: duration,
        stagger: stagger,
        scaleY: 0.5,
        scaleX: 1.3,
        ease: "myBounce-squash",
        transformOrigin: "bottom center",
      },
      "start"
    );

    // CONTROLS
    GSDevTools.create({
      animation: mainTL,
      visibility: "auto",
      minimal: true,
      keyboard: false,
      container: timelineControlsElement,
    });
  }

  function handleResize() {
    const boxElements = Array.prototype.slice.call(
      document.querySelectorAll("#boxesWelcome .box")
    );
    const distance = getDistance(boxElements);
    mainTL.progress(0);
    mainTL.invalidate();
    gsap.set(boxElements, { y: -distance + "px" });
  }

  function getDistance(boxElements) {
    const height = boxElements[0].getBoundingClientRect().height;
    const heightAdjustment = height / 18.5;
    const distance = Math.max(57, window.innerHeight / 2 / heightAdjustment);
    return distance;
  }

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;

    // SETUP
    Utilities.centerElement(svgElement, 45);

    // ANIMATE
    createTimeline();

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      {<SVG ref={svgRef} className={classes.svg} />}
    </FullscreenAnimationDemo>
  );
}

export default Boxes;
