import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import gsap from "gsap";

const navLinks = [
  { title: "home", path: "/" },
  { title: "welcomes", path: "/welcomes" },
  { title: "scrollers", path: "/scrollers" },
  { title: "logos", path: "/logos" },
  { title: "about", path: "/about" },
];

function SiteNavigation(props) {
  function handleLinkInput(e) {
    const link = e.currentTarget;
    if (link.classList.contains("current-page")) {
      e.preventDefault();
      props.closeDrawer();
    } else {
      const hideTL = props.hideContent();
      const showTL = props.showContent();
      const tl = gsap.timeline();
      tl.add(hideTL);
      tl.add(props.closeDrawer, "-=0.1");
      tl.call(() => {
        window.scrollTo(0, 0);
      });
      tl.add(showTL, "+=0.1");
    }
  }

  const useStyles = makeStyles((theme) => ({
    navigationList: {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: "200px",
      "& .current-page": {
        color: theme.palette.navigation.activePage,
      },
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  }));

  const activePageClassName = "current-page";
  const classes = useStyles();

  return (
    <div>
      <List component="nav" className={classes.navigationList}>
        {navLinks.map(({ title, path }) => (
          <div key={navLinks.findIndex((x) => x.title === title).toString()}>
            <NavLink
              exact
              to={path}
              className={classes.link}
              activeClassName={activePageClassName}
              onClick={handleLinkInput}
            >
              <ListItem button>
                <ListItemText primary={title} />
              </ListItem>
            </NavLink>
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );
}

export default SiteNavigation;
