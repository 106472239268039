import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/top-bottom.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    width: "80%",
    maxWidth: "600px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "800px",
    },
    height: "auto",
    position: "absolute",
    overflow: "visible",
    "& path": {
      position: "absolute",
      stroke: "none",
    },
    "& #topLine, #topOverlay, #centerLine": {
      fill: theme.palette.animations.topBottom.topLineStart,
    },
    "& #topBackground, #bottomLine": {
      fill: theme.palette.background.paper,
    },
    "& #bottomBackground": {
      fill: theme.palette.animations.topBottom.bottomBackgroundStart,
    },
    "& #topLineFinal": {
      fill: theme.palette.background.paper,
      // fill: "green",
      visibility: "hidden",
    },
    "& #topBackgroundFinal": {
      fill: theme.palette.text.primary,
      // fill: "red",
      visibility: "hidden",
    },
    "& #topOverlayFinal": {
      fill: theme.palette.background.paper,
    },
    "& #bottomLineFinal": {
      fill: theme.palette.text.primary,
      visibility: "hidden",
    },
  },
}));

function TopBottom() {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;

    const top = document.querySelector("#topBottom #top");
    const topLine = document.querySelector("#topBottom #topLine");
    const topBackground = document.querySelector("#topBottom #topBackground");
    const topLineFinal = document.querySelector("#topBottom #topLineFinal");
    const topBackgroundFinal = document.querySelector(
      "#topBottom #topBackgroundFinal"
    );
    const topOverlay = document.querySelector("#topBottom #topOverlay");
    const finalOverlay = document.querySelector("#topBottom #topOverlayFinal");
    const bottom = document.querySelector("#topBottom #bottom");
    const bottomLine = document.querySelector("#topBottom #bottomLine");
    const bottomLineFinal = document.querySelector(
      "#topBottom #bottomLineFinal"
    );
    const bottomBackground = document.querySelector(
      "#topBottom #bottomBackground"
    );
    const centerLine = document.querySelector("#topBottom #centerLine");

    // SETUP
    Utilities.centerElement(svgElement);
    gsap.set(centerLine, {
      scaleY: 0,
      stroke: "none",
      transformOrigin: "50% 50%",
    });
    gsap.set(finalOverlay, { autoAlpha: 0 });

    // ANIMATION
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add(makeShapesTL(top, topOverlay, bottomBackground));
    mainTL.add(
      makeTransitionTL(top, topOverlay, bottomBackground, centerLine),
      "+=0.5"
    );
    mainTL.add(
      makeUDFCL(
        top,
        topLineFinal,
        topOverlay,
        finalOverlay,
        topBackgroundFinal,
        bottom,
        bottomLineFinal,
        centerLine,
        [topLine, topBackground, bottomLine, bottomBackground]
      ),
      "+=0.4"
    );

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      {<SVG ref={svgRef} className={classes.svg} />}
    </FullscreenAnimationDemo>
  );
}

function makeShapesTL(top, topOverlay, bottomBackground) {
  const mainTL = gsap.timeline();
  mainTL.set(top, {
    scaleX: 0,
    transformOrigin: "0% 50%",
  });
  mainTL.set(bottomBackground, { scaleY: 0, transformOrigin: "50% 0%" });
  mainTL.add("start", 0.2);
  mainTL.add("show text", 1.2);

  const startDuration = 0.8;
  mainTL.to(
    top,
    { duration: startDuration, scaleX: 1, ease: "back.out(0.3)" },
    "start"
  );
  const textDuration = 0.5;
  mainTL.to(
    topOverlay,
    {
      scaleY: 0,
      duration: textDuration,
      transformOrigin: "50% 100%",
    },
    "show text"
  );
  mainTL.to(
    bottomBackground,
    {
      scaleY: 1,
      duration: textDuration,
      transformOrigin: "50% 0%",
      ease: "back.out(0.4)",
    },
    "show text"
  );

  return mainTL;
}

function makeTransitionTL(top, topOverlay, bottom, centerLine) {
  const mainTL = gsap.timeline();
  mainTL.add("start", 0);

  const scaleDownDuration = 0.5;
  mainTL.to(
    topOverlay,
    {
      scaleY: 1,
      duration: scaleDownDuration,
      transformOrigin: "50% 100%",
    },
    "start"
  );
  mainTL.to(
    bottom,
    {
      scaleY: 0,
      duration: scaleDownDuration,
      transformOrigin: "50% 0%",
    },
    "start"
  );
  mainTL.to(top, {
    scaleY: 0.01,
    duration: scaleDownDuration,
    transformOrigin: "50% 100%",
    ease: "back.in(0.4)",
  });
  mainTL.to(
    centerLine,
    {
      scaleY: 1,
      duration: 0.2,
      transformOrigin: "50% 50%",
      ease: "back.out",
    },
    "-=0.1"
  );

  return mainTL;
}

function makeUDFCL(
  top,
  topLine,
  topOverlay,
  finalOverlay,
  topBackground,
  bottom,
  bottomLine,
  centerLine,
  junk
) {
  const mainTL = gsap.timeline();

  // cleanup junk
  mainTL.set(junk, { autoAlpha: 0 });

  // setup
  // bottom
  mainTL.set(bottom, { yPercent: -100, autoAlpha: 0 });
  mainTL.set(bottomLine, { autoAlpha: 1 });
  // top
  mainTL.set(topOverlay, { autoAlpha: 0 });
  mainTL.set(finalOverlay, { autoAlpha: 1 });
  mainTL.set(topBackground, {
    scale: 0.9,
    autoAlpha: 1,
    transformOrigin: "50% 50%",
  });
  mainTL.set(topLine, { autoAlpha: 1 });
  mainTL.set(top, { scaleY: 1, yPercent: 100 });

  // animate
  const ease = "back.out(1.5)";
  mainTL.to(top, { yPercent: 0, ease: ease });
  mainTL.set(finalOverlay, { autoAlpha: 0 });
  mainTL.set(bottom, { autoAlpha: 1 });
  mainTL.to(bottom, { yPercent: 0, ease: ease });
  mainTL.to(centerLine, { scaleX: 0, ease: "back.in(0.7)" });

  return mainTL;
}

export default TopBottom;
