import React, { forwardRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import gsap from "gsap/gsap-core";

const Block = forwardRef((props, ref) => {
  const theme = useTheme();

  const color = props.color
    ? props.color
    : gsap.utils.random([
        theme.palette.animatedBackground.blockColor1,
        theme.palette.animatedBackground.blockColor2,
        theme.palette.animatedBackground.blockColor3,
        theme.palette.animatedBackground.blockColor4,
        theme.palette.animatedBackground.blockColor5,
      ]);

  const useStyles = makeStyles((theme) => ({
    root: {
      background: color,
      position: "absolute",
      visibility: "hidden",
      display: "none"
    },
  }));
  const classes = useStyles();

  return <div className={classes.root} ref={ref}></div>;
});

export default Block;
