import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function BoxesDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        For this welcome animation, I drop SVG boxes to reveal text the same
        color as the background. I used GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Eases/CustomBounce"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          CustomBounce
        </Link>{" "}
        plugin for the squish effect on the boxes.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I like the effect of revealing text with contrasting background
        elements. It's a simple trick with many uses. I wrote a similiar effect
        years ago for a welcome animation with the now defunct{" "}
        <Link
          href="http://deprecated.famous.org"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          famo.us
        </Link>{" "}
        JavaScript animation engine.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        The re-write with CustomBounce and SVG is a nice update. I used SVG
        elements for the boxes so they keep clean lines at any resolution, even
        while stretching out and squishing.
      </Typography>
    </DemoDescription>
  );
}

export default BoxesDescription;
