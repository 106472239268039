import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as PioneerTenMissionFlightPlan } from "../../../img/scrollers/pioneer-10/Pioneer-10-mission-optimized.svg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as Utilities from "./AnimationUtilities";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "1em",
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
    minHeight: "90vh",
    position: "relative",
    pointerEvents: "none",
    visibility: "hidden",
  },
  titleContainer: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1000,
    backgroundColor:
      theme.palette.animations.pioneer10.sectionTitleBackground + " !important",
    paddingTop: "10px",
    paddingBottom: "10px",
    visibility: "hidden",
  },
  title: {},
  svgContainer: {
    marginTop: "2em",
    overflow: "visible",
  },
  svg: {
    width: "90%",
    maxWidth: "1400px",
    height: "auto",
    position: "absolute",
    "& #points-of-interest #leader-lines path, #Flight-path-beyond-Jupiter #Beyond-Jupiter-Leader-Line":
      {
        stroke:
          theme.palette.animations.pioneer10.diagramLeaderLinesAndPoints +
          " !important",
      },
    "& #points-of-interest #points": {
      fill:
        theme.palette.animations.pioneer10.diagramLeaderLinesAndPoints +
        " !important",
    },
    "& #Asteroid-Belt-Label, #points-of-interest #labels": {
      fill: theme.palette.text.primary + " !important",
    },
    "& #Earth-Orbit, #Jupiter-Orbit, #Flight-path-to-Jupiter, #Flight-path-beyond-Jupiter #Beyond-Jupiter-Path":
      {
        stroke: theme.palette.text.primary + " !important",
      },
    "& #Flight-path-beyond-Jupiter #Beyond-Jupiter-Arrow": {
      fill: theme.palette.text.primary + " !important",
    },
    "& #Flight-path-beyond-Jupiter .label path": {
      stroke: "none !important",
      fill: theme.palette.text.primary + " !important",
    },
  },
}));

function MissionFlightPlan(props) {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    const container = containerRef.current;
    const svg = svgRef.current;
    const title = titleRef.current;
    Utilities.centerElement(svg, 45);

    // move svg below title
    const menuBar = document.querySelector(".MuiToolbar-root");
    gsap.to(svg, { y: "+=" + menuBar.offsetHeight });

    // reveal the elements
    const revealTL = gsap.timeline();
    revealTL.to(container, {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: title,
        start: "top 66%",
        end: "top 75px",
        scrub: true,
      },
    });

    // GET and SETUP ANIMATION ELEMENTS
    // FLIGHT PATHS
    const flightPathToJupiter = document.querySelector(
      "#Flight-path-to-Jupiter"
    );
    const flightPathBeyondJupiter = document.querySelector(
      "#Flight-path-beyond-Jupiter"
    );

    // Points of interest from Earth to Jupiter
    const pointsOfInterestAll = document.querySelector("#points-of-interest");
    const poi = {
      lines: pointsOfInterestAll.children[0],
      points: pointsOfInterestAll.children[1],
      labels: pointsOfInterestAll.children[2],
      labelOverlays: pointsOfInterestAll.children[3],
    };

    // Beyond Jupiter
    const beyondJupiter = {
      arrow: flightPathBeyondJupiter.children[0],
      line: flightPathBeyondJupiter.children[1],
      label: flightPathBeyondJupiter.children[2],
      labelOverlay: flightPathBeyondJupiter.children[3],
      flightPath: flightPathBeyondJupiter.children[4],
    };

    // Zooms
    const zoomTweens = _getZoomTweens(svg);

    // ANIMATE
    const tl = gsap.timeline();

    // Earth to Jupiter
    gsap.set(flightPathToJupiter, { drawSVG: "0%" });
    tl.set([poi.labelOverlays], {
      autoAlpha: 0,
    });

    // Flight Path
    // To Jupiter
    const flightPathPOI = [
      "6.4%",
      "9.25%",
      "12%",
      "14.8%",
      "17.8%",
      "96.25%",
      "100%",
    ];
    const pointDuration = 0.2;
    const pointEase = "back.out(1.5)";
    const lineDuration = 0.2;
    const labelCharDuration = 0.08;

    // reveal title
    tl.to(title, { autoAlpha: 1 });

    // launch
    tl.add(zoomTweens.launch, "+=1");

    for (let i = 0; i < poi.points.children.length; i++) {
      // zoom view for longest flight path segment
      if (i === poi.points.children.length - 1) {
        tl.add(zoomTweens.middle);
      }
      tl.to(flightPathToJupiter, { drawSVG: flightPathPOI[i] });
      tl.from(poi.points.children[i], {
        scale: 0,
        transformOrigin: "50% 50%",
        ease: pointEase,
        duration: pointDuration,
      });
      tl.from(poi.lines.children[i], { drawSVG: "0%", duration: lineDuration });
      tl.from(poi.labels.children[i].children, {
        autoAlpha: 0,
        stagger: 0.025,
        duration: labelCharDuration,
      });
    }

    tl.to(flightPathToJupiter, {
      drawSVG: flightPathPOI[poi.points.children.length],
    });

    // Beyond Jupiter
    tl.add(zoomTweens.end);
    tl.from(beyondJupiter.flightPath, { drawSVG: "0%" });
    tl.from(beyondJupiter.arrow, { autoAlpha: 0, duration: 0.1 }, "-=0.05");
    tl.from(beyondJupiter.line, { drawSVG: "0%", duration: lineDuration });
    tl.from(beyondJupiter.label.children, {
      autoAlpha: 0,
      stagger: 0.01,
      duration: labelCharDuration,
    });

    // the end
    tl.add(zoomTweens.centerTheView);

    // wait for a beat while hiding the title
    tl.to(title, { autoAlpha: 0, duration: 2 });

    // add ScrollTrigger for main timeline
    ScrollTrigger.create({
      animation: tl,
      trigger: container,
      pin: true,
      anticipatePin: 1,
      start: "top " + menuBar.offsetHeight + "px",
      end: "+=" + window.innerHeight * 3,
      scrub: 0.5,
      toggleActions: "play reset play reset",
    });

    // fade container out on scroll after un-pin
    const endTL = gsap.timeline();
    endTL.to(container, { autoAlpha: 0 });
    ScrollTrigger.create({
      animation: endTL,
      trigger: container,
      anticipatePin: 1,
      start: "bottom 90%",
      end: "+=" + window.innerHeight * 0.5,
      scrub: 0.5,
      toggleActions: "play reset play reset",
      invalidateOnRefresh: true,
    });

    // Sun
    const sun = document.querySelector("#Sun");
    const sunTL = _getSunTimeline(sun);
    sunTL.play();
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.gridContainer}
      container
      justify="center"
    >
      <Grid item xs={12} className={classes.titleContainer} ref={titleRef}>
        <Typography className={classes.title} variant="h4" align="center">
          Mission Flight Plan
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <PioneerTenMissionFlightPlan ref={svgRef} className={classes.svg} />
      </Grid>
    </Grid>
  );
}

// Animation Functions
// tweens to center the view on each point of interest
function _getZoomTweens(svgElement) {
  const duration = 2.2;
  const zoomInEase = "Power2.inOut";
  const zoomOutEase = "Power2.inOut";
  const smallScales = [2, 1.75, 2.25, 1];
  const largeScales = [1.5, 1.25, 1.3, 1];
  const smallXPercents = [-50, -70, -120, -50];
  const smallYPercents = [-50, -60, -80, -50];
  const largeXPercents = [-50, -60, -75, -50];
  const largeYPercents = [-60, -60, -80, -50];

  let scales, xPercents, yPercents;
  if (window.innerWidth > 900) {
    scales = largeScales;
    xPercents = largeXPercents;
    yPercents = largeYPercents;
  } else {
    scales = smallScales;
    xPercents = smallXPercents;
    yPercents = smallYPercents;
  }

  const launch = gsap.to(svgElement, {
    scale: scales[0],
    yPercent: yPercents[0],
    xPercent: xPercents[0],
    ease: zoomInEase,
    duration: duration,
  });

  const middle = gsap.to(svgElement, {
    scale: scales[1],
    yPercent: yPercents[1],
    xPercent: xPercents[1],
    ease: zoomInEase,
    duration: duration,
  });

  const end = gsap.to(svgElement, {
    scale: scales[2],
    yPercent: yPercents[2],
    xPercent: xPercents[2],
    ease: zoomInEase,
    duration: duration,
  });

  const centerTheView = gsap.to(svgElement, {
    scale: scales[3],
    xPercent: xPercents[3],
    yPercent: yPercents[3],
    duration: duration,
    ease: zoomOutEase,
  });

  return {
    launch: launch,
    middle: middle,
    end: end,
    centerTheView: centerTheView,
  };
}

function _getSunTimeline(sun) {
  const tl = gsap.timeline({ paused: true });
  tl.to(
    sun,
    {
      rotate: "+=360",
      transformOrigin: "50% 50%",
      duration: 23,
      repeat: -1,
      ease: "none",
    },
    0
  );
  tl.to(
    sun,
    {
      scale: 1.73,
      transformOrigin: "50% 50%",
      duration: 7,
      repeat: -1,
      yoyo: true,
      ease: "none",
    },
    0
  );
  return tl;
}

export default MissionFlightPlan;
