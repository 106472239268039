import React, { forwardRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap/gsap-core";

const useStyles = makeStyles((theme) => ({
  root: {
    background: () =>
      gsap.utils.random([
        theme.palette.animations.circles.circleColor1,
        theme.palette.animations.circles.circleColor3,
        theme.palette.animations.circles.circleGradient,
      ]),
    borderRadius: "1000px",
    position: "absolute",
    visibility: "hidden",
    willChange: "scale, opacity",
  },
}));

const Circle = forwardRef((props, ref) => {
  useEffect(() => {
    // setup
    let circleSize = props.size + "px";
    if (!props.size || props.size === 0) {
      const baseSize = window.innerWidth * 0.5;
      circleSize = baseSize < 500 ? baseSize + "px" : "333px";
    }
    const circle = ref.current;
    gsap.set(circle, {
      height: circleSize,
      width: circleSize,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return <div className={classes.root} ref={ref}></div>;
});

export default Circle;
