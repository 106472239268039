import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function LineDrawingDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This welcome animation uses{" "}
        <Link
          href="https://greensock.com/drawsvg/"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          GSAP's DrawSVG Plugin
        </Link>{" "}
        to reveal a message.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I started with a single line font to get stroke only paths to use with
        DrawSVG. I cut the letters into separate paths, then animate those paths
        in overlapping timelines. Finally, I replace the drawn letter with a
        single path version of that letter, to avoid any gaps or misalignments.
        This final version (green in dark mode, blue in light mode) fades in
        slowly to complete the animation.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        This effect can be customized for a welcome message, site tagline or
        company name with any single line font. I'm currently using this as the
        welcome animation for{" "}
        <Link
          href="https://jonathanharrison.us"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          my site
        </Link>
        .
      </Typography>
    </DemoDescription>
  );
}

export default LineDrawingDescription;
