import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import gsap from "gsap";
import GSDevTools from "gsap/GSDevTools";
import TimelineControlsContainer from "./TimelineControlsContainer";

gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100% - 56px)",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    zIndex: "100",
    overflow: "hidden",
  },
  animationContainer: {
    position: "relative",
    height: "100%",
    visibility: "hidden",
  },
}));

const FullscreenAnimationDemo = React.forwardRef((props, ref) => {
  const animationContainerRef = React.useRef();
  const timelineControlsRef = props.allRefs.timelineControlsRef;

  useEffect(() => {
    // ELEMENTS
    const animationContainer = animationContainerRef.current;

    // SETUP
    document.querySelector("body").style.overflow = "hidden";
    document.querySelector("html").style.overflow = "hidden";

    // ANIMATE
    const mainTL = gsap.timeline();
    mainTL.to(animationContainer, { autoAlpha: 1, duration: 0.2 });

    return () => {
      const body = document.querySelector("body");
      const html = document.querySelector("html");
      body.style.overflowY = "initial";
      html.style.overflowY = "initial";
      body.style.overflowX = "hidden";
      html.style.overflowX = "hidden";
    };
  }, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.gridContainer}
      container
      justify="center"
      alignItems="center"
    >
      <Grid
        ref={animationContainerRef}
        className={classes.animationContainer}
        item
        // xs={11}
        xs={12}
        // lg={8}
        align="center"
      >
        {props.children}
        <TimelineControlsContainer
          ref={timelineControlsRef}
        ></TimelineControlsContainer>
      </Grid>
    </Grid>
  );
});

export default FullscreenAnimationDemo;
