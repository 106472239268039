import React, { useEffect } from "react";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnimatedBackgroundCircles from "./AnimatedBackgroundCircles";
import ScrollTop from "../../ScrollTop";
import PlaceholderText from "../../../../PlaceholderText";
import gsap from "gsap/gsap-core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflowX: "hidden",
    minHeight:
      theme.palette.animations.animatedScrollingBackground.contentMinHeight,
    visibility: "hidden",
  },
  contentContainer: {
    position: "relative",
    zIndex: "100",
    marginTop: "35px",
  },
  title: {
    paddingTop: "50px",
    paddingBottom: "50px",
    position: "relative",
    zIndex: 10,
    background: theme.palette.animations.circles.header,
  },
  italicize: {
    fontStyle: "italic",
  },
  quote: {
    marginLeft: theme.spacing(2),
    fontStyle: "italic",
  },
}));

function ContentWithAnimatedBackground(props) {
  const containerRef = React.useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.to(containerRef.current, { autoAlpha: 1, duration: 0.3 });
  }, []);

  const classes = useStyles(props);
  return (
    <div ref={containerRef} className={classes.root}>
      <ScrollTop />
      <AnimatedBackgroundCircles />
      <div className={classes.title}>
        <Typography variant="h3" align="center">
          Animated Background
        </Typography>
      </div>
      {/* <Divider /> */}
      <Grid className={classes.contentContainer} container justify="center">
        <Grid item xs={12} md={8} lg={6}>
          <Container>
            <Typography variant="h5" align="left" gutterBottom>
              Infinite background animation: elements change their opacity and
              scale, but only move when the user scrolls.
            </Typography>
            <Typography align="left" gutterBottom>
              Overlapping colors create fresh contrasts for text as the user
              scrolls down the page. Check out the effect in light and dark
              mode.
            </Typography>
            <Typography align="left" gutterBottom>
              This is a great way to add some flair to a blog or long blocks of
              text, without constant movement to distract while the user is
              reading. Paragraphs could be given a transparent background color
              if the effect makes the text difficult to read.
            </Typography>
            <Typography variant="h5" align="left" gutterBottom>
              Here's some text from{" "}
              <Link
                href="http://m.joyceproject.com/chapters/proteus.html"
                target="_blank"
                rel="noopener"
                color="inherit"
                className={classes.italicize}
              >
                Ulysses
              </Link>{" "}
              to fill out the page:
            </Typography>
            <PlaceholderText />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContentWithAnimatedBackground;
