import React from "react";
import { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScrollTopButton = withStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "8px",
    right: "20px",
    zIndex: 1001,
    fontWeight: "400",
    visibility: "hidden",
    color: theme.palette.animations.elements.scrollTop.textColor,
    backgroundColor:
      theme.palette.animations.elements.scrollTop.backgroundColor,
    opacity: 0.95,
    "&:hover": {
      backgroundColor: theme.palette.animations.elements.scrollTop.hoverColor,
      opacity: 1,
    },
  },
}))(Button);

function ScrollTop() {
  const buttonRef = React.useRef();

  useEffect(() => {
    // elements
    const buttonElement = buttonRef.current;
    const parentElement = buttonElement.parentElement;

    // setup
    const mainTL = gsap.timeline();

    // animate
    mainTL.from(buttonElement, { autoAlpha: 0 });

    ScrollTrigger.create({
      animation: mainTL,
      trigger: parentElement,
      start: "top -" + window.innerHeight * 0.1,
      end: "+=55",
      scrub: 1,
      toggleActions: "play reset play reset",
    });
  }, []);

  return (
    <ScrollTopButton
      ref={buttonRef}
      variant="contained"
      onClick={() => {
        handleClick(buttonRef);
      }}
      startIcon={<ArrowUpwardRoundedIcon />}
    >
      TOP
    </ScrollTopButton>
  );
}

function handleClick(buttonRef) {
  const button = buttonRef.current;
  const scrollContainer = button.parentElement;
  const mainTL = gsap.timeline();
  mainTL.to(
    scrollContainer,
    {
      autoAlpha: 0,
      duration: 0.2,
      onComplete: () => {
        gsap.set(button, { x: "+=1000" });
        window.scrollTo(0, 0);
      },
    },
    0
  );
  mainTL.to(scrollContainer, { autoAlpha: 1, duration: 0.2 }, "+=0.2");
  mainTL.set(button, { x: 0 }, "+=0.5");
}

export default ScrollTop;
