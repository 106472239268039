import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const COLORS = {
  seventeen: {
    top: "#003233",
    middle: "#013179",
    bottom: "#003357",
  },
  untitled: {
    top: "#e58805",
    middle: "#ea9700",
    bottom: "#e7530e",
  },
  sixtyOne: {
    top: "#5d353e",
    middle: "#403c87",
    bottom: "#4b4361",
  },
  blackRedAndBlack: {
    top: "#251216",
    middle: "#670000",
    bottom: "#1c0f11",
  },
};

let COLOR = COLORS.seventeen;
let INIT = true;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100vh",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  colorBlock: {
    width: "101%",
    position: "absolute",
  },
  no17Top: {
    backgroundColor: COLOR.top,
    zIndex: 10,
  },
  no17Middle: {
    backgroundColor: COLOR.middle,
    zIndex: 1,
  },
  no17Bottom: {
    backgroundColor: COLOR.bottom,
    zIndex: 2,
  },
}));

function AnimatedBackgroundRothko(props) {
  const containerRef = React.useRef();
  const no17TopRef = React.useRef();
  const no17MiddleRef = React.useRef();
  const no17BottomRef = React.useRef();
  INIT = props.init;

  useEffect(() => {
    // ELEMENTS
    const containerElement = containerRef.current;
    const no17 = {
      top: no17TopRef.current,
      middle: no17MiddleRef.current,
      bottom: no17BottomRef.current,
    };

    if (props.colorScheme) {
      COLOR = COLORS[props.colorScheme];
      const backgroundColors = [COLOR.top, COLOR.middle, COLOR.bottom];
      const duration = 0.2;

      gsap.to([no17.top, no17.middle, no17.bottom], {
        backgroundColor: (i) => backgroundColors[i],
        duration: duration,
      });
    }

    if (INIT) {
      // Animate
      setupAnimation(containerElement, no17);
      INIT = false;
    }
  }, [props.colorScheme]);

  const classes = useStyles();

  return (
    <div id="rothkoContainer" className={classes.root} ref={containerRef}>
      <div
        ref={no17TopRef}
        className={[classes.no17Top, classes.colorBlock].join(" ")}
      ></div>
      <div
        ref={no17MiddleRef}
        className={[classes.no17Middle, classes.colorBlock].join(" ")}
      ></div>
      <div
        ref={no17BottomRef}
        className={[classes.no17Bottom, classes.colorBlock].join(" ")}
      ></div>
    </div>
  );
}

export default AnimatedBackgroundRothko;

function firstTransform(colorBlocks) {
  const colorBlockScaleValues = [0.17, 0.84, 0.11];
  const mainTL = gsap.timeline();
  mainTL.add("start", 0);

  mainTL.to(
    colorBlocks.top,
    {
      height: colorBlockScaleValues[0] * 100 + "%",
      transformOrigin: "50% 0%",
    },
    "start"
  );
  mainTL.to(
    colorBlocks.middle,
    {
      height: colorBlockScaleValues[1] * 100 + "%",
      transformOrigin: "50% 100%",
    },
    "start"
  );
  mainTL.to(
    colorBlocks.bottom,
    {
      height: colorBlockScaleValues[2] * 100 + "%",
      transformOrigin: "50% 100%",
    },
    "start"
  );

  return mainTL;
}

function secondTransform(colorBlocks) {
  const colorBlockScaleValues = [0.23, 0.78, 0.69];
  const mainTL = gsap.timeline();
  mainTL.add("start", 0);

  mainTL.to(
    colorBlocks.top,
    {
      height: colorBlockScaleValues[0] * 100 + "%",
      transformOrigin: "50% 0%",
    },
    "start"
  );
  mainTL.to(
    colorBlocks.middle,
    {
      height: colorBlockScaleValues[1] * 100 + "%",
      transformOrigin: "50% 100%",
    },
    "start"
  );
  mainTL.to(
    colorBlocks.bottom,
    {
      height: colorBlockScaleValues[2] * 100 + "%",
      transformOrigin: "50% 100%",
    },
    "start"
  );

  return mainTL;
}

function setupAnimation(containerElement, no17) {
  // starting positions
  const colorBlockStartingValues = [0.66, 0.35, 0.26];
  gsap.set(no17.top, {
    height: colorBlockStartingValues[0] * 100 + "%",
    top: 0,
    left: 0,
  });
  gsap.set(no17.middle, {
    height: colorBlockStartingValues[1] * 100 + "%",
    bottom: 0,
    left: 0,
  });
  gsap.set(no17.bottom, {
    height: colorBlockStartingValues[2] * 100 + "%",
    bottom: 0,
    left: 0,
  });

  // ANIMATE
  const mainTL = gsap.timeline();
  mainTL.add(firstTransform(no17), 0);
  mainTL.add(secondTransform(no17));

  ScrollTrigger.create({
    animation: mainTL,
    trigger: containerElement.parentElement,
    start: "top 50px",
    end: "bottom bottom",
    scrub: 0.6,
    toggleActions: "play reset play reset",
  });

  return mainTL;
}
