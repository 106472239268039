import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import CustomEase from "gsap/CustomEase";
import CustomWiggle from "gsap/CustomWiggle";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/welcomes/circuit-board.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomWiggle);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    height: "100%",
    width: "auto",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    "& *": {
      stroke: "#191919",
    },
  },
}));

function CircuitBoard(props) {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;
    Utilities.centerElement(svgElement);

    const circuitPaths = document.querySelectorAll("#circuits path");

    const cpuNodes = document.querySelectorAll("#circuitBoard #cpuNodes path");
    gsap.set(cpuNodes, {
      drawSVG: "100% 100%",
      autoAlpha: 0.2,
    });
    const nodes = Utilities.sattoloShuffleCopy(gsap.utils.toArray(cpuNodes));

    const screen = document.querySelectorAll("#circuitBoard #screen");
    gsap.set(screen, { stroke: "none" });
    const screenGlow = document.querySelectorAll("#circuitBoard #screenGlow");

    const welcomeText = document.querySelectorAll(
      "#circuitBoard #welcomeText path"
    );
    const cursorGroup = document.querySelector("#circuitBoard #cursors");
    const cursors = document.querySelectorAll("#circuitBoard #cursors path");

    gsap.set([screen, screenGlow, welcomeText, cursorGroup], {
      autoAlpha: 0,
    });

    // ANIMATION
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.to(svgElement, { autoAlpha: 1, duration: 0.3 });

    const circuitsTL = makeCircuitsTL(circuitPaths);
    const cpuTL = makeCPUTL(nodes);
    const screenTL = makeScreenTL(screen, screenGlow);
    const textTL = makeTextTL(welcomeText, cursorGroup, cursors);

    mainTL.add(circuitsTL);
    mainTL.add(cpuTL, 3.5);
    mainTL.add(screenTL);
    mainTL.add(textTL, "+=0.22");

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <SVG ref={svgRef} className={classes.svg} />
    </FullscreenAnimationDemo>
  );
}

function makeCircuitsTL(circuits) {
  const circuitsTL = gsap.timeline();
  gsap.set(circuits, { drawSVG: "0%" });
  circuitsTL.to(circuits, {
    duration: 2.5,
    drawSVG: "100%",
    stagger: 0.01,
  });

  return circuitsTL;
}

function makeCPUTL(nodes) {
  const cpuTL = gsap.timeline();
  cpuTL.to(nodes, {
    duration: 0.4,
    drawSVG: "0% 100%",
    stagger: 0.02,
  });
  cpuTL.to(nodes, { autoAlpha: 1, stagger: 0.01, duration: 0.3 });

  return cpuTL;
}

function makeScreenTL(screen, glow) {
  const screenTL = gsap.timeline();
  screenTL.set(glow, {
    stroke: "none",
    transformOrigin: "50% 50%",
  });
  CustomWiggle.create("blink", { wiggles: 4, type: "uniform" });
  screenTL.to(screen, { autoAlpha: 0.9, duration: 0.4, ease: "blink" });
  screenTL.add("on");
  screenTL.to(screen, { autoAlpha: 0.97, duration: 0.2 }, "on");
  screenTL.to(
    glow,
    {
      autoAlpha: 0.4,
      duration: 0.2,
      transformOrigin: "50% 50%",
    },
    "on+=0.2"
  );
  return screenTL;
}

function makeTextTL(text, cursorGroup, cursors) {
  const textTL = gsap.timeline();
  gsap.set(cursorGroup, { autoAlpha: 1 });
  gsap.set(cursors, { autoAlpha: 0 });

  for (let i = 0; i < cursors.length; i++) {
    textTL.set(
      cursors[i],
      { autoAlpha: 1 },
      "+=" + gsap.utils.random(0.04, 0.06)
    );
    textTL.set(
      cursors[i],
      { autoAlpha: 0 },
      "+=" + gsap.utils.random(0.04, 0.06)
    );
    textTL.set(text[i], { autoAlpha: 1 }, "+=" + gsap.utils.random(0.04, 0.06));
  }

  return textTL;
}

export default CircuitBoard;
