import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/hillside.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: "80%",
      width: "auto",
    },
    [`${theme.breakpoints.up("xs")} and (orientation: portrait)`]: {
      height: "80%",
      width: "auto",
    },
    [`${theme.breakpoints.only("xs")} and (orientation: portrait)`]: {
      width: "80%",
      height: "auto",
    },
    [`${theme.breakpoints.only("sm")} and (orientation: portrait)`]: {
      width: "80%",
      height: "auto",
      maxHeight: "80vh",
    },
    position: "absolute",
    transform: "translateZ(0)",
    overflow: "hidden",
    "& #textElement": {
      position: "absolute",
      willChange: "transform",
    },
  },
}));

function Hillside(props) {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ref elements
    const svgElement = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;

    // animation elements
    const roofShadow = document.querySelector("#apartmentBuilding #roofShadow");
    const buildingShadow = document.querySelector(
      "#apartmentBuilding #buildingShadow"
    );
    const dayToNightElements = document.querySelectorAll(
      "#apartmentBuilding .day-night"
    );
    const sky = document.querySelector("#apartmentBuilding #sky");
    const hillLeft = document.querySelector("#apartmentBuilding #hillLeft");
    const hillRight = document.querySelector("#apartmentBuilding #hillRight");
    const stars = document.querySelectorAll(
      "#apartmentBuilding .hillside-star"
    );
    const rightCurtain = document.querySelector(
      "#apartmentBuilding #rightCurtain"
    );
    const leftCurtain = document.querySelector(
      "#apartmentBuilding #leftCurtain"
    );
    const text = document.querySelector("#apartmentBuilding #textElement");
    const monitor = document.querySelector(
      "#apartmentBuilding #roomBackground"
    );

    // setup elements
    setupSVG(svgElement);

    // setup main timeline
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add("pull away", 0.3);
    mainTL.add("sunset", 1.2);
    mainTL.add("nightfall", 2.8);
    mainTL.add("stars", 4.25);
    mainTL.add("text", 4.25);
    mainTL.add("curtains", 5.3);
    mainTL.add("monitor", 5.99);

    // populate main timeline
    mainTL.add(pullAway(svgElement), "pull away");
    mainTL.add(drawShadows(roofShadow, buildingShadow), "sunset");
    mainTL.add(
      dayToNight(dayToNightElements, sky, hillLeft, hillRight),
      "nightfall"
    );
    mainTL.add(showStars(stars), "stars");
    mainTL.add(showText(text), "text");
    mainTL.add(openCurtains(leftCurtain, rightCurtain), "curtains");
    mainTL.add(turnOnMonitor(monitor), "monitor");

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <SVG ref={svgRef} className={classes.svg} />
    </FullscreenAnimationDemo>
  );
}

function setupSVG(svg) {
  Utilities.centerElement(svg, 47);
  let startingXPosition = 23.8; // edges: 0.0 to 100.0
  let startingYPosition = 34; // edges: 0.0 to 100.0
  let startingScale = 6; // from starting value to 1

  // map starting position to X/YPercent values
  startingXPosition = Math.abs(startingXPosition - 100);
  startingXPosition = startingXPosition * 4 - 250;
  startingYPosition = Math.abs(startingYPosition - 100);
  startingYPosition = startingYPosition * 4 - 300;

  gsap.set(svg, {
    scale: startingScale,
    xPercent: startingXPosition,
    yPercent: startingYPosition,
  });
}

function pullAway(svg) {
  const tl = gsap.timeline();
  const pullAwayDuration = 5.6;
  tl.to(svg, {
    duration: pullAwayDuration,
    scale: 1,
    xPercent: -50,
    yPercent: -50,
    transformOrigin: "50% 50%",
    force3D: false,
    ease: "power2.out",
  });

  return tl;
}

function drawShadows(roofShadow, buildingShadow) {
  const tl = gsap.timeline();
  const drawShadowDuration = 3.5;
  const offset = 0.8;
  tl.from(
    roofShadow,
    {
      duration: drawShadowDuration,
      scaleX: 0.05,
      scaleY: 0,
      transformOrigin: "44.5% 0%",
    },
    0
  );
  tl.from(
    buildingShadow,
    {
      duration: drawShadowDuration - offset,
      scaleY: 0,
      transformOrigin: "50% 0%",
    },
    offset
  );

  return tl;
}

function dayToNight(elements, sky, hillLeft, hillRight) {
  const tl = gsap.timeline();
  const duration = 2.3;

  // relative hsl tween
  tl.to(elements, { duration: duration, fill: "hsl(+=0%, -=70%, -=5%)" }, 0);
  tl.to(sky, { duration: duration, fill: "hsl(+=0%, -=60%, -=60%)" }, 0);
  tl.to(hillLeft, { duration: duration, fill: "hsl(+=0%, -=75%, -=7%)" }, 0);
  tl.to(hillRight, { duration: duration, fill: "hsl(+=0%, -=75%, -=10%)" }, 0);

  return tl;
}

function showStars(stars) {
  const tl = gsap.timeline();
  const duration = 1;
  tl.from(stars, {
    duration: duration,
    stagger: 0.03,
    scale: 0,
    transformOrigin: "50% 50%",
    ease: "back.out",
  });

  return tl;
}

/*
function twinkleStars(stars) {
  const tl = gsap.timeline();
  for (let i = 0; i < stars.length; i++) {
    const delay = gsap.utils.random(1, 3);
    const repeatDelay = gsap.utils.random(3, 4);
    const timelinePosition = (i + 1) * gsap.utils.random(0.9, 1.5);
    const rotation = gsap.utils.random(10, 30);
    const tempTL = gsap.timeline({
      repeat: -1,
      delay: delay,
      repeatDelay: repeatDelay,
    });
    tempTL.to(stars[i], {
      duration: 0.4,
      scale: 0.2,
      opacity: 0.4,
      transformOrigin: "50% 50%",
    });
    tempTL.set(stars[i], { rotation: "+=" + rotation });
    tempTL.to(stars[i], {
      duration: 0.6,
      scale: 1,
      opacity: 1,
      transformOrigin: "50% 50%",
      ease: "back.in",
    });
    tl.add(tempTL, timelinePosition);
  }

  return tl;
}
*/

function showText(text) {
  const tl = gsap.timeline();
  tl.from(text, { duration: 0.8, yPercent: -125, ease: "power2.out" });

  return tl;
}

function openCurtains(leftCurtain, rightCurtain) {
  const tl = gsap.timeline();
  const curtainDuration = 0.3;
  const scaleX = 0;
  tl.to(
    leftCurtain,
    { duration: curtainDuration, scaleX: scaleX, transformOrigin: "2% 50%" },
    0
  );
  tl.to(
    rightCurtain,
    { duration: curtainDuration, scaleX: scaleX, transformOrigin: "98% 50%" },
    0
  );

  // shrink as we near the edges
  const shrink = 0.16;
  const shrinkDuration = (curtainDuration - shrink) * 0.9;
  const scaleY = 0.75;
  tl.to(leftCurtain, { duration: shrinkDuration, scaleY: scaleY }, shrink);
  tl.to(rightCurtain, { duration: shrinkDuration, scaleY: scaleY }, shrink);

  return tl;
}

function turnOnMonitor(monitor) {
  const tl = gsap.timeline({ id: "turn on monitor" });
  tl.to(monitor, { duration: 0.01, fill: "#eef" });

  return tl;
}

export default Hillside;
