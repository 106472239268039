import React, { useEffect } from "react";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import AnimatedBackgroundDrop from "./AnimatedBackgroundDrop";
import ScrollTop from "../../ScrollTop";
import PlaceholderText from "../../../../PlaceholderText";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflowX: "hidden",
    minHeight:
      theme.palette.animations.animatedScrollingBackground.contentMinHeight,
    visibility: "hidden",
  },
  contentContainer: {
    position: "relative",
    zIndex: "100",
    marginTop: "35px",
  },
  grid: {
    position: "relative",
  },
  title: {
    paddingTop: "50px",
    paddingBottom: "50px",
    position: "relative",
    zIndex: 10,
    background: theme.palette.animations.drop.header,
  },
  italicize: {
    fontStyle: "italic",
  },
  quote: {
    marginLeft: theme.spacing(2),
    fontStyle: "italic",
  },
  theEnd: {
    visibility: "hidden",
    marginTop: "100px",
  },
}));

function ContentWithAnimatedBackground(props) {
  const classes = useStyles(props);
  const containerRef = React.useRef();
  const gridContainerRef = React.useRef();
  const theEndRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.to(containerRef.current, { autoAlpha: 1, duration: 0.3 });
    const gridContainer = gridContainerRef.current;
    const theEnd = theEndRef.current;
    const size = Math.ceil(gridContainer.offsetWidth * 0.125) + "px";
    gsap.set(theEnd.querySelector("h4"), { lineHeight: size });
    gsap.set(theEnd, { height: size, autoAlpha: 1 });

    // window resize
    function handleResize() {
      const newSize = Math.ceil(gridContainer.offsetWidth * 0.125) + "px";
      gsap.set(theEnd.querySelector("h4"), { lineHeight: newSize });
      gsap.set(theEnd, { height: newSize, autoAlpha: 1 });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div ref={containerRef} className={classes.root}>
      <ScrollTop />
      <AnimatedBackgroundDrop />
      <div className={classes.title}>
        <Typography variant="h3" align="center">
          Animated Background
        </Typography>
      </div>
      {/* <Divider /> */}
      <Grid className={classes.contentContainer} container justify="center">
        <Grid
          ref={gridContainerRef}
          className={classes.grid}
          item
          xs={12}
          md={8}
          lg={6}
        >
          <Container>
            <Typography variant="h5" align="left" gutterBottom>
              Blocks fall from the top of the viewport as the user scrolls
              through the text.
            </Typography>
            <Typography align="left" gutterBottom>
              The blocks give a visual cue of progress through the text, like a
              progress bar. The individual animations are randomized, so the
              effect is unique, even when used multiple times on the same page.
            </Typography>
            <Typography align="left" gutterBottom>
              Block color follows a linear progression between two values. Check
              out the effect in light and dark mode.
            </Typography>
            <Typography align="left" gutterBottom>
              This is a great way to add some flair to a blog or long blocks of
              text, without constant movement to distract while the user is
              reading. Paragraphs could be given a transparent background color
              if the effect makes the text difficult to read.
            </Typography>
            <Typography variant="h5" align="left" gutterBottom>
              Here's some text from{" "}
              <Link
                href="http://m.joyceproject.com/chapters/proteus.html"
                color="inherit"
                target="_blank"
                rel="noopener"
                className={classes.italicize}
              >
                Ulysses
              </Link>{" "}
              to fill out the page:
            </Typography>
            <PlaceholderText />
          </Container>
        </Grid>
        <Grid ref={theEndRef} item xs={12} className={classes.theEnd}>
          <Typography variant="h4" align="center">
            the end
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContentWithAnimatedBackground;
