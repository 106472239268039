import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function PageElementsDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        In this demo, I show off multiple techniques using GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/ScrollTrigger"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          ScrollTrigger
        </Link>{" "}
        plugin to bring page elements on and off the screen with user input. I
        feature responsive grid animations, staggered reveals, data
        visualization with a pinned SVG map, and more.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        GSAP's ScrollTrigger lets us play the animation forward or backward
        depending on the scroll direction. The animations can also be configured
        to run only once, leaving the element in place after it is revealed.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        Images from{" "}
        <Link
          href="https://picsum.photos"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Lorem Picsum
        </Link>
        .
      </Typography>
    </DemoDescription>
  );
}

export default PageElementsDescription;
