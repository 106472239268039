import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/upsy-daisy.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    [theme.breakpoints.up("xs")]: {
      width: theme.sizes.timelineControlsContainerWidth.xSmallUp,
    },
    [theme.breakpoints.only("md")]: {
      width: theme.sizes.timelineControlsContainerWidth.medium,
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.sizes.timelineControlsContainerWidth.large,
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.sizes.timelineControlsContainerWidth.xLarge,
    },
    height: "auto",
    position: "absolute",
    overflow: "visible",
    "& #upsy path": {
      fill: theme.palette.text.primary + "!important",
      visibility: "hidden",
    },
    "& #upsy-top, #daisy-bottom": {
      fill: theme.palette.background.paper + "!important",
    },
    "& #upsy-background, #upsy-overlay": {
      fill: theme.palette.animations.upsyDaisy.upsyBox + "!important",
    },
    "& #daisy-background": {
      fill: theme.palette.animations.upsyDaisy.daisyBox + "!important",
    },
  },
}));

function UpsyDaisy() {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svg = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;
    const upsyText = document.querySelectorAll("#upsy-daisy #upsy path");
    const upsyOverlay = document.querySelector("#upsy-daisy #upsy-overlay");
    const upsyBackground = document.querySelector(
      "#upsy-daisy #upsy-background"
    );
    const daisy = document.querySelector("#upsy-daisy #daisy");
    const daisyText = document.querySelectorAll("#upsy-daisy #daisy path");
    const daisyBottom = document.querySelector("#upsy-daisy #daisy-bottom");
    const daisyBackground = document.querySelector(
      "#upsy-daisy #daisy-background"
    );
    gsap.set(daisyBottom, { autoAlpha: 1 });
    gsap.set(daisy, { autoAlpha: 0 });

    // SETUP
    Utilities.centerElement(svg, 47);
    gsap.set([upsyBackground, upsyOverlay, daisyBackground], {
      scaleY: 0,
      transformOrigin: "50% 100%",
    });

    // ANIMATION
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add(
      showBackgrounds([upsyBackground, upsyOverlay, daisyBackground]),
      0.2
    );
    mainTL.set(upsyText, { autoAlpha: 1 });
    mainTL.add(makeUpsyTL(upsyText, upsyOverlay));
    mainTL.add(makeDaisyTL(daisy, daisyText), "<+=0.5");

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      {<SVG ref={svgRef} className={classes.svg} />}
    </FullscreenAnimationDemo>
  );
}

function showBackgrounds(backgrounds) {
  const mainTL = gsap.timeline();
  mainTL.to([backgrounds[0], backgrounds[1]], {
    scaleY: 1,
    transFormOrigin: "50% 100%",
    ease: "back.out",
  });
  mainTL.to(
    backgrounds[2],
    {
      scaleY: 1,
      transFormOrigin: "50% 100%",
      ease: "back.out",
    },
    0.2
  );
  return mainTL;
}

function makeUpsyTL(text, overlay) {
  const tl = gsap.timeline();

  const customBounce = CustomEase.create(
    "custom",
    "M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.37,0.985 0.425,0.853 0.496,0.83 0.652,0.778 0.719,0.981 0.726,0.998 0.788,0.914 0.84,0.936 0.859,0.95 0.878,0.964 0.897,0.985 0.911,0.998 0.922,0.994 0.939,0.984 0.954,0.984 0.969,0.984 1,1 1,1"
  );
  const distance = 20;

  tl.to(text, { y: "-=" + distance, duration: 0.4, stagger: 0.05 }, 0);
  tl.set(overlay, { autoAlpha: 0 });
  tl.to(text, {
    y: "+=" + distance,
    duration: 0.4,
    ease: customBounce,
    stagger: 0.05,
  });

  tl.to(
    text,
    {
      rotationZ: 2520,
      duration: 0.6,
      ease: "back.out(0.5)",
      transformOrigin: "50% 50%",
      stagger: 0.05,
    },
    0
  );

  return tl;
}

function makeDaisyTL(element, text) {
  const up = gsap.timeline();
  const upStarts = [21, 17, 15, 17, 19];
  const upRotations = [-540, -270, -540, -270, -540];
  const upDuration = 1;

  const startColor = "#795548";
  const endColor = "#ffc107";

  // move up
  up.set(text, {
    y: (i) => "+=" + upStarts[i],
    rotation: (i) => upRotations[i],
    transformOrigin: "50% 50%",
    fill: startColor,
  });
  up.to(text, { y: 0, duration: upDuration, ease: "back.out" });

  // shake
  const backForthDuration = 0.1;
  const backForthDistance = 2;
  const shake = gsap.timeline();
  shake.to(element, {
    x: "-=" + backForthDistance / 2,
    duration: backForthDuration / 2,
  });
  shake.to(element, {
    x: "+=" + backForthDistance,
    duration: backForthDuration,
    repeat: 5,
    yoyo: true,
  });
  shake.to(element, { x: 0, duration: backForthDuration / 2 });

  // change color
  const bloom = gsap.timeline();
  const bloomChars = Utilities.sattoloShuffleCopy(gsap.utils.toArray(text));
  bloom.to(bloomChars, {
    fill: endColor,
    duration: 0.1,
    stagger: 0.05,
    repeat: 2,
    yoyo: true,
  });

  // reset rotation
  const rotateTL = gsap.timeline();
  const rotationDuration = 0.8;
  rotateTL.to(text, {
    rotation: 0,
    stagger: 0.1,
    duration: rotationDuration,
    transformOrigin: "50% 50%",
    ease: "back.inOut",
  });

  const tl = gsap.timeline();
  tl.set(element, { autoAlpha: 1 });
  tl.add(up, 0);
  tl.add(rotateTL, "-=0.2");
  tl.add(shake, 0);
  tl.add(bloom, 0.2);

  return tl;
}

export default UpsyDaisy;
