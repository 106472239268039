import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/sunside.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(GSDevTools);

const CLIP_DURATION = 2;
const BACKGROUND_COLORS = {
  start: "#051d42",
  mid: "#039be5",
  end: "#fff",
};

const useStyles = makeStyles((theme) => ({
  svg: {
    width: "100%",
    maxWidth: "1000px",
    height: "auto",
    position: "relative",
    overflow: "visible",
    zIndex: 100,
  },
  svgContainer: {
    position: "absolute",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "500px",
    },
    [theme.breakpoints.only("md")]: {
      width: "70%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
      maxWidth: "750px",
    },
    [theme.breakpoints.only("xl")]: {
      width: "50%",
      maxWidth: "1000px",
    },
  },
  background: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 10,
  },
}));

function Sunside(props) {
  const svgRef = React.useRef();
  const svgContainerRef = React.useRef();
  const backgroundRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svgContainer = svgContainerRef.current;
    const timelineControlsElement = timelineControlsRef.current;
    const background = document.querySelector("#sun #background");
    const sun = document.querySelector("#sun #disk");
    const rays = document.querySelector("#sun #rays");
    const sunGlow = document.querySelector("#sun #sunGlow");

    // SETUP
    Utilities.centerElement(svgContainer, 47);

    gsap.set(svgContainer, {
      scale: 2,
      yPercent: 50,
      xPercent: 30,
      transformOrigin: "50% 50%",
    });
    gsap.set([sunGlow, rays, background], {
      scale: 3,
      transformOrigin: "50% 50%",
    });
    gsap.set([rays, sunGlow], { autoAlpha: 0 });

    // text
    const text = document.querySelectorAll("#sun #text path");
    gsap.set(text, { autoAlpha: 0, fill: "rgba(205,126,25,0.65)" });

    // ANIMATION
    // infinite repeat on sun scale and rays rotation
    // keep out of main timeline
    makeSunAnimationTL(sun, rays);

    // main timeline
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add(makeSunriseTL(svgContainer, rays, sunGlow, background), 0.5);
    mainTL.add("clip");
    mainTL.add(makeClipTL(svgContainer), "clip");
    mainTL.add(desaturateColors(sun, rays.querySelectorAll("path")), "clip");
    mainTL.add(revealText(text));
    mainTL.add(lightenBackground(background), "clip+=0.5");

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <div ref={backgroundRef} className={classes.background}></div>
      <div ref={svgContainerRef} className={classes.svgContainer}>
        <SVG ref={svgRef} className={classes.svg} />
      </div>
    </FullscreenAnimationDemo>
  );
}

function makeSunAnimationTL(sun, rays) {
  const diskDuration = 5;
  const rotationDuration = 72;
  const sunScale = 1.2;

  const sunTL = gsap.timeline();

  const rotationTL = gsap.timeline();
  rotationTL.to(rays, {
    duration: rotationDuration,
    rotation: "-=360",
    ease: "none",
    transformOrigin: "50% 50%",
    repeat: -1,
  });

  const diskTL = gsap.timeline();
  diskTL.to(sun, {
    duration: diskDuration,
    scale: sunScale,
    ease: "power1.inOut",
    transformOrigin: "50% 50%",
    repeat: -1,
    yoyo: "true",
  });

  sunTL.add(rotationTL, 0);
  sunTL.add(diskTL, 0);

  return sunTL;
}

function makeSunriseTL(sun, rays, sunGlow, background) {
  const mainTL = gsap.timeline();
  const sunriseDuration = 6;
  const sunTL = gsap.timeline();
  sunTL.to(sun, {
    yPercent: -50,
    xPercent: -50,
    duration: sunriseDuration,
    transformOrigin: "50% 50%",
    ease: "power1.inOut",
  });

  const raysTL = gsap.timeline();
  raysTL.to(rays, {
    autoAlpha: 1,
    duration: sunriseDuration,
  });

  const backgroundTL = gsap.timeline();
  backgroundTL.to(
    sunGlow,
    {
      autoAlpha: 1,
      duration: sunriseDuration,
    },
    0
  );
  backgroundTL.to(
    background,
    {
      fill: BACKGROUND_COLORS.mid,
      duration: sunriseDuration / 2,
    },
    0
  );

  mainTL.add(sunTL, 0);
  mainTL.add(raysTL, 0);
  mainTL.add(backgroundTL, 0);

  return mainTL;
}

function makeClipTL(sun) {
  const mainTL = gsap.timeline();
  const duration = CLIP_DURATION;
  mainTL.set(sun, {
    clipPath: "polygon(-100% -100%, 200% -100%, 200% 200%, -100% 200%)",
  });
  mainTL.to(sun, {
    clipPath: "polygon(33% 33%, 67% 33%, 67% 69%, 33% 69%)",
    duration: duration,
    ease: "back.out(0.2)",
  });
  return mainTL;
}

function desaturateColors(sun, rays) {
  const mainTL = gsap.timeline();
  const duration = CLIP_DURATION;
  mainTL.to(sun, { fill: "hsl(+=0%, -=20%, -=0%)", duration: duration }, 0);
  mainTL.to(rays, { fill: "hsl(+=0%, -=20%, -=0%)", duration: duration }, 0);
  return mainTL;
}

function lightenBackground(background) {
  const mainTL = gsap.timeline();
  const duration = CLIP_DURATION * 1.5;
  mainTL.to(background, { fill: BACKGROUND_COLORS.end, duration: duration });
  return mainTL;
}

function revealText(text) {
  const mainTL = gsap.timeline();
  const duration = 0.3;
  mainTL.set(text, { y: "+=5" });
  mainTL.to(text, {
    scale: 1,
    autoAlpha: 1,
    y: 0,
    stagger: 0.035,
    force3D: true,
    duration: duration,
  });
  return mainTL;
}

export default Sunside;
