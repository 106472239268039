import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/cranium-man.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(GSDevTools);

// colors
const COLORS = {
  laserHot: "#ff355e",
  laserImpact: "#ff4f72",
  cooledOff: "#666",
  gearCooledOff: "#111",
  headCooledOff: "#222320",
  textCooledOff: "#111",
  backgroundElementEnd: {
    top: "#222320",
    head: "#eee",
    text: "#eee",
  },
};

const useStyles = makeStyles((theme) => ({
  svg: {
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: "80%",
      width: "auto",
    },
    [`${theme.breakpoints.up("xs")} and (orientation: portrait)`]: {
      height: "80%",
      width: "auto",
    },
    [`${theme.breakpoints.only("xs")} and (orientation: portrait)`]: {
      width: "80%",
      height: "auto",
    },
    [`${theme.breakpoints.only("sm")} and (orientation: portrait)`]: {
      width: "80%",
      height: "auto",
      maxHeight: "80vh",
    },
    position: "absolute",
    transform: "translateZ(0)",
    overflow: "visible",
    "& #topAreaBackground, #headBackground, #textAreaBackground": {
      fill: theme.palette.background.paper,
    },
    "& #laserImpactGroup path, .laserGroup path": {
      fill: COLORS.laserImpact,
    },
    "& #frame, #divider, #headOutline, #gearsGroup path, #text path": {
      stroke: COLORS.laserHot,
    },
    "& #laserImpactGroup": {
      visibility: "hidden",
    },
  },
}));

function CraniumMan(props) {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    const svgElement = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;

    // animation elements
    const frame = document.querySelector("#craniumMan #frame");
    const divider = document.querySelector("#craniumMan #divider");
    const head = document.querySelector("#craniumMan #headOutline");
    const gears = {
      smallGearInner: document.querySelector("#craniumMan #smallGearInner"),
      smallGearOuter: document.querySelector("#craniumMan #smallGearOuter"),
      mediumGearInner: document.querySelector("#craniumMan #mediumGearInner"),
      mediumGearOuter: document.querySelector("#craniumMan #mediumGearOuter"),
      largeGearInner: document.querySelector("#craniumMan #bigGearInner"),
      largeGearOuter: document.querySelector("#craniumMan #bigGearOuter"),
    };
    const text = document.querySelectorAll("#craniumMan #text path");
    const topAreaBackground = document.querySelector(
      "#craniumMan #topAreaBackground"
    );
    const headBackground = document.querySelector(
      "#craniumMan #headBackground"
    );
    const textAreaBackground = document.querySelector(
      "#craniumMan #textAreaBackground"
    );

    // setup elements
    Utilities.centerElement(svgElement, 47);

    // setup main timeline
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add("frame", 0.3);
    mainTL.add("divider", 1.2);
    mainTL.add("head outline", 1.8);
    mainTL.add("small gear", 3.3);
    mainTL.add("medium gear", 3.4);
    mainTL.add("large gear", 3.5);
    mainTL.add("darken top background", 3.6);
    mainTL.add("text", 5);
    mainTL.add("darken head background", 5.2);
    mainTL.add("darken text background", 6.6);
    mainTL.add("spin gears", 7);

    // populate main timeline
    mainTL.add(drawPathAndTraceWithLaser(frame, 2, 0.66), "frame");
    mainTL.add(drawPathAndTraceWithLaser(divider, 1, 0.5), "divider");
    mainTL.add(drawPathAndTraceWithLaser(head, 3, 0.5), "head outline");
    mainTL.add(
      drawPathAndTraceWithLaser(gears.smallGearOuter, 1.2, 0.35, true),
      "small gear"
    );
    mainTL.add(
      drawPathAndTraceWithLaser(gears.smallGearInner, 0.8, 0.35, true),
      "small gear+=0.3"
    );
    mainTL.add(
      drawPathAndTraceWithLaser(gears.mediumGearOuter, 1.3, 0.35, true),
      "medium gear"
    );
    mainTL.add(
      drawPathAndTraceWithLaser(gears.mediumGearInner, 0.85, 0.35, true),
      "medium gear+=0.3"
    );
    mainTL.add(
      drawPathAndTraceWithLaser(gears.largeGearOuter, 1.5, 0.4, true),
      "large gear"
    );
    mainTL.add(
      drawPathAndTraceWithLaser(gears.largeGearInner, 1, 0.45, true),
      "large gear+=0.3"
    );
    mainTL.add(drawText(text), "text");
    mainTL.add(
      changeBackgroundColor(topAreaBackground, COLORS.backgroundElementEnd.top),
      "darken top background"
    );
    mainTL.add(
      changeBackgroundColor(headBackground, COLORS.backgroundElementEnd.head),
      "darken head background"
    );
    mainTL.add(
      changeBackgroundColor(
        textAreaBackground,
        COLORS.backgroundElementEnd.text
      ),
      "darken text background"
    );
    mainTL.add(
      spinGears([
        gears.smallGearOuter,
        gears.mediumGearOuter,
        gears.largeGearOuter,
      ]),
      "spin gears"
    );

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <SVG ref={svgRef} className={classes.svg} />
    </FullscreenAnimationDemo>
  );
}

function drawPathAndTraceWithLaser(path, duration, laserScale, pathIsAGear) {
  const ease = "power2.inOut";
  const delay = 0.5;
  const scale = laserScale ? laserScale : 1;

  // get coordinates of path's start point
  const rawPath = MotionPathPlugin.getRawPath(path);
  MotionPathPlugin.cacheRawPathMeasurements(rawPath);
  const pathStartingPoint = MotionPathPlugin.getPositionOnPath(rawPath, 0);

  // draw path
  const drawPathTL = gsap.timeline();
  drawPathTL.from(path, {
    drawSVG: 0,
    duration: duration,
    ease: ease,
  });

  // trace path with laser
  const laserTraceTL = gsap.timeline();
  const laserObject = makeLaser(duration);
  const traceTL = gsap.timeline();
  const offsetY = pathIsAGear ? -2.5 : 0;
  traceTL.set(laserObject.laser, {
    x: pathStartingPoint.x,
    y: pathStartingPoint.y + offsetY,
  });
  traceTL.to(laserObject.laser, {
    scale: scale,
    duration: 0.2,
    ease: "back.out",
  });
  traceTL.to(
    laserObject.laser,
    {
      duration: duration,
      ease: ease,
      motionPath: {
        path: path,
        offsetY: offsetY,
      },
    },
    delay
  );

  laserTraceTL.add(traceTL, 0);
  laserTraceTL.to(
    laserObject.laser,
    {
      scale: 0,
      duration: 0.3,
      ease: "back.in",
    },
    "+=0.2"
  );
  laserTraceTL.add(laserObject.timeline, 0);

  // main timeline
  const mainTL = gsap.timeline();
  mainTL.add(drawPathTL, delay);
  mainTL.add(coolOff(path), "+=0.3");
  mainTL.add(laserTraceTL, 0);

  return mainTL;
}

function makeLaser(duration) {
  const laserTemplate = document.querySelector("#craniumMan #laserImpactGroup");
  const laser = laserTemplate.cloneNode(true);
  const laserDuration = duration + 0.3;
  document.querySelector("#craniumMan").appendChild(laser);
  gsap.set(laser, {
    transformOrigin: "50% 50%",
    xPercent: -50,
    yPercent: -50,
    autoAlpha: 1,
    scale: 0,
  });
  const impactPoint = laser.querySelector(".impactPoint");
  const rays = laser.querySelectorAll(".rayGroup path");
  const debris = laser.querySelectorAll(".debrisGroup path");

  // animate
  const laserTL = gsap.timeline();
  laserTL.add(laserImpact(impactPoint, laserDuration), 0);
  laserTL.add(drawEnergyRays(rays), 0);
  laserTL.add(throwDebris(debris), 0);

  return { laser: laser, timeline: laserTL };
}

function laserImpact(impactPoint, laserDuration) {
  const impactPointDuration = 0.1;
  const impactPointRepeats = laserDuration / impactPointDuration;
  const mainTL = gsap.timeline();
  mainTL.to(impactPoint, {
    scale: 1.3,
    transformOrigin: "50% 50%",
    ease: "power2.inOut",
    duration: impactPointDuration,
    repeat: impactPointRepeats,
    yoyo: true,
  });

  return mainTL;
}

function drawEnergyRays(rays) {
  // starting rotation, opacity
  setupRays(rays);

  // tween scale
  const scaleTL = gsap.timeline();
  const scaleDuration = 0.4;
  scaleTL.fromTo(
    rays,
    { scale: 0 },
    { scale: 1, duration: scaleDuration, stagger: 0.15 }
  );

  // tween opacity
  const opacityTL = gsap.timeline();
  const opacityDuration = 0.6;
  opacityTL.fromTo(
    rays,
    { opacity: 1 },
    { opacity: 0, duration: opacityDuration, stagger: 0.15 }
  );

  // main timeline
  const mainTL = gsap.timeline({
    repeat: 2,
    onRepeat: setupRays,
    onRepeatParams: [rays],
  });
  mainTL.add(scaleTL, 0);
  mainTL.add(opacityTL, 0.25);

  return mainTL;
}

function setupRays(raysArg) {
  // shuffle
  const rays = gsap.utils.shuffle(gsap.utils.toArray(raysArg));

  // rotate
  gsap.set(rays, {
    rotation: () => gsap.utils.random(1, 359),
    transformOrigin: "50% 100%",
  });
}

function throwDebris(debris, laserDuration) {
  // increase scale of all debris
  gsap.set(debris, { scale: 1.3, transformOrigin: "50% 50%" });

  // move debris away from center and rotate
  const movementTL = gsap.timeline();
  const movementDuration = 0.6;
  movementTL.fromTo(
    debris,
    { x: 0, y: 0 },
    {
      duration: movementDuration,
      x: () => {
        return Utilities.rollForPositiveNegative(gsap.utils.random(25, 49));
      },
      y: () => {
        return Utilities.rollForPositiveNegative(gsap.utils.random(25, 49));
      },
      rotation: () => {
        return (
          "+=" + Utilities.rollForPositiveNegative(gsap.utils.random(10, 350))
        );
      },
      stagger: 0.1,
      transformOrigin: "50% 50%",
    },
    0.1
  );

  // fade out debris as it moves
  const opacityTL = gsap.timeline();
  const opacityDuration = 0.4;
  opacityTL.fromTo(
    debris,
    { opacity: 1 },
    { opacity: 0, duration: opacityDuration, stagger: 0.1 }
  );

  // main timeline
  const debrisRepeats = Math.ceil(laserDuration / movementTL.duration());
  const mainTL = gsap.timeline({ repeat: debrisRepeats });
  mainTL.add(movementTL, 0);
  mainTL.add(opacityTL, 0.25);

  return mainTL;
}

function coolOff(path) {
  const mainTL = gsap.timeline();
  const coolOffDuration = 1;
  let color = COLORS.cooledOff;
  if (path.id.indexOf("Gear") !== -1) {
    color = COLORS.gearCooledOff;
  }
  mainTL.to(path, { stroke: color, duration: coolOffDuration });
  if (path.id === "headOutline") {
    color = COLORS.headCooledOff;
    mainTL.to(path, { stroke: color, duration: coolOffDuration });
  }
  return mainTL;
}

function drawText(text) {
  // scramble text array
  const scrambledText = Utilities.sattoloShuffleCopy(gsap.utils.toArray(text));

  // draw text
  const duration = 1.5;
  const drawTL = gsap.timeline();
  drawTL.from(scrambledText, { duration: duration, drawSVG: 0, stagger: 0.1 });

  // change color from hot laser color to cool final color
  const colorTL = gsap.timeline();
  colorTL.to(scrambledText, {
    duration: 1,
    stroke: COLORS.textCooledOff,
    stagger: 0.1,
  });

  const breatheTL = gsap.timeline({ repeat: 1 });
  breatheTL.to(text, {
    stroke: "#FF355E",
    ease: "power1.in",
    duration: 0.8,
    stagger: {
      each: 0.15,
      repeat: 1,
      repeatDelay: 0.1,
      yoyo: true,
    },
  });

  const mainTL = gsap.timeline();
  mainTL.add(drawTL, 0);
  mainTL.add(colorTL, 1.5);
  mainTL.add(breatheTL);

  return mainTL;
}

function changeBackgroundColor(element, color) {
  const tl = gsap.timeline();
  const duration = 2.6;
  tl.to(element, { fill: color, duration: duration });
  return tl;
}

function spinGears(gears) {
  // start up
  const speedUpDurations = [0.4, 0.5, 0.8];
  const starts = 3;
  const labels = ["step 1", "step 2", "step 3"];
  let labelPosition = -speedUpDurations[0];
  const speedUpRotations = [7, -7, 7];
  const delays = [0.1, 0.4, 0.3];
  const eases = ["bounce.out", "bounce.out", "power2.in"];
  const speedUpTL = gsap.timeline();
  for (let i = 0; i < starts; i++) {
    // add label
    labelPosition += speedUpDurations[i];
    speedUpTL.add(labels[i], labelPosition);
    for (let j = 0; j < gears.length; j++) {
      speedUpTL.to(
        gears[j],
        {
          rotation: "+=" + speedUpRotations[j] * (i + 1),
          transformOrigin: "50% 50%",
          ease: eases[i],
          duration: speedUpDurations[i],
          delay: delays[i],
        },
        labels[i]
      );
    }
  }

  // spin
  const spinDuration = 5;
  const spinTL = gsap.timeline();
  const spinRotations = ["+=360", "-=360", "+=360"];
  spinTL.to(gears, {
    rotation: (i) => spinRotations[i],
    duration: spinDuration,
    ease: "none",
    transformOrigin: "50% 50%",
  });

  // main timeline
  const mainTL = gsap.timeline();
  mainTL.add(speedUpTL, 0);
  mainTL.add(spinTL);

  return mainTL;
}

export default CraniumMan;
