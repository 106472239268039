import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    height: theme.sizes.headerHeight,
    position: "relative",
  },
  text: {
    color: theme.palette.text.primary,
    [theme.breakpoints.only("xs")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "80px",
    },
  },
}));

function Header(props) {
  const headerTextRef = React.useRef();

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      justify="center"
      alignItems="center"
    >
      <Grid className={classes.hero} item xs={12} xl={10}>
        <Typography
          ref={headerTextRef}
          variant="h1"
          className={classes.text}
          align="center"
        >
          {props.text ? props.text : "header"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Header;
