import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import CustomEase from "gsap/CustomEase";
import CustomBounce from "gsap/CustomBounce";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/logos/three-two-one.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomBounce);
gsap.registerPlugin(GSDevTools);

const DROP_DISTANCE = 90;
const DROP_DURATION = 0.8;
CustomBounce.create("myBounce", {
  strength: 0.3,
  squash: 2,
  squashID: "myBounce-squash",
});

const useStyles = makeStyles((theme) => ({
  svg: {
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: "85%",
      width: "auto",
    },
    [`${theme.breakpoints.up("xs")} and (orientation: portrait)`]: {
      height: "80%",
      width: "auto",
    },
    [`${theme.breakpoints.only("xs")} and (orientation: portrait)`]: {
      height: "73%",
      width: "auto",
    },
    position: "absolute",
    overflow: "visible",
    "& #one, #two, #three, #background": {
      visibility: "hidden",
    },
    "& #ball-one": {
      fill: theme.palette.animations.threeTwoOne.one + " !important",
    },
    "& #balls-two path": {
      fill: theme.palette.animations.threeTwoOne.two + " !important",
    },
    "& #balls-three path": {
      fill: theme.palette.animations.threeTwoOne.three + " !important",
    },
  },
}));

function TheeeTwoOne() {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;
    const timelineControlsElement = timelineControlsRef.current;
    const text = {
      one: document.querySelector("#three-two-one #one path"),
      two: document.querySelectorAll("#three-two-one #two path"),
      three: document.querySelectorAll("#three-two-one #three path"),
    };
    const balls = {
      one: document.querySelector("#three-two-one #ball-one"),
      two: document.querySelectorAll("#three-two-one #balls-two path"),
      three: document.querySelectorAll("#three-two-one #balls-three path"),
    };

    // SETUP
    Utilities.centerElement(svgElement, 47);

    // ANIMATION
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.add(three(text.three, balls.three), 0);
    mainTL.add(two(text.two, balls.two), 1.25);
    mainTL.add(one(text.one, balls.one), 2.3);

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      {<SVG ref={svgRef} className={classes.svg} />}
    </FullscreenAnimationDemo>
  );
}

function three(text, balls) {
  const origins = ["20% 60%", "20% 60%", "10% 80%"];
  const mainTL = gsap.timeline();
  const dropTL = gsap.timeline();
  dropTL.from(
    balls,
    {
      y: "-=" + DROP_DISTANCE,
      stagger: 0.1,
      ease: "myBounce",
      duration: DROP_DURATION,
    },
    0
  );
  dropTL.to(
    balls,
    {
      stagger: 0.1,
      scaleX: 1.4,
      scaleY: 0.6,
      ease: "myBounce-squash",
      transformOrigin: "center bottom",
      duration: DROP_DURATION,
    },
    0
  );

  const morphTL = gsap.timeline();
  morphTL.to(balls, {
    morphSVG: {
      shape: (i) => text[i],
      type: "rotational",
      origin: (i) => origins[i],
    },
    duration: 0.25,
    stagger: 0.1,
  });

  mainTL.add(dropTL, 0);
  mainTL.add(morphTL, 0.6);
  return mainTL;
}

function two(text, balls) {
  const origins = ["20% 60%", "0% 50%"];
  const mainTL = gsap.timeline();

  const dropTL = gsap.timeline();
  dropTL.from(
    balls,
    {
      y: "-=" + DROP_DISTANCE,
      stagger: 0.1,
      ease: "myBounce",
      duration: DROP_DURATION,
    },
    0
  );
  dropTL.to(
    balls,
    {
      stagger: 0.1,
      scaleX: 1.4,
      scaleY: 0.6,
      ease: "myBounce-squash",
      transformOrigin: "center bottom",
      duration: DROP_DURATION,
    },
    0
  );

  const morphTL = gsap.timeline();
  morphTL.to(balls, {
    morphSVG: {
      shape: (i) => text[i],
      type: "rotational",
      origin: (i) => origins[i],
    },
    duration: 0.35,
    stagger: 0.1,
  });

  mainTL.add(dropTL, 0);
  mainTL.add(morphTL, 0.6);
  return mainTL;
}

function one(text, ball) {
  const mainTL = gsap.timeline();
  const dropTL = gsap.timeline();
  dropTL.from(
    ball,
    {
      y: "-=" + DROP_DISTANCE,
      ease: "myBounce",
      duration: DROP_DURATION,
    },
    0
  );
  dropTL.to(
    ball,
    {
      scaleX: 1.4,
      scaleY: 0.6,
      ease: "myBounce-squash",
      transformOrigin: "center bottom",
      duration: DROP_DURATION,
    },
    0
  );

  const morphTL = gsap.timeline();
  morphTL.to(ball, {
    morphSVG: {
      shape: text,
      type: "rotational",
      origin: "90% 0%",
    },
    duration: 0.35,
  });

  mainTL.add(dropTL, 0);
  mainTL.add(morphTL, 0.5);
  return mainTL;
}

export default TheeeTwoOne;
