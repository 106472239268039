/*
  A list of Scrolling animations
*/

// components
import PioneerTen from "../animations/Scrollers/pioneer-10/Content";
import Elements from "../animations/Scrollers/elements/Elements";
import Circles from "../animations/Scrollers/backgrounds/circles/ContentWithAnimatedBackgroundCircles";
import Rothko from "../animations/Scrollers/backgrounds/rothko/ContentWithAnimatedBackgroundRothko";
import Drop from "../animations/Scrollers/backgrounds/drop/ContentWithAnimatedBackgroundDrop";

// descriptions
import PioneerTenDescription from "../animation-descriptions/scrollers/PioneerTenDescription";
import ElementsDescription from "../animation-descriptions/scrollers/PageElementsDescription";
import CirclesDescription from "../animation-descriptions/scrollers/CirclesDescription";
import RothkoDescription from "../animation-descriptions/scrollers/RothkoDescription";
import DropDescription from "../animation-descriptions/scrollers/DropDescription";

// thumbnails
import PioneerTenThumbnail from "../img/thumbnails/scrollers/dark/pioneer-10.png";
import PioneerTenThumbnailLight from "../img/thumbnails/scrollers/light/pioneer-10.png";
import ElementsThumbnail from "../img/thumbnails/scrollers/dark/elements.png";
import ElementsThumbnailLight from "../img/thumbnails/scrollers/light/elements.png";
import CirclesThumbnail from "../img/thumbnails/scrollers/dark/circles.png";
import CirclesThumbnailLight from "../img/thumbnails/scrollers/light/circles.png";
import RothkoThumbnail from "../img/thumbnails/scrollers/dark/rothko.png";
import RothkoThumbnailLight from "../img/thumbnails/scrollers/light/rothko.png";
import DropThumbnail from "../img/thumbnails/scrollers/dark/drop.png";
import DropThumbnailLight from "../img/thumbnails/scrollers/light/drop.png";

const pathStart = "/scrollers/";

const ScrollingAnimations = [
  {
    heading: "pioneer 10",
    description: <PioneerTenDescription path={pathStart + "pioneer-10"} />,
    image: PioneerTenThumbnail,
    imageLight: PioneerTenThumbnailLight,
    component: PioneerTen,
    path: pathStart + "pioneer-10",
  },
  {
    heading: "elements",
    description: <ElementsDescription path={pathStart + "elements"} />,
    image: ElementsThumbnail,
    imageLight: ElementsThumbnailLight,
    component: Elements,
    path: pathStart + "elements",
  },
  {
    heading: "circles",
    description: <CirclesDescription path={pathStart + "circles"} />,
    image: CirclesThumbnail,
    imageLight: CirclesThumbnailLight,
    component: Circles,
    path: pathStart + "circles",
  },
  {
    heading: "rothko",
    description: <RothkoDescription path={pathStart + "rothko"} />,
    image: RothkoThumbnail,
    imageLight: RothkoThumbnailLight,
    component: Rothko,
    path: pathStart + "rothko",
  },
  {
    heading: "drop",
    description: <DropDescription path={pathStart + "drop"} />,
    image: DropThumbnail,
    imageLight: DropThumbnailLight,
    component: Drop,
    path: pathStart + "drop",
  },
];

export default ScrollingAnimations;
