import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function TopBottomDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This logo animation shows off multiple effects for revealing and hiding
        stacked elements. The elements start as text on contrasting color
        blocks, then the blocks collapse and combine into a center line, and I
        reveal the text from that line a final time before shrinking the center
        line down to nothing.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A logo animation is a great way to introduce your brand, and could work
        as a site intro, or a banner/header animation. With this effect, we can
        turn your site tagline into an animated logo.
      </Typography>
    </DemoDescription>
  );
}

export default TopBottomDescription;
