import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as Utilities from "./AnimationUtilities";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const creditFontSize = "1.73em";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
    minWidth: "100%",
    textAlign: "center",
    // pointerEvents: "none",
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
    "& a": {
      fontWeight: "600",
    },
  },
  titleContainer: {
    position: "absolute",
    visibility: "hidden",
  },
  credit: {
    color: theme.palette.text.primary,
    position: "relative",
    overflow: "hidden",
    display: "inline",
    "& p": {
      overflow: "hidden",
      position: "relative",
    },
  },
  creditsContainer: {
    position: "absolute",
    overflow: "hidden",
    maxWidth: "600px",
  },
  creditLeftColumn: {
    paddingRight: "0.5em",
    position: "relative",
    "& p": {
      fontSize: creditFontSize,
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2em",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2em",
      },
    },
    overflow: "hidden",
  },
  creditRightColumn: {
    position: "relative",
    paddingLeft: "0.5em",
    "& p": {
      fontSize: creditFontSize,
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2em",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2em",
      },
    },
    overflow: "hidden",
  },
  nasaLogo: {
    color: theme.palette.animations.pioneer10.nasaBlue,
  },
  reactLogo: {
    color: theme.palette.animations.pioneer10.reactBlue,
  },
  gsapLogo: {
    color: theme.palette.animations.pioneer10.gsapGreen,
  },
  jlhLogo: {
    color: theme.palette.text.primary,
    fontWeight: "400 !important",
  },
  overlay: {
    position: "absolute",
    width: "101%",
    height: "100%",
    borderRadius: "2px",
    backgroundColor: theme.palette.animations.pioneer10.overlays,
  },
}));

function Credits(props) {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const creditsRef = React.useRef();
  const overlayRef_Nasa_Left = React.useRef();
  const overlayRef_Nasa_Right = React.useRef();
  const overlayRef_BuiltWith_Left = React.useRef();
  const overlayRef_BuiltWith_Right = React.useRef();
  const overlayRef_JLH_Left = React.useRef();
  const overlayRef_JLH_Right = React.useRef();

  useEffect(() => {
    const container = containerRef.current;
    const title = titleRef.current;
    const credits = creditsRef.current;
    Utilities.centerElement(credits, 42);
    const overlays = [
      overlayRef_Nasa_Left.current,
      overlayRef_Nasa_Right.current,
      overlayRef_BuiltWith_Left.current,
      overlayRef_BuiltWith_Right.current,
      overlayRef_JLH_Left.current,
      overlayRef_JLH_Right.current,
    ];

    const text = overlays.map((overlay) => overlay.previousSibling);
    gsap.set(text, { autoAlpha: 0 });

    // setup and animate title
    const titleTL = gsap.timeline();
    Utilities.centerElement(title, 10);
    const titleText = new SplitText(title, { type: "chars" });
    const chars = titleText.chars;
    titleTL.set(chars, {
      autoAlpha: 0,
      scaleX: () => {
        return gsap.utils.random(0.1, 0.2);
      },
      scaleY: () => {
        return gsap.utils.random(4, 4.5);
      },
      transformOrigin: "50% 0%",
    });
    titleTL.set(title, { autoAlpha: 1 });
    titleTL.to(chars, {
      autoAlpha: 1,
      scaleX: 1,
      scaleY: 1,
      duration: 2,
      ease: "back.out(1.2)",
    });

    // setup and animate credits
    const creditsTL = gsap.timeline();
    const transformOriginLeft = "0% 50%";
    const transformOriginRight = "100% 50%";
    const overlayDuration = 1;
    Object.values(overlays).forEach((element, i) => {
      Utilities.centerElement(element);
      let transformOrigin, delay, xPercent;
      if (i % 2 === 0) {
        transformOrigin = transformOriginLeft;
        delay = 2;
        xPercent = "-150";
      } else {
        transformOrigin = transformOriginRight;
        delay = 1;
        xPercent = "-150";
      }
      gsap.set(element, {
        xPercent: xPercent,
        transformOrigin: transformOrigin,
      });

      // animate overlays to roll the credits
      creditsTL.to(
        element,
        {
          xPercent: "-50",
          transformOrigin: transformOrigin,
          duration: overlayDuration,
        },
        "+=" + delay
      );
      creditsTL.set(text[i], { autoAlpha: 1 });
      creditsTL.to(
        element,
        {
          xPercent: i % 2 === 0 ? "50" : "50",
          transformOrigin: "100% 50%",
          duration: overlayDuration,
        },
        "+=0.2"
      );
    });

    // add ScrollTrigger for Main timeline
    const menuBar = document.querySelector(".MuiToolbar-root");
    const mainTL = gsap.timeline();
    mainTL.add(titleTL);
    mainTL.add(creditsTL);
    ScrollTrigger.create({
      animation: mainTL,
      trigger: container,
      pin: true,
      anticipatePin: 1,
      start: "top " + menuBar.offsetHeight + "px",
      end: "+=" + window.innerHeight * 3,
      scrub: 0.5,
      toggleActions: "play reset play reset",
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      container
      ref={containerRef}
      className={classes.root}
      alignItems="center"
    >
      <Grid ref={titleRef} className={classes.titleContainer} item xs={12}>
        <Typography className={classes.title} variant="h3" align="center">
          credits
        </Typography>
      </Grid>

      <Grid
        container
        ref={creditsRef}
        className={classes.creditsContainer}
        alignItems="center"
      >
        {/* NASA */}
        <Grid item xs={6} align="right">
          <div className={classes.creditLeftColumn}>
            <Typography>copy and images</Typography>
            <div ref={overlayRef_Nasa_Left} className={classes.overlay}></div>
          </div>
        </Grid>
        <Grid item xs={6} align="left" className={classes.creditRightColumn}>
          <Typography>
            <Link
              href="http://nasa.gov"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.nasaLogo}
            >
              NASA
            </Link>
          </Typography>
          <div ref={overlayRef_Nasa_Right} className={classes.overlay}></div>
        </Grid>

        {/* React and GSAP */}
        <Grid item xs={6} align="right" className={classes.creditLeftColumn}>
          <Typography>built with</Typography>
          <div
            ref={overlayRef_BuiltWith_Left}
            className={classes.overlay}
          ></div>
        </Grid>
        <Grid item xs={6} align="left" className={classes.creditRightColumn}>
          <Typography>
            <Link
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.reactLogo}
            >
              React
            </Link>{" "}
            and{" "}
            <Link
              href="https://greensock.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.gsapLogo}
            >
              GSAP
            </Link>
          </Typography>
          <div
            ref={overlayRef_BuiltWith_Right}
            className={classes.overlay}
          ></div>
        </Grid>

        {/* JonnyH */}
        <Grid item xs={6} align="right" className={classes.creditLeftColumn}>
          <Typography>developed by</Typography>
          <div ref={overlayRef_JLH_Left} className={classes.overlay}></div>
        </Grid>
        <Grid item xs={6} align="left" className={classes.creditRightColumn}>
          <Typography>
            <Link
              href="https://jonathanharrison.us"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.jlhLogo}
            >
              Jonathan Harrison
            </Link>
          </Typography>
          <div ref={overlayRef_JLH_Right} className={classes.overlay}></div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Credits;
