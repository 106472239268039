// index pages
import HomePage from "./HomePage";
import WelcomeAnimationPage from "./WelcomeAnimationPage";
import ScrollingAnimationPage from "./ScrollingAnimationPage";
import LogoAnimationPage from "./LogoAnimationPage";
import About from "./About";
// demos
// welcomes
import Boxes from "./animations/Welcomes/Boxes";
import CircuitBoard from "./animations/Welcomes/CircuitBoard";
import LineDrawing from "./animations/Welcomes/Line-Drawing/LineDrawingDemo";
import Gears from "./animations/Welcomes/Gears";
import OFC from "./animations/Welcomes/OFC";
import SuperBalls from "./animations/Welcomes/SuperBalls";
// scrollers
import PioneerTen from "./animations/Scrollers/pioneer-10/Content";
import Elements from "./animations/Scrollers/elements/Elements";
import Circles from "./animations/Scrollers/backgrounds/circles/ContentWithAnimatedBackgroundCircles";
import Rothko from "./animations/Scrollers/backgrounds/rothko/ContentWithAnimatedBackgroundRothko";
import Drop from "./animations/Scrollers/backgrounds/drop/ContentWithAnimatedBackgroundDrop";

// logos
import Sunside from "./animations/Logos/Sunside";
import CraniumMan from "./animations/Logos/CraniumMan";
import Hillside from "./animations/Logos/Hillside";
import ThreeTwoOne from "./animations/Logos/ThreeTwoOne";
import TopBottom from "./animations/Logos/TopBottom";
import UpsyDaisy from "./animations/Logos/UpsyDaisy";

function SiteMap() {
  const pages = [
    // home page
    { path: "/home", Component: HomePage },
    // about page
    { path: "/about", Component: About },
    // welcome demos and index
    {
      path: "/welcomes/boxes",
      Component: Boxes,
      demoTitle: "boxes",
    },
    {
      path: "/welcomes/circuit-board",
      Component: CircuitBoard,
      demoTitle: "circuit board",
    },
    {
      path: "/welcomes/line-drawing",
      Component: LineDrawing,
      demoTitle: "line drawing",
    },
    {
      path: "/welcomes/gears",
      Component: Gears,
      demoTitle: "gears",
    },
    {
      path: "/welcomes/ofc",
      Component: OFC,
      demoTitle: "ofc",
    },
    {
      path: "/welcomes/superballs",
      Component: SuperBalls,
      demoTitle: "superballs",
    },
    { path: "/welcomes", Component: WelcomeAnimationPage },
    // scroll demos and index
    {
      path: "/scrollers/pioneer-10",
      Component: PioneerTen,
      demoTitle: "pioneer 10",
    },
    {
      path: "/scrollers/elements",
      Component: Elements,
      demoTitle: "elements",
    },
    {
      path: "/scrollers/circles",
      Component: Circles,
      demoTitle: "circles",
    },
    {
      path: "/scrollers/rothko",
      Component: Rothko,
      demoTitle: "rothko",
    },
    {
      path: "/scrollers/drop",
      Component: Drop,
      demoTitle: "drop",
    },
    { path: "/scrollers", Component: ScrollingAnimationPage },
    // logo demos and index
    {
      path: "/logos/sunside",
      Component: Sunside,
      demoTitle: "sunside",
    },
    {
      path: "/logos/cranium-man",
      Component: CraniumMan,
      demoTitle: "cranium man",
    },
    {
      path: "/logos/hillside",
      Component: Hillside,
      demoTitle: "hillside",
    },
    {
      path: "/logos/321",
      Component: ThreeTwoOne,
      demoTitle: "321",
    },
    {
      path: "/logos/top-bottom",
      Component: TopBottom,
      demoTitle: "top bottom",
    },
    {
      path: "/logos/upsy-daisy",
      Component: UpsyDaisy,
      demoTitle: "upsy daisy",
    },
    { path: "/logos", Component: LogoAnimationPage },
    // default
    { path: "/", Component: HomePage },
  ];

  return pages;
}

export default SiteMap;
