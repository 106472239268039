import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function HillsideDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a SVG animation that tells a little story and ends up as a logo.
        I start zoomed in on a window, then pull back to reveal the apartment
        building as day turns to night, when the unseen programmer opens up the
        shades and turns on the monitor.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I started with a{" "}
        <Link
          href="https://www.flaticon.com/free-icon/apartment_188437"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          SVG
        </Link>{" "}
        made by{" "}
        <Link
          href="https://www.flaticon.com/authors/smashicons"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Roundicons
        </Link>{" "}
        at{" "}
        <Link
          href="https://www.flaticon.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Flaticon
        </Link>{" "}
        then cut the individual pieces into distinct paths so I could animate
        them separately. I added in the text, and simple shapes for the hill,
        curtains, monitor, and stars.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        This is a great demonstration the "scalable" property of SVG (Scalable
        Vector Graphic) as an image format for web graphics, since the quality
        is perfect at any level of zoom. Additionally, this method shows the
        power of{" "}
        <Link
          href="https://greensock.com/gsap/"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          GSAP
        </Link>{" "}
        to handle simple transformations of HTML elements (scale, color,
        position) without blowing up your CPU.
      </Typography>
    </DemoDescription>
  );
}

export default HillsideDescription;
