import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import CustomEase from "gsap/CustomEase";
import CustomBounce from "gsap/CustomBounce";
import GSDevTools from "gsap/GSDevTools";
import * as Utilities from "../../AnimationUtilities";
import ContentMockup from "../../ContentMockup";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomBounce);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  root: {},
  ball: {
    borderRadius: "50%",
    position: "absolute",
    zIndex: 100,
    willChange: "transform",
  },
  floor: {
    width: "110%",
    height: "1px",
    position: "absolute",
    backgroundColor: theme.palette.text.secondary,
    visibility: "hidden",
    zIndex: 10,
    willChange: "transform",
  },
  ballsAndFloorContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: "100",
  },
  welcomeText: {
    position: "absolute",
    "& h1": {
      [theme.breakpoints.up("lg")]: {
        fontSize: "162px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "60px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "80px",
      },
    },
    color: theme.palette.text.secondary,
    zIndex: 11,
    visibility: "hidden",
  },
  background: {
    backgroundColor: theme.palette.background.paper,
    width: "100vw",
    height: "100%",
    position: "absolute",
    zIndex: 2,
  },
  backgroundTwo: {
    backgroundColor: theme.palette.text.secondary,
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 1,
    visibility: "hidden",
  },
}));

function SuperBalls(props) {
  const timelineControlsRef = React.useRef();
  const ballsAndFloorContainerRef = React.useRef();
  const floorRef = React.useRef();
  const welcomeContainerRef = React.useRef();
  const toContainerRef = React.useRef();
  const backgroundRef = React.useRef();
  const backgroundTwoRef = React.useRef();

  // ANIMATION ELEMENTS
  const ballMin = 25;
  const ballMax = 50;
  let numberOfBalls = Math.floor(window.innerWidth * 0.15);
  const maxBalls = 120;
  if (numberOfBalls > maxBalls) {
    numberOfBalls = maxBalls;
  }
  let ballArray = [];
  let refArray = [];
  for (let i = 0; i < numberOfBalls; i++) {
    ballArray.push("key-" + i);
  }

  // Bounce and Squish effect
  CustomBounce.create("myBounce", {
    strength: 0.66,
    squash: 1,
    squashID: "myBounce-squash",
  });

  useEffect(() => {
    // ELEMENTS
    const timelineControlsElement = timelineControlsRef.current;
    const ballsUnshuffled = refArray.map((ref) => ref.current);
    const balls = Utilities.sattoloShuffleCopy(ballsUnshuffled);
    const floor = floorRef.current;
    const ballsAndFloorContainer = ballsAndFloorContainerRef.current;
    const textWelcome = welcomeContainerRef.current;
    const textTo = toContainerRef.current;
    const background = backgroundRef.current;
    const backgroundTwo = backgroundTwoRef.current;

    // SETUP
    const floorPosition = 100;
    const ballColors = ["#f44336", "#76ff03", "#2196f3", "#ffeb3b"];
    balls.forEach((ball) => {
      const size = gsap.utils.random(ballMin, ballMax);
      ball.style.height = size + "px";
      ball.style.width = size + "px";
    });
    gsap.set(balls, {
      xPercent: -50,
      yPercent: -100,
      left: "50%",
      top: floorPosition + "%",
    });
    gsap.set(balls, {
      y: () =>
        gsap.utils.random(window.innerHeight * 0.25, window.innerHeight * 0.3),
      left: () => {
        return gsap.utils.random(0, 100) + "%";
      },
      backgroundColor: () => gsap.utils.random(ballColors),
    });
    Utilities.centerElement(floor, floorPosition);
    Utilities.centerElement([textWelcome, textTo], 42);
    Utilities.centerElement(background);
    Utilities.centerElement(backgroundTwo);

    // ANIMATE
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    const launchDuration = 0.5;
    const fallDuration = 2;

    mainTL.addLabel("start", 0);
    mainTL.to(balls, {
      y: () =>
        -gsap.utils.random(window.innerHeight * 1.5, window.innerHeight * 1.75),
      stagger: 0.01,
      duration: launchDuration,
    });
    mainTL.to(textWelcome, { duration: 1, autoAlpha: 1 }, "start+=0.2");
    mainTL.add("fall", "+=0.3");
    mainTL.to(textWelcome, { duration: 0.5, autoAlpha: 0 });
    mainTL.to(textTo, { duration: 1, autoAlpha: 1 }, "fall+=0.5");
    mainTL.to(floor, { autoAlpha: 0.3, duration: 0.5 }, "fall");
    mainTL.to(
      balls,
      {
        duration: fallDuration,
        stagger: 0.025,
        y: 0,
        ease: "myBounce",
      },
      "fall"
    );
    mainTL.to(
      balls,
      {
        duration: fallDuration,
        stagger: 0.025,
        scaleY: 0.8,
        scaleX: 1.2,
        ease: "myBounce-squash",
        transformOrigin: "bottom center",
      },
      "fall"
    );
    mainTL.add("end", "+=0.2");
    mainTL.to(floor, { autoAlpha: 0, duration: 0.3 }, "end");
    mainTL.to(
      [ballsAndFloorContainer, background],
      { yPercent: "-160", ease: "back.in" },
      "end"
    );
    mainTL.add("final launch", "end+=0.23");
    mainTL.set(backgroundTwo, { autoAlpha: 1 }, "final launch");
    mainTL.to(
      balls,
      {
        y: () => -gsap.utils.random(50, 181),
        duration: 0.3,
      },
      "final launch"
    );
    const splitTo = new SplitText(textTo, { type: "chars" });
    mainTL.to(
      splitTo.chars,
      {
        y: () => -gsap.utils.random(240, 280),
        rotation: () =>
          Utilities.rollForPositiveNegative(gsap.utils.random(274, 400)),
      },
      "final launch"
    );

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo
      allRefs={{ timelineControlsRef }}
      className={classes.root}
    >
      <div
        ref={ballsAndFloorContainerRef}
        className={classes.ballsAndFloorContainer}
      >
        {ballArray.map((elementKey) => {
          const newRef = React.createRef();
          refArray.push(newRef);
          return (
            <div key={elementKey} ref={newRef} className={classes.ball}></div>
          );
        })}
        <div ref={floorRef} className={classes.floor}></div>
        <div ref={welcomeContainerRef} className={classes.welcomeText}>
          <Typography variant="h1">welcome</Typography>
        </div>
        <div ref={toContainerRef} className={classes.welcomeText}>
          <Typography variant="h1">to</Typography>
        </div>
      </div>
      <div ref={backgroundRef} className={classes.background}></div>
      <div ref={backgroundTwoRef} className={classes.backgroundTwo}>
        <ContentMockup />
      </div>
    </FullscreenAnimationDemo>
  );
}

export default SuperBalls;
