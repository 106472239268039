import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap/gsap-core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    opacity: 0,
    [theme.breakpoints.up("xs")]: {
      width: theme.sizes.timelineControlsContainerWidth.xSmallUp,
    },
    [theme.breakpoints.only("md")]: {
      width: theme.sizes.timelineControlsContainerWidth.medium,
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.sizes.timelineControlsContainerWidth.large,
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.sizes.timelineControlsContainerWidth.xLarge,
    },
    height: "50px",
    bottom: 0,
    zIndex: 1000,
    "& .time-scale-label": {
      fontFamily: theme.typography.fontFamily,
    },
    "& .progress-bar": {
      backgroundColor: "#e8e8e8 !important",
    },
    "& .playhead": {
      backgroundColor: "#fefefe !important",
      borderColor: "#ddd !important",
    },
  },
}));

const TimelineControlsContainer = React.forwardRef((props, ref) => {
  const classes = useStyles();

  useEffect(() => {
    const element = ref.current;
    gsap.set(element, { xPercent: -50, yPercent: -50, left: "50%" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={ref} className={classes.root}></div>;
});

export default TimelineControlsContainer;
