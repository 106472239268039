/*
  A list of Logo animations
*/

// components
import Hillside from "../animations/Logos/Hillside";
import CraniumMan from "../animations/Logos/CraniumMan";
import Sunside from "../animations/Logos/Sunside";
import ThreeTwoOne from "../animations/Logos/ThreeTwoOne";
import TopBottom from "../animations/Logos/TopBottom";
import UpsyDaisy from "../animations/Logos/UpsyDaisy";

// descriptions
import SunsideDescription from "../animation-descriptions/logos/SunsideDescription";
import CraniumManDescription from "../animation-descriptions/logos/CraniumManDescription";
import HillsideDescription from "../animation-descriptions/logos/HillsideDescription";
import ThreeTwoOneDescription from "../animation-descriptions/logos/ThreeTwoOneDescription";
import TopBottomDescription from "../animation-descriptions/logos/TopBottomDescription";
import UpsyDaisyDescription from "../animation-descriptions/logos/UpsyDaisyDescription";

// thumbnails
import SunsideThumbnail from "../img/thumbnails/logos/dark/sunside.png";
import SunsideThumbnailLight from "../img/thumbnails/logos/light/sunside.png";
import CraniumManThumbnail from "../img/thumbnails/logos/dark/cranium-man.png";
import CraniumManThumbnailLight from "../img/thumbnails/logos/light/cranium-man.png";
import HillsideThumbnail from "../img/thumbnails/logos/dark/hillside.png";
import HillsideThumbnailLight from "../img/thumbnails/logos/light/hillside.png";
import ThreeTwoOneThumbnail from "../img/thumbnails/logos/dark/three-two-one.png";
import ThreeTwoOneThumbnailLight from "../img/thumbnails/logos/light/three-two-one.png";
import TopBottomThumbnail from "../img/thumbnails/logos/dark/top-bottom.png";
import TopBottomThumbnailLight from "../img/thumbnails/logos/light/top-bottom.png";
import UpsyDaisyThumbnail from "../img/thumbnails/logos/dark/upsy-daisy.png";
import UpsyDaisyThumbnailLight from "../img/thumbnails/logos/light/upsy-daisy.png";

const pathStart = "/logos/";

const LogoAnimations = [
  {
    heading: "sunside",
    description: <SunsideDescription path={pathStart + "sunside"} />,
    image: SunsideThumbnail,
    imageLight: SunsideThumbnailLight,
    component: Sunside,
    path: pathStart + "sunside",
  },
  {
    heading: "cranium man",
    description: <CraniumManDescription path={pathStart + "cranium-man"} />,
    image: CraniumManThumbnail,
    imageLight: CraniumManThumbnailLight,
    component: CraniumMan,
    path: pathStart + "cranium-man",
  },
  {
    heading: "3.2.1",
    description: <ThreeTwoOneDescription path={pathStart + "321"} />,
    image: ThreeTwoOneThumbnail,
    imageLight: ThreeTwoOneThumbnailLight,
    component: ThreeTwoOne,
    path: pathStart + "321",
  },
  {
    heading: "hillside",
    description: <HillsideDescription path={pathStart + "hillside"} />,
    image: HillsideThumbnail,
    imageLight: HillsideThumbnailLight,
    component: Hillside,
    path: pathStart + "hillside",
  },
  {
    heading: "top bottom",
    description: <TopBottomDescription path={pathStart + "top-bottom"} />,
    image: TopBottomThumbnail,
    imageLight: TopBottomThumbnailLight,
    component: TopBottom,
    path: pathStart + "top-bottom",
  },
  {
    heading: "upsy daisy",
    description: <UpsyDaisyDescription path={pathStart + "upsy-daisy"} />,
    image: UpsyDaisyThumbnail,
    imageLight: UpsyDaisyThumbnailLight,
    component: UpsyDaisy,
    path: pathStart + "upsy-daisy",
  },
];

export default LogoAnimations;
