import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/welcomes/ofc.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    [theme.breakpoints.up("xs")]: {
      width: theme.sizes.timelineControlsContainerWidth.xSmallUp,
    },
    [theme.breakpoints.only("md")]: {
      width: theme.sizes.timelineControlsContainerWidth.medium,
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.sizes.timelineControlsContainerWidth.large,
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.sizes.timelineControlsContainerWidth.xLarge,
    },
    height: "100%",
    visibility: "hidden",
    position: "absolute",
    transform: "translateZ(0)",
    overflow: "visible",
    "& *": {
      fill: theme.palette.animations.welcomeOFC.fill,
    },
  },
}));

function OFC(props) {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const SVG = svgRef.current;
    const welcomeWhole = document.querySelector(
      "#orderFromChaos #ofcWelcomeWhole"
    );
    const welcomeInPieces = document.querySelector(
      "#orderFromChaos #ofcWelcomeInPieces"
    );
    const welcomePieces = Array.prototype.slice.call(
      document.querySelectorAll("#orderFromChaos .ofc-piece")
    );
    const timelineControlsElement = timelineControlsRef.current;

    // SETUP
    Utilities.centerElement(SVG, 45);
    gsap.set(welcomeWhole, { autoAlpha: 0 });
    gsap.set(welcomePieces, { autoAlpha: 0 });

    // ANIMATE
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.to(SVG, { autoAlpha: 1, duration: 0.3 });
    mainTL.add("start");
    mainTL.to(welcomePieces, { duration: 0.2, autoAlpha: 1 }, "start");
    mainTL.add(assemble(welcomePieces), "start");
    mainTL.add(stretch(welcomePieces), "+=0.2");
    mainTL.add(finish(welcomeWhole, welcomeInPieces));

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  function assemble(pieces) {
    const tl = gsap.timeline();
    const shuffledElements = Utilities.sattoloShuffleCopy(pieces);
    const duration = 2;

    tl.from(shuffledElements, {
      x: () => {
        let distance = gsap.utils.random(180, 310);
        return Utilities.rollForPositiveNegative(distance);
      },
      y: () => {
        const base = 83;
        let distance = gsap.utils.random(50, 70);
        return base - Utilities.rollForPositiveNegative(distance);
      },
      rotation: () => {
        let angle = gsap.utils.random(1440, 2400);
        return Utilities.rollForPositiveNegative(angle);
      },
      scale: () => {
        return gsap.utils.random(1.03, 1.1);
      },
      transformOrigin: "50% 50%",
      duration: duration,
    });

    return tl;
  }

  function stretch(pieces) {
    /*
      Element Stretch Positions
    */
    // stretch positions X
    var stretchDestinationsX = [
      // w
      "-=1",
      "-=1.2",
      "-=0.8",
      "+=0.8",
      "+=0.5",
      "+=0.7",
      "+=0.9",
      "-=0.4",
      "-=1.5",
      // e
      "+=0.8",
      "+=1.2",
      "+=0.6",
      "-=0.6",
      "-=1.1",
      "-=0.7",
      "+=0.5",
      // l
      "-=0.6",
      "+=0.3",
      "-=0.7",
      "+=0.8",
      // c
      "+=0.6",
      "-=0.6",
      "-=1.1",
      "+=0.8",
      // o
      "-=1",
      "-=0.6",
      "+=0.4",
      "+=0.7",
      "+=1.2",
      "+=0.6",
      "-=0.3",
      // m
      "+=1",
      "+=0.7",
      "+=0.4",
      "-=0.3",
      "+=0.4",
      "-=0.1",
      "-=0.4",
      "-=1.1",
      "-=0.3",
      "-=0.7",
      // e
      "+=1",
      "-=0.5",
      "-=0.9",
      "-=0.5",
      "+=0.7",
      "+=1",
      "+=0.4",
    ];

    // stretch positions Y
    var stretchDestinationsY = [
      // w
      "+=0.5",
      "+=1.2",
      "+=0.5",
      "+=0.4",
      "+=1.1",
      "+=0.6",
      "-=0.85",
      "-=0.8",
      "-=0.9",
      // e
      "-=0.4",
      "-=0.5",
      "+=0.7",
      "+=1",
      "+=0.4",
      "-=1",
      "-=1.2",
      // l
      "+=1",
      "+=0.5",
      "-=0.5",
      "-=1",
      // c
      "+=1",
      "+=0.5",
      "-=0.5",
      "-=1",
      // o
      "-=0",
      "+=0.5",
      "+=0.7",
      "+=0.5",
      "-=0.5",
      "-=1",
      "-=1",
      // m
      "+=0.8",
      "-=0.6",
      "-=0.8",
      "+=0.5",
      "+=0.3",
      "-=0.9",
      "-=1",
      "+=0.9",
      "+=0.3",
      "-=1",
      // e
      "+=0.7",
      "+=0.8",
      "-=1",
      "-=0.5",
      "+=0.3",
      "-=0.8",
      "-=1.1",
    ];

    const duration = 0.3;
    const tl = gsap.timeline();
    tl.to(pieces, {
      x: (i) => {
        return stretchDestinationsX[i];
      },
      y: (i) => {
        return stretchDestinationsY[i];
      },
      transformOrigin: "50% 50%",
      duration: duration,
      repeat: 1,
      yoyo: "true",
    });
    return tl;
  }

  function finish(whole, inPieces) {
    const tl = gsap.timeline();
    tl.to(whole, { autoAlpha: 1, immediateRender: "false", duration: 0.01 });
    tl.to(inPieces, { autoAlpha: 0, duration: 0.3 });
    return tl;
  }

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <SVG ref={svgRef} className={classes.svg} />
    </FullscreenAnimationDemo>
  );
}

export default OFC;
