import React, { forwardRef, useEffect } from "react";
import { ReactComponent as SVG } from "../../../img/scrollers/elements/scroll-down-arrows-optimized.svg";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import gsap from "gsap";

const ScrollDownArrows = forwardRef((props, ref) => {
  const containerRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;

    // ANIMATION
    const tl = gsap.timeline({ delay: props.delay });

    // setup
    const arrows = svgElement.children[0].children;
    gsap.set(arrows, { strokeWidth: "5px" });

    // ARROWS
    const arrowsTL = gsap.timeline({ repeat: -1, repeatDelay: 2.71 });
    tl.add(arrowsTL, 0.5);
    const arrowsDuration = 0.5;
    const childTLOptions = { repeat: 2, repeatDelay: 0.3 };
    const arrowsChildTL = gsap.timeline(childTLOptions);
    arrowsChildTL.to(arrows, {
      y: "55px",
      strokeWidth: "10px",
      duration: arrowsDuration,
      transformOrigin: "50% 50%",
      stagger: { each: 0.1, repeat: 1, yoyo: true },
    });
    arrowsTL.add(arrowsChildTL, 0);
  }, [props.delay]);

  const useStyles = makeStyles((theme) => ({
    gridContainer: {},
    svgContainer: {
      marginTop: "2em",
    },
    svg: {
      width: "80px",
      [theme.breakpoints.only("xs")]: {
        width: "60px",
      },
      [theme.breakpoints.only("sm")]: {
        width: "70px",
      },
      height: "auto",
      overflow: "visible",
      "& path": {
        stroke: theme.palette.text.primary,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.gridContainer}
      container
      justify="center"
    >
      <Grid className={classes.svgContainer} item xs={12} align="center">
        <SVG ref={svgRef} className={classes.svg} />
      </Grid>
    </Grid>
  );
});

export default ScrollDownArrows;
