/*
  A list of Welcome animations
*/

// components
import Boxes from "../animations/Welcomes/Boxes";
import OFC from "../animations/Welcomes/OFC";
import SuperBalls from "../animations/Welcomes/SuperBalls";
import Gears from "../animations/Welcomes/Gears";
import CircuitBoard from "../animations/Welcomes/CircuitBoard";
import LineDrawing from "../animations/Welcomes/Line-Drawing/LineDrawingDemo";

// descriptions
import BoxesDescription from "../animation-descriptions/welcomes/BoxesDescription";
import OFCDescription from "../animation-descriptions/welcomes/OFCDescription";
import GearsDescription from "../animation-descriptions/welcomes/GearsDescription";
import SuperBallsDescription from "../animation-descriptions/welcomes/SuperBallsDescription";
import CircuitBoardDescription from "../animation-descriptions/welcomes/CircuitBoardDescription";
import LineDrawingDescription from "../animation-descriptions/welcomes/LineDrawingDescription";

// thumbnails
import BoxesThumbnail from "../img/thumbnails/welcomes/dark/boxes.png";
import BoxesThumbnailLight from "../img/thumbnails/welcomes/light/boxes.png";
import OFCThumbnail from "../img/thumbnails/welcomes/dark/ofc.png";
import OFCThumbnailLight from "../img/thumbnails/welcomes/light/ofc.png";
import SuperballsThumbnail from "../img/thumbnails/welcomes/dark/superballs.png";
import SuperballsThumbnailLight from "../img/thumbnails/welcomes/light/superballs.png";
import GearsThumbnail from "../img/thumbnails/welcomes/dark/gears.png";
import GearsThumbnailLight from "../img/thumbnails/welcomes/light/gears.png";
import CircuitBoardThumbnail from "../img/thumbnails/welcomes/dark/circuit-board.png";
import CircuitBoardThumbnailLight from "../img/thumbnails/welcomes/light/circuit-board.png";
import LineDrawingThumbnail from "../img/thumbnails/welcomes/dark/line-drawing.png";
import LineDrawingThumbnailLight from "../img/thumbnails/welcomes/light/line-drawing.png";

const pathStart = "/welcomes/";

const WelcomeAnimations = [
  {
    heading: "circuit board",
    description: <CircuitBoardDescription path={pathStart + "circuit-board"} />,
    image: CircuitBoardThumbnail,
    imageLight: CircuitBoardThumbnailLight,
    component: CircuitBoard,
    path: pathStart + "circuit-board",
  },
  {
    heading: "boxes",
    description: <BoxesDescription path={pathStart + "boxes"} />,
    image: BoxesThumbnail,
    imageLight: BoxesThumbnailLight,
    component: Boxes,
    path: pathStart + "boxes",
  },
  {
    heading: "line drawing",
    description: <LineDrawingDescription path={pathStart + "line-drawing"} />,
    image: LineDrawingThumbnail,
    imageLight: LineDrawingThumbnailLight,
    component: LineDrawing,
    path: pathStart + "line-drawing",
  },
  {
    heading: "gears",
    description: <GearsDescription path={pathStart + "gears"} />,
    image: GearsThumbnail,
    imageLight: GearsThumbnailLight,
    component: Gears,
    path: pathStart + "gears",
  },
  {
    heading: "ofc",
    description: <OFCDescription path={pathStart + "ofc"} />,
    image: OFCThumbnail,
    imageLight: OFCThumbnailLight,
    component: OFC,
    path: pathStart + "ofc",
  },
  {
    heading: "superballs",
    description: <SuperBallsDescription path={pathStart + "superballs"} />,
    image: SuperballsThumbnail,
    imageLight: SuperballsThumbnailLight,
    component: SuperBalls,
    path: pathStart + "superballs",
  },
];

export default WelcomeAnimations;
