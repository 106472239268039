import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    marginBottom: theme.spacing(2),
    "& :hover": {
      textDecoration: "underline",
    },
  },
}));

function DemoDescription(props) {
  const path = props.path ? props.path : "/home";
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {props.children}
      <Typography className={classes.link}>
        Click the thumbnail or{" "}
        <NavLink exact to={path} className={classes.link}>
          here
        </NavLink>{" "}
        to see the demo.
      </Typography>
    </div>
  );
}

export default DemoDescription;
