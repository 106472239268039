import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function CirclesDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This background animation with GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/ScrollTrigger"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          ScrollTrigger
        </Link>{" "}
        plugin moves background elements while the user scrolls, but leaves them
        in place while the screen is still to allow focus on the text.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I animate the scale and opacity of the background elements on an
        infinite loop for a subtly dynamic visual during reading.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A scrolling background animation is a great way to add some flair to a
        blog or long blocks of text, without constant movement to distract the
        user while reading. Paragraphs could be given a transparent background
        color to make the text stand out more if needed.
      </Typography>
    </DemoDescription>
  );
}

export default CirclesDescription;
