/*
  Utility functions for GSAP
  Jonathan Harrison
  2020
*/

import { gsap } from "gsap";

function centerElement(element, topPosition) {
  topPosition = topPosition ? topPosition + "%" : "50%";
  gsap.set(element, {
    xPercent: -50,
    yPercent: -50,
    left: "50%",
    top: topPosition,
  });
}

function showElement(element, duration = 0.2) {
  gsap.to(element, { duration: duration, autoAlpha: 1 });
}

function hideElement(element, duration = 0.2) {
  gsap.to(element, { duration: duration, autoAlpha: 0 });
}

export { centerElement, showElement, hideElement };
