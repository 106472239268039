import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import GSDevTools from "gsap/GSDevTools";
import { ReactComponent as SVG } from "../../img/welcomes/gears.svg";
import FullscreenAnimationDemo from "../FullscreenAnimationDemo";
import * as Utilities from "../../AnimationUtilities";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  svg: {
    width: "85%",
    maxHeight: "80%",
    position: "absolute",
    visibility: "hidden",
    transform: "translateZ(0)",
    overflow: "visible",
    "& #caption": {
      fill: theme.palette.text.secondary,
    },
  },
}));

function Gears() {
  const svgRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    const svgElement = svgRef.current;
    Utilities.centerElement(svgElement, 45);
    const timelineControlsElement = timelineControlsRef.current;
    const gears = [
      document.querySelector("#gears #gearLeft"),
      document.querySelector("#gears #gearTop"),
      document.querySelector("#gears #gearBottom"),
    ];
    const caption = document.querySelectorAll("#gears #caption path");
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.to(svgElement, { autoAlpha: 1 });
    mainTL.add(animateGears(gears, caption));

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <SVG ref={svgRef} className={classes.svg} />
    </FullscreenAnimationDemo>
  );
}

export default Gears;

function animateGears(gears, caption) {
  const mainTL = gsap.timeline();
  const gearColors = ["#f44336", "#2196f3", "#4caf50"];

  gsap.utils.toArray(gears).forEach((gear, i) => {
    gear.style.fill = gearColors[i];
    gear.style.position = "absolute";
  });

  const spinUpRotations = [25, -25, -25];
  const rotations = [1440, -1440, -1440];

  const startPositionsX = [
    "-=" + window.innerWidth * 0.66 + "px",
    "+=" + window.innerWidth * 0.1 + "px",
    "+=" + window.innerWidth * 0.66 + "px",
  ];
  const startPositionsY = [
    0,
    "-=" + window.innerHeight * 0.5 + "px",
    "+=" + window.innerHeight * 0.3 + "px",
  ];
  const startRotations = ["-=240", "+=120", "+=240"];

  // roll in
  const gearsTL = gsap.timeline();
  gearsTL.add("start");
  gearsTL.from(
    gears,
    {
      duration: 1,
      x: (i) => startPositionsX[i],
      y: (i) => startPositionsY[i],
      autoAlpha: 0,
      rotation: (i) => startRotations[i],
      stagger: 0.3,
      transformOrigin: "50% 50%",
      ease: "back.out(0.2)",
    },
    "start"
  );

  // spin up
  gearsTL.to(
    gears,
    {
      ease: "back.out(3)",
      transformOrigin: "50% 50%",
      duration: 1,
      rotation: (index) => {
        return "+=" + spinUpRotations[index];
      },
    },
    "+=0.2"
  );
  gearsTL.to(gears, {
    ease: "back.out(3)",
    transformOrigin: "50% 50%",
    duration: 1,
    rotation: (index) => {
      return "+=" + spinUpRotations[index];
    },
  });
  gearsTL.to(gears, {
    ease: "back.in(3)",
    transformOrigin: "50% 50%",
    duration: 1,
    rotation: (index) => {
      return "+=" + spinUpRotations[index];
    },
  });

  // spin
  gearsTL.to(gears, {
    ease: "none",
    transformOrigin: "50% 50%",
    duration: 8,
    rotation: (index) => {
      return "+=" + rotations[index];
    },
  });

  const captionTL = gsap.timeline();
  gsap.set(caption, { autoAlpha: 0 });
  const captionMainTL = gsap.timeline({ repeat: 2, repeatDelay: 0.5 });
  captionMainTL.to(caption, {
    autoAlpha: 1,
    stagger: {
      each: 0.2,
      repeat: 1,
      repeatDelay: 0.3,
      yoyo: true,
    },
  });
  const captionEndTL = gsap.timeline();
  captionEndTL.to(caption, {
    autoAlpha: 1,
    stagger: {
      each: 0.2,
    },
  });
  captionTL.add(captionMainTL, 0);
  captionTL.add(captionEndTL);

  const endTL = gsap.timeline();
  endTL.to([gears, caption], { autoAlpha: 0 });

  mainTL.add(gearsTL, 0);
  mainTL.add(captionTL, 1.5);
  mainTL.add(endTL, "-=0.5");

  return mainTL;
}
