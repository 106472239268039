import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import * as Utilities from "./AnimationUtilities";

const COLORS = {
  light: {
    container: "#b2ebf2",
    background: "#bbdefb",
    elements: "#f5f5f5",
  },
  dark: {
    // container: "#0b1d89",
    container: "#479d00",
    // background: "#07135a",
    background: "#479d00",
    // elements: "#07285a",
    elements: "#212121",
    // elements: theme.palette.animations.animatedHeader.elements,
  },
};

const useStyles = makeStyles((theme) => ({
  animationContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.animations.animatedHeader.container
  },
  background: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: theme.palette.animations.animatedHeader.background,
    /**
         * background-color: #479d00;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23222222' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");

background-color: #222222;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23479d00' fill-opacity='0.68' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
         */
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23222222' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
  },
  colorBandSetContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 20,
  },
  colorBandVertical: {
    height: "100%",
    display: "inline-block",
    backgroundColor: theme.palette.animations.animatedHeader.elements,
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23479d00' fill-opacity='0.68' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
    visibility: "hidden",
    zIndex: 21,
  },
  colorBandHorizontal: {
    width: "100%",
    backgroundColor:
      theme.palette.type === "dark"
        ? COLORS.dark.elements
        : COLORS.light.elements,
    visibility: "hidden",
    zIndex: 22,
  },
}));

function HeaderBackgroundAnimation() {
  const containerRef = React.useRef();
  const backgroundRef = React.useRef();

  const minVerticalColorBands = 12;
  const maxVerticalColorBands = 36;
  const numberOfVerticalColorBands = gsap.utils.clamp(
    minVerticalColorBands,
    maxVerticalColorBands,
    Math.ceil(window.innerWidth / 50)
  );
  let verticalColorBandArray = [];
  let verticalColorBandRefArray = [];
  for (let i = 0; i < numberOfVerticalColorBands; i++) {
    verticalColorBandArray.push("key-" + i);
  }

  const minHorizontalColorBands = 9;
  const maxHorizontalColorBands = 17;
  const numberOfHorizontalColorBands = gsap.utils.clamp(
    minHorizontalColorBands,
    maxHorizontalColorBands,
    Math.ceil(window.innerHeight / 100)
  );

  let horizontalColorBandArray = [];
  let horizontalColorBandRefArray = [];
  for (let i = 0; i < numberOfHorizontalColorBands; i++) {
    horizontalColorBandArray.push("key-" + i);
  }

  useEffect(() => {
    // ELEMENTS
    const background = backgroundRef.current;
    const verticalColorBandElements = verticalColorBandRefArray.map(
      (ref) => ref.current
    );
    const horizontalColorBandElements = horizontalColorBandRefArray.map(
      (ref) => ref.current
    );

    // SETUP
    verticalColorBandElements.forEach((colorBand, i) => {
      colorBand.style.width = (1 / numberOfVerticalColorBands) * 100 + "%";
    });
    horizontalColorBandElements.forEach((colorBand, i) => {
      colorBand.style.height =
        (1 / horizontalColorBandElements.length) * 100 + "%";
    });

    const colorBandSet = [
      verticalColorBandElements,
      horizontalColorBandElements,
    ];

    // animate background
    const mainTL = gsap.timeline();
    mainTL.to(background, {
      duration: 10,
      autoAlpha: 0,
      repeat: -1,
      yoyo: true,
      ease: "power4.inOut",
    });

    // start color band animation loop
    resetAnimationLoop(colorBandSet);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <div ref={containerRef} className={classes.animationContainer}>
      <div ref={backgroundRef} className={classes.background}></div>
      {/* vertical animation elements */}
      <div className={classes.colorBandSetContainer}>
        {verticalColorBandArray.map((elementKey, i) => {
          const newRef = React.createRef();
          verticalColorBandRefArray[i] = newRef;
          return (
            <div
              key={elementKey}
              ref={newRef}
              className={classes.colorBandVertical}
            ></div>
          );
        })}
      </div>
      <div className={classes.colorBandSetContainer}>
        {/* horizontal animation elements */}
        {horizontalColorBandArray.map((elementKey, i) => {
          const newRef = React.createRef();
          horizontalColorBandRefArray[i] = newRef;
          return (
            <div
              key={elementKey}
              ref={newRef}
              className={classes.colorBandHorizontal}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

function resetAnimationLoop(colorBandSets) {
  const setupTL = gsap.timeline({
    onComplete: () => {
      const roll = gsap.utils.random(0, 1);
      const nextSet = roll > 0.5 ? colorBandSets[0] : colorBandSets[1];
      rollIn(colorBandSets, nextSet);
    },
  });
  const setupDuration = 0.3;
  setupTL.to(colorBandSets[0], { autoAlpha: 0, duration: setupDuration }, 0);
  setupTL.to(colorBandSets[1], { autoAlpha: 0, duration: setupDuration }, 0);
}

function rollIn(colorBandSets, currentSet) {
  const delay = gsap.utils.random(1.3, 1.8);
  const inFunctions = [fadeIn, fadeInRandom, fadeInGradientWave, scaleIn];
  gsap.delayedCall(delay, () => {
    const inFunction = gsap.utils.random(inFunctions);
    // const inFunction = scaleIn;
    inFunction(colorBandSets, currentSet);
  });
}

function rollOut(colorBandSets, currentSet) {
  const delay = gsap.utils.random(2, 2.5);
  const outFunctions = [fadeOut, fadeOutRandom, scaleOutRandom];
  gsap.delayedCall(delay, () => {
    const outFunction = gsap.utils.random(outFunctions);
    // const outFunction = scaleOutRandom;
    outFunction(colorBandSets, currentSet);
  });
}

// IN ANIMATIONS
function fadeIn(sets, currentSet) {
  const targets =
    gsap.utils.random(0, 1) > 0.5 ? currentSet : currentSet.slice().reverse();
  const mainTL = gsap.timeline();
  mainTL.to(targets, {
    autoAlpha: 1,
    stagger: 0.1,
    onComplete: rollOut,
    onCompleteParams: [sets, currentSet],
  });
}

function fadeInRandom(sets, currentSet) {
  const targets = Utilities.sattoloShuffleCopy(currentSet);
  const mainTL = gsap.timeline();
  const minDuration = 1.5;
  const maxDuration = 2;
  mainTL.to(targets, {
    autoAlpha: 1,
    duration: () => gsap.utils.random(minDuration, maxDuration),
    stagger: 0.15,
    onComplete: rollOut,
    onCompleteParams: [sets, currentSet],
  });
}

function scaleIn(sets, currentSet) {
  // currentSet = sets[0];
  const targets =
    gsap.utils.random(0, 1) > 0.5 ? currentSet : currentSet.slice().reverse();
  const ease = "Power2.inOut";
  const mainTL = gsap.timeline();
  let transformOrigins = ["50% 0%", "50% 100%", "0% 50%", "100% 50%"];
  let tween;
  if (currentSet === sets[0]) {
    mainTL.set(targets, { scaleX: 1.05, transformOrigin: "50% 50%" });
    tween = gsap.from(Utilities.sattoloShuffleCopy(targets), {
      duration: 0.5,
      stagger: 0.075,
      scaleY: 0,
      transformOrigin: () =>
        gsap.utils.random(0, 1) > 0.5
          ? transformOrigins[0]
          : transformOrigins[1],
      ease: ease,
    });
  } else {
    mainTL.set(targets, { scaleY: 1.05, transformOrigin: "50% 50%" });
    tween = gsap.from(targets, {
      duration: 0.75,
      stagger: 0.2,
      scaleX: 0,
      transformOrigin:
        gsap.utils.random(0, 1) > 0.5
          ? transformOrigins[2]
          : transformOrigins[3],
      ease: ease,
      // onComplete: rollOut,
      // onCompleteParams: [sets, currentSet],
    });
  }

  mainTL.set(targets, { autoAlpha: 1 });
  mainTL.add(tween);
  mainTL.set(targets, { scaleX: 1, scaleY: 1 });
  mainTL.call(() => {
    rollOut(sets, currentSet);
  });
}

function fadeInGradientWave(sets, currentSet) {
  const mainTL = gsap.timeline();

  const minDuration = 0.5;
  const maxDuration = 1.5;
  const targets =
    gsap.utils.random(0, 1) > 0.5 ? currentSet : currentSet.slice().reverse();
  const targetsBackward = targets.slice().reverse();
  const duration = gsap.utils.random(minDuration, maxDuration);
  const stagger = 0.05;

  const secondOpacityTween = 0.2;
  targets.forEach((element, i) => {
    const alpha = (1 / targets.length) * i;
    mainTL.to(
      element,
      {
        autoAlpha: 1,
        duration: 0.5,
        onComplete: () => {
          gsap.to(element, { autoAlpha: alpha, duration: secondOpacityTween });
        },
      },
      "-=" + 0.375
    );
  });
  mainTL.to(targetsBackward, {
    autoAlpha: 1,
    duration: duration,
    stagger: stagger,
    onComplete: rollOut,
    onCompleteParams: [sets, currentSet],
  });
}

// OUT ANIMATIONS
function fadeOut(sets, currentSet) {
  const targets =
    gsap.utils.random(0, 1) > 0.5 ? currentSet : currentSet.slice().reverse();
  const mainTL = gsap.timeline();
  mainTL.to(targets, {
    autoAlpha: 0,
    duration: 0.5,
    stagger: 0.1,
    onComplete: resetAnimationLoop,
    onCompleteParams: [sets],
  });
}

function fadeOutRandom(sets, currentSet) {
  const targets = Utilities.sattoloShuffleCopy(currentSet);
  const stagger = currentSet === sets[0] ? 0.1 : 0.2;
  const minDuration = 1.5;
  const maxDuration = 2;
  const mainTL = gsap.timeline();
  mainTL.to(targets, {
    autoAlpha: 0,
    duration: () => gsap.utils.random(minDuration, maxDuration),
    stagger: stagger,
    onComplete: resetAnimationLoop,
    onCompleteParams: [sets],
  });
}

function scaleOutRandom(sets, currentSet) {
  const targets = Utilities.sattoloShuffleCopy(currentSet);
  const ease = "Power2.inOut";
  const mainTL = gsap.timeline();
  let tween;
  if (currentSet === sets[0]) {
    mainTL.set(targets, { scaleX: 1.05, transformOrigin: "50% 50%" });
    tween = gsap.to(targets, {
      duration: 0.5,
      stagger: 0.075,
      scaleY: 0,
      transformOrigin: () => {
        return "50% " + gsap.utils.random([0, 100]) + "%";
      },
      ease: ease,
    });
  } else {
    mainTL.set(targets, { scaleY: 1.05, transformOrigin: "50% 50%" });
    tween = gsap.to(targets, {
      duration: 0.75,
      stagger: 0.1,
      scaleX: 0,
      transformOrigin: () => {
        return gsap.utils.random([0, 100]) + "% 50%";
      },
      ease: ease,
    });
  }

  mainTL.set(targets, { autoAlpha: 1 });
  mainTL.add(tween);
  mainTL.set(targets, { autoAlpha: 0 });
  mainTL.set(targets, { scaleX: 1, scaleY: 1 });
  mainTL.call(() => {
    resetAnimationLoop(sets, currentSet);
  });
}

export default HeaderBackgroundAnimation;
