import React from "react";
import { useState, useEffect } from "react";
import { Paper, CssBaseline, useMediaQuery } from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import "./fonts/redacted-font-old-sources/fonts/web/stylesheet.css";
import NavBar from "./NavBar";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import Content from "./Content";
import MakeTheme from "./MakeTheme";

export default function App() {
  const containerRef = React.useRef();
  const contentRef = React.useRef();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });
  const siteMode = isLocalStorageSet()
    ? getSiteModeFromLocalStorage()
    : () => {
        setSiteMode(prefersDarkMode);
        return prefersDarkMode;
      };

  const [darkMode, setDarkMode] = useState(siteMode);
  const [brightnessIcon, setBrightnessIcon] = useState();
  let [init, setInit] = useState(true);

  // themes
  const lightTheme = MakeTheme(false);
  const darkTheme = MakeTheme(true);

  const useStyles = makeStyles((theme) => ({
    container: {
      minHeight: "100vh",
      minWidth: "100%",
      overflowX: "hidden",
      visibility: "hidden",
    },
  }));

  useEffect(() => {
    // sync dark mode icon to darkMode state
    setBrightnessIcon(darkMode ? <Brightness3Icon /> : <WbSunnyIcon />);

    if (init) {
      const appContainer = containerRef.current;
      gsap.to(appContainer, { autoAlpha: 1, delay: 0.1 });
      setInit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, darkMode]);

  function toggleTheme() {
    setDarkMode(!darkMode);
    setSiteMode(!darkMode);
  }
  function isLocalStorageSet() {
    if (localStorage.getItem("jlh_site_mode")) {
      return true;
    } else {
      return false;
    }
  }

  function getSiteModeFromLocalStorage() {
    const siteMode = localStorage.getItem("jlh_site_mode");
    return siteMode === "dark" ? true : false;
  }

  function setSiteMode(siteMode) {
    const siteModeStr = siteMode ? "dark" : "light";
    localStorage.setItem("jlh_site_mode", siteModeStr);
  }

  const hideContent = () => {
    const contentElement = contentRef.current;
    const tl = gsap.timeline();
    tl.to(contentElement, { autoAlpha: 0, duration: 0.2 });
    return tl;
  };
  const showContent = () => {
    const contentElement = contentRef.current;
    const tl = gsap.timeline();
    tl.fromTo(
      contentElement,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.2 }
    );
    return tl;
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Paper
        ref={containerRef}
        className={classes.container}
        square
        elevation={0}
      >
        <NavBar
          title="jlh.us"
          themeToggle={toggleTheme}
          brightnessIcon={brightnessIcon}
          showContent={showContent}
          hideContent={hideContent}
        />
        <Content ref={contentRef} />
      </Paper>
    </ThemeProvider>
  );
}
