import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function DropDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a background animation with GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/ScrollTrigger"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          ScrollTrigger
        </Link>{" "}
        plugin that doubles as a progress indicator. Blocks fall from the top of
        the viewport as the user scrolls through the text, reaching the bottom
        as the content is finished.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I used GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/GSAP/UtilityMethods/interpolate()"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          interpolate
        </Link>{" "}
        utility to give the elements a block gradient effect, with each block's
        color set to one step of the transition between two colors.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A scrolling background animation is a great way to add some flair to a
        blog or long blocks of text, without constant movement to distract the
        user while reading. Paragraphs could be given a transparent background
        color to make the text stand out more if needed.
      </Typography>
    </DemoDescription>
  );
}

export default DropDescription;
