import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CustomEase from "gsap/CustomEase";
import CustomBounce from "gsap/CustomBounce";
import * as Utilities from "../../../../AnimationUtilities";
import Block from "./Block";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomBounce);

const NUMBER_OF_ELEMENTS = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  grid: {
    position: "relative",
  },
  block: {
    display: "none",
  },
}));

function AnimatedBackground(props) {
  const containerRef = React.useRef();
  const gridRef = React.useRef();

  let blockArray = [];
  let blockRefArray = [];
  for (let i = 0; i < NUMBER_OF_ELEMENTS; i++) {
    blockArray.push("key-" + i);
  }

  // Bounce and Squish effect
  CustomBounce.create("myBounce", {
    strength: 0.1,
    squash: 1,
    squashID: "myBounce-squash",
  });

  useEffect(() => {
    // ELEMENTS
    const containerElement = containerRef.current;
    const blockElements = blockRefArray.map((ref) => ref.current);
    const startingXPositions = [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5];

    // window resize
    function handleResizeDrop() {
      const width = blockElements[0].offsetWidth;
      blockElements.forEach((block) => {
        block.style.height = width + "px";
      });
    }

    // SETUP
    blockElements.forEach((block) => {
      block.style.width = "12.5%";
      block.style.visibility = "hidden";
    });

    // ANIMATE
    const mainTL = gsap.timeline({ onStart: handleResizeDrop });
    const animationElements = Utilities.sattoloShuffleCopy(blockElements);
    const stagger = 0.01;
    mainTL.set(blockElements, {
      bottom: 0,
      left: (i) => startingXPositions[i] + "%",
      y: () => {
        return -window.innerHeight;
      },
      transformOrigin: "50% 100%",
      display: "block",
    });
    mainTL.set(
      blockElements,
      {
        autoAlpha: () => {
          return gsap.utils.random(0.81, 0.86);
        },
      },
      0.1
    );
    mainTL.to(
      animationElements,
      {
        y: 0,
        stagger: stagger,
        ease: "myBounce",
      },
      0
    );
    mainTL.to(
      animationElements,
      {
        scaleY: 0.8,
        scaleX: 1.2,
        stagger: stagger,
        ease: "myBounce-squash",
        transformOrigin: "bottom center",
      },
      0
    );

    ScrollTrigger.create({
      animation: mainTL,
      invalidateOnRefresh: true,
      trigger: containerElement.parentElement,
      start: "top 50px",
      end: "bottom bottom",
      scrub: 0.6,
      toggleActions: "play reset play reset",
      onLeave: () => {
        gsap.set(containerElement, { position: "absolute", bottom: 0 });
      },
      onEnterBack: () => {
        gsap.set(containerElement, { position: "fixed", top: 0 });
      },
    });

    window.addEventListener("resize", handleResizeDrop);
    return () => {
      window.removeEventListener("resize", handleResizeDrop);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      className={classes.root}
      ref={containerRef}
      container
      justify="center"
    >
      <Grid ref={gridRef} className={classes.grid} item xs={12} md={8} lg={6}>
        {blockArray.map((elementKey, i) => {
          const newRef = React.createRef();
          blockRefArray[i] = newRef;
          const blockColors = [
            theme.palette.animations.drop.blockColor1,
            theme.palette.animations.drop.blockColor2,
            theme.palette.animations.drop.blockColor3,
            theme.palette.animations.drop.blockColor4,
            theme.palette.animations.drop.blockColor5,
            theme.palette.animations.drop.blockColor6,
            theme.palette.animations.drop.blockColor7,
            theme.palette.animations.drop.blockColor8,
          ];
          return (
            <Block
              key={elementKey}
              ref={newRef}
              color={blockColors[i]}
              className={classes.block}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

export default AnimatedBackground;
