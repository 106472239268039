import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function RothkoDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        For this background animation with GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/ScrollTrigger"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          ScrollTrigger
        </Link>{" "}
        plugin, I animate three pieces of the background to different scales,
        always adding up to 100% of the viewport.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I used three color field paintings by{" "}
        <Link
          href="https://en.wikipedia.org/wiki/Mark_Rothko"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Mark Rothko
        </Link>{" "}
        (
        <Link
          href="https://arthur.io/art/mark-rothko/no-17"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          NO. 17
        </Link>
        {", "}
        <Link
          href="https://arthur.io/art/mark-rothko/no-61-rust-and-blue"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          NO. 61
        </Link>
        {", "}
        <Link
          href="https://www.moma.org/collection/works/37042"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          UNTITLED
        </Link>
        {") "}for the color schemes, which can be changed by clicking the
        painting title in the demo.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A scrolling background animation is a great way to add some flair to a
        blog or long blocks of text, without constant movement to distract the
        user while reading. Paragraphs could be given a transparent background
        color to make the text stand out more if needed.
      </Typography>
    </DemoDescription>
  );
}

export default RothkoDescription;
