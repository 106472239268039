import React from "react";
import { useEffect } from "react";
import { Container, Grid, Paper, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import TabletMacIcon from "@material-ui/icons/TabletMac";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import TvIcon from "@material-ui/icons/Tv";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "visible",
  },
  paperStyles: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "1px solid " + theme.palette.animations.elements.grid.border,
    minHeight: "100%",
  },
  title: {
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
  },
  textStyles: {},
  link: {
    color: theme.palette.animations.elements.link.color,
  },
  threeIconContainer: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      minWidth: "20%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {},
  },
  fourIconContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& svg": {
      width: "18%",
      maxWidth: "80px",
      height: "auto",
    },
  },
  centerForMdUp: {},
  mediumUp: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function ScrollingGridAnimations() {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const blockOneRef = React.useRef();
  const blockTwoRef = React.useRef();
  const blockThreeRef = React.useRef();
  const blockFourRef = React.useRef();
  const blockFiveRef = React.useRef();
  const blockSixRef = React.useRef();
  const blockSevenRef = React.useRef();
  const scrollBoldRef = React.useRef();

  useEffect(() => {
    const containerElement = containerRef.current;
    const titleElement = titleRef.current;
    const blockOneElement = blockOneRef.current;
    const blockTwoElement = blockTwoRef.current;
    const blockThreeElement = blockThreeRef.current;
    const blockFourElement = blockFourRef.current;
    const blockFiveElement = blockFiveRef.current;
    const blockSixElement = blockSixRef.current;
    const blockSevenElement = blockSevenRef.current;
    const scrollBoldElement = scrollBoldRef.current;
    const blocks = [
      blockOneElement,
      blockTwoElement,
      blockThreeElement,
      blockFourElement,
      blockFiveElement,
      blockSixElement,
      blockSevenElement,
    ];

    animateTitle(titleElement);
    animateGridBlocks(containerElement, blocks);
    scrollBold(scrollBoldElement, containerElement);
  }, []);

  const classes = useStyles();

  return (
    <Container ref={containerRef} className={classes.root}>
      <Grid container spacing={2} alignItems="stretch">
        <Typography ref={titleRef} className={classes.title} variant="h2">
          responsive grid
        </Typography>
        <Grid ref={blockOneRef} item xs={12}>
          <Paper className={classes.paperStyles} elevation={0}>
            <Typography
              className={classes.textStyles}
              variant="h6"
              align="left"
            >
              Scrolling animations grab user attention and can be used to bring
              focus <span ref={scrollBoldRef}>toward a piece of content.</span>{" "}
              I'm using{" "}
              <Link
                target="_blank"
                rel="noopener"
                className={classes.link}
                href="https://material-ui.com"
              >
                Material UI
              </Link>
              , a React library built to{" "}
              <Link
                target="_blank"
                rel="noopener"
                className={classes.link}
                href="https://material.io"
              >
                Google's Material Design
              </Link>{" "}
              guidelines.
            </Typography>
          </Paper>
        </Grid>
        <Grid
          ref={blockTwoRef}
          item
          xs={12}
          md={3}
          className={classes.mediumUp}
        >
          <Paper
            className={[classes.fourIconContainer, classes.paperStyles].join(
              " "
            )}
            elevation={0}
          >
            <PhoneIphoneIcon />
            <TabletMacIcon />
            <DesktopMacIcon />
            <TvIcon />
          </Paper>
        </Grid>
        <Grid ref={blockThreeRef} item xs={12} md={9}>
          <Paper className={classes.paperStyles} elevation={0}>
            <Typography className={classes.textStyles} align="left">
              The following are a few examples of using scroll input to reveal
              content.
              <span className={classes.mediumUp}>
                {" "}
                I hope you get some ideas about how scroll animations can
                enhance the user experience on your site.
              </span>
            </Typography>
          </Paper>
        </Grid>
        <Grid ref={blockFourRef} item xs={12} md={9}>
          <Paper
            className={classes.paperStyles}
            elevation={0}
            style={{ lineHeight: "100%" }}
          >
            <Typography className={classes.textStyles} align="left">
              <Link
                target="_blank"
                rel="noopener"
                className={classes.link}
                href="https://greensock.com/gsap/"
              >
                GSAP's
              </Link>{" "}
              <Link
                target="_blank"
                rel="noopener"
                className={classes.link}
                href="https://greensock.com/scrolltrigger/"
              >
                ScrollTrigger
              </Link>{" "}
              plugin makes handling multiple scrolling animations on a single
              page a breeze
              <span className={classes.mediumUp}>
                , and eliminates the problems and performance hits that come
                with libraries that use scrolljacking
              </span>
              .
            </Typography>
          </Paper>
        </Grid>
        <Grid
          ref={blockFiveRef}
          item
          xs={6}
          md={3}
          className={classes.mediumUp}
        >
          <Paper
            className={[classes.threeIconContainer, classes.paperStyles].join(
              " "
            )}
            elevation={0}
          >
            <HomeWorkIcon />
            <LocalCafeIcon />
            <OfflinePinIcon />
          </Paper>
        </Grid>
        <Grid
          ref={blockSixRef}
          item
          xs={12}
          md={6}
          className={classes.mediumUp}
        >
          <Paper className={classes.paperStyles} elevation={0}>
            <Typography className={classes.textStyles} align="left">
              Responsive grid layouts shift element size and position to display
              content in the best way for the device the visitor is using.
            </Typography>
          </Paper>
        </Grid>
        <Grid ref={blockSevenRef} item xs={12} md={6}>
          <Paper className={classes.paperStyles} elevation={0}>
            <Typography className={classes.textStyles} align="left">
              This grid item is full screen width on small and extra-small
              screens, and half the width of the screen on medium, large, and
              extra-large screens.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ScrollingGridAnimations;

function animateTitle(title, container) {
  const text = new SplitText(title, { type: "words,chars" });
  const scrollDuration = window.innerHeight * 0.3;
  const mainTL = gsap.timeline();
  mainTL.from(text.chars, { autoAlpha: 0, stagger: 0.05 });
  ScrollTrigger.create({
    animation: mainTL,
    trigger: title,
    start: "top 85%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });
}

function animateGridBlocks(container, blocks) {
  const revealDirection = ["-120", "-150", "120", "-120", "150", "-120", "120"];
  const height = window.innerHeight;
  const scrollDuration = window.innerWidth > 799 ? height * 0.6 : height * 1.2;

  const blockTL = gsap.timeline();
  blockTL.from(
    blocks[0],
    {
      xPercent: (index) => {
        return revealDirection[0];
      },
      autoAlpha: 0,
    },
    0
  );
  // row 2
  blockTL.from([blocks[1], blocks[2]], {
    xPercent: (index) => {
      return revealDirection[index + 1];
    },
    autoAlpha: 0,
  });
  // row 3
  blockTL.from([blocks[3], blocks[4]], {
    xPercent: (index) => {
      return revealDirection[index + 1];
    },
    autoAlpha: 0,
  });
  // row 4
  blockTL.from([blocks[5], blocks[6]], {
    xPercent: (index) => {
      return revealDirection[index + 1];
    },
    autoAlpha: 0,
  });
  ScrollTrigger.create({
    animation: blockTL,
    trigger: container,
    // once: true,
    start: "top 80%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    ease: "none",
  });
}

function scrollBold(textElement, container, containerPosition) {
  const mainTL = gsap.timeline();
  const startPosition = containerPosition ? containerPosition : "33%";
  const duration = window.innerHeight * 0.2;

  const text = new SplitText(textElement, { type: "words chars" });
  mainTL.to(text.chars, {
    scale: 1.85,
    transformOrigin: "50% 50%",
    stagger: { each: 0.2, repeat: 1, yoyo: true },
  });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top " + startPosition,
    end: "+=" + duration,
    scrub: 0.2,
    toggleActions: "play reset play reset",
  });
}
