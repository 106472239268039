import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Divider } from "@material-ui/core";
import gsap from "gsap";
import Header from "./AnimatedHeader";
import SubHeader from "./SubHeader";
import AnimationDescriptionAndDemoLink from "./AnimationDescriptionAndDemoLink";
import LogoAnimations from "./animation-lists/LogoAnimations";

function LogoAnimationPage(props) {
  const containerRef = React.useRef();

  useEffect(() => {
    if (sessionStorage.getItem("linkedFromHomePage")) {
      window.scrollTo(0, 0);
      sessionStorage.removeItem("linkedFromHomePage");
    }
    const container = containerRef.current;
    gsap.to(container, { autoAlpha: 1 });
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      visibility: "hidden",
    },
    mainContent: {
      minHeight: theme.sizes.bodyMinHeight,
    },
    gridContainer: {
      position: "relative",
    },
    gridItem: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    gridItemThumbnail: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    message: {
      position: "absolute",
      width: "100vw",
    },
    rowDivider: {
      backgroundColor: theme.palette.dividerColor,
      width: "100%",
    },
  }));

  const classes = useStyles();

  return (
    <div ref={containerRef} className={classes.root}>
      <Header text="logo animations" />
      <SubHeader text="bring your branding to life" />
      <div className={classes.mainContent}>
        <Container maxWidth="md">
          <Grid
            className={classes.gridContainer}
            container
            justify="center"
            alignItems="center"
          >
            {LogoAnimations.map(
              (
                { heading, description, image, imageLight, component, path },
                index
              ) => (
                <React.Fragment
                  key={LogoAnimations.findIndex(
                    (x) => x.heading === heading
                  ).toString()}
                >
                  <AnimationDescriptionAndDemoLink
                    heading={heading}
                    description={description}
                    image={image}
                    imageLight={imageLight}
                    component={component}
                    path={path}
                  />
                  {index < LogoAnimations.length - 1 && (
                    <Divider className={classes.rowDivider} />
                  )}
                </React.Fragment>
              )
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default LogoAnimationPage;
