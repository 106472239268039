import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { ReactComponent as SVG } from "../../../img/scrollers/pioneer-10/scroll-down-arrows-optimized.svg";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import gsap from "gsap";

const ScrollDownArrows = forwardRef((props, ref) => {
  const containerRef = React.useRef();
  const svgRef = React.useRef();

  const MAIN_TIMELINE = gsap.timeline({ paused: true });

  useImperativeHandle(ref, () => ({
    playTimeline,
  }));

  function playTimeline() {
    MAIN_TIMELINE.play();
  }

  useEffect(() => {
    // ELEMENTS
    const svgElement = svgRef.current;

    // setup
    const arrows = svgElement.children[0].children;
    gsap.set(arrows, { strokeWidth: "5px", autoAlpha: 0 });

    // REVEAL
    const revealTL = gsap.timeline();
    revealTL.to(arrows, { autoAlpha: 1, stagger: 0.25 });

    // ARROWS
    const arrowsTL = gsap.timeline({ repeat: -1, repeatDelay: 2.71 });
    const arrowsDuration = 0.5;
    const childTLOptions = { repeat: 2, repeatDelay: 0.3 };
    const arrowsChildTL = gsap.timeline(childTLOptions);
    arrowsChildTL.to(arrows, {
      y: "55px",
      strokeWidth: "10px",
      duration: arrowsDuration,
      transformOrigin: "50% 50%",
      stagger: { each: 0.1, repeat: 1, yoyo: true },
    });
    arrowsTL.add(arrowsChildTL, 0);

    MAIN_TIMELINE.add(revealTL);
    MAIN_TIMELINE.add(arrowsTL, "+=1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles((theme) => ({
    gridContainer: {},
    svgContainer: {
      marginTop: "2em",
    },
    svg: {
      width: "55px",
      height: "auto",
      overflow: "visible",
      "& path": {
        stroke: theme.palette.animations.pioneer10.scrollDownArrows,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.gridContainer}
      container
      justify="center"
    >
      <Grid className={classes.svgContainer} item xs={12} align="center">
        <SVG ref={svgRef} className={classes.svg} />
      </Grid>
    </Grid>
  );
});

export default ScrollDownArrows;
