import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import HeaderBackgroundAnimation from "./HeaderBackgroundAnimation";
import * as Utilities from "./AnimationUtilities";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: theme.sizes.headerHeight,
    minHeight: "300px",
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.background.header,
  },
  message: {
    position: "absolute",
    width: "100%",
    zIndex: 1000,
    [theme.breakpoints.only("xs")]: {
      fontSize: "44px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "48px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "56px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
    },
  },
}));

function AnimatedHeader(props) {
  const messageRef = React.useRef();
  const message = props.text ? props.text : "Jonathan Harrison";

  useEffect(() => {
    // ELEMENTS
    const message = messageRef.current;
    Utilities.centerElement(message);
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="h1"
        ref={messageRef}
        className={classes.message}
        align="center"
      >
        {message}
      </Typography>
      <HeaderBackgroundAnimation />
    </div>
  );
}

export default AnimatedHeader;
