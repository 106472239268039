import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  italicize: {
    fontStyle: "italic",
  },
  quote: {
    marginLeft: theme.spacing(2),
    fontStyle: "italic",
  },
}));

function PlaceholderText() {
  const classes = useStyles();

  return (
    <>
      <Typography paragraph>
        Ineluctable modality of the visible: at least that if no more, thought
        through my eyes. Signatures of all things I am here to read, seaspawn
        and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver,
        rust: coloured signs. Limits of the diaphane. But he adds: in bodies.
        Then he was aware of them bodies before of them coloured. How? By
        knocking his sconce against them, sure. Go easy. Bald he was and a
        millionaire,{" "}
        <span className={classes.italicize}>maestro di color che sanno.</span>{" "}
        Limit of the diaphane in. Why in? Diaphane, adiaphane. If you can put
        your five fingers through it, it is a gate, if not a door. Shut your
        eyes and see.
      </Typography>

      <Typography paragraph>
        Stephen closed his eyes to hear his boots crush crackling wrack and
        shells. You are walking through it howsomever. I am, a stride at a time.
        A very short space of time through very short times of space. Five, six:
        the <span className={classes.italicize}>nacheinander.</span> Exactly:
        and that is the ineluctable modality of the audible. Open your eyes. No.
        Jesus! If I fell over a cliff that beetles o'er his base, fell through
        the <span className={classes.italicize}>nebeneinander</span>{" "}
        ineluctably! I am getting on nicely in the dark. My ash sword hangs at
        my side. Tap with it: they do. My two feet in his boots are at the ends
        of his legs, <span className={classes.italicize}>nebeneinander.</span>{" "}
        Sounds solid: made by the mallet of{" "}
        <span className={classes.italicize}>Los Demiurgos.</span> Am I walking
        into eternity along Sandymount strand? Crush, crack, crick, crick. Wild
        sea money. Dominie Deasy kens them a'.
      </Typography>
      <Typography paragraph>
        <span className={classes.quote}>Won't you come to Sandymount,</span>{" "}
        <br />
        <span className={classes.quote}>Madeline the mare?</span>
      </Typography>
      <Typography paragraph>
        Rhythm begins, you see. I hear. A catalectic tetrameter of iambs
        marching. No, agallop:{" "}
        <span className={classes.italicize}>deline the mare.</span>
      </Typography>
      <Typography paragraph>
        Open your eyes now. I will. One moment. Has all vanished since? If I
        open and am for ever in the black adiaphane.{" "}
        <span className={classes.italicize}>Basta!</span> I will see if I can
        see.
      </Typography>
      <Typography paragraph>
        See now. There all the time without you: and ever shall be, world
        without end.
      </Typography>
      <Typography paragraph>
        They came down the steps from Leahy's terrace prudently,{" "}
        <span className={classes.italicize}>Frauenzimmer:</span> and down the
        shelving shore flabbily, their splayed feet sinking in the silted sand.
        Like me, like Algy, coming down to our mighty mother. Number one swung
        lourdily her midwife's bag, the other's gamp poked in the beach. From
        the liberties, out for the day. Mrs Florence MacCabe, relict of the late
        Patk MacCabe, deeply lamented, of Bride Street. One of her sisterhood
        lugged me squealing into life. Creation from nothing. What has she in
        the bag? A misbirth with a trailing navelcord, hushed in ruddy wool. The
        cords of all link back, strandentwining cable of all flesh. That is why
        mystic monks. Will you be as gods? Gaze in your omphalos. Hello! Kinch
        here. Put me on to Edenville. Aleph, alpha: nought, nought, one.
      </Typography>
      <Box display={{ xs: "none", xl: "block" }}>
        <Typography paragraph>
          Spouse and helpmate of Adam Kadmon: Heva, naked Eve. She had no navel.
          Gaze. Belly without blemish, bulging big, a buckler of taut vellum,
          no, whiteheaped corn, orient and immortal, standing from everlasting
          to everlasting. Womb of sin.
        </Typography>
        <Typography paragraph>
          Wombed in sin darkness I was too, made not begotten. By them, the man
          with my voice and my eyes and a ghostwoman with ashes on her breath.
          They clasped and sundered, did the coupler's will. From before the
          ages He willed me and now may not will me away or ever. A lex eterna
          stays about Him. Is that then the divine substance wherein Father and
          Son are consubstantial? Where is poor dear Arius to try conclusions?
          Warring his life long on the contransmagnificandjewbangtantiality.
          Illstarred heresiarch. In a Greek watercloset he breathed his last:
          euthanasia. With beaded mitre and with crozier, stalled upon his
          throne, widower of a widowed see, with upstiffed omophorion, with
          clotted hinderparts.
        </Typography>
        <Typography paragraph>
          Airs romped round him, nipping and eager airs. They are coming, waves.
          The whitemaned seahorses, champing, brightwindbridled, the steeds of
          Mananaan.
        </Typography>
        <Typography paragraph>
          I mustn't forget his letter for the press. And after? The Ship, half
          twelve. By the way go easy with that money like a good young imbecile.
          Yes, I must.
        </Typography>
        <Typography paragraph>
          His pace slackened. Here. Am I going to aunt Sara's or not? My
          consubstantial father's voice. Did you see anything of your artist
          brother 38Stephen lately? No? Sure he's not down in Strasburg terrace
          with his aunt Sally? Couldn't he fly a bit higher than that, eh? And
          and and and tell us, Stephen, how is uncle Si? O weeping God, the
          things I married into! De boys up in de hayloft. The drunken little
          costdrawer and his brother, the cornet player. Highly respectable
          gondoliers! And skeweyed Walter sirring his father, no less! Sir. Yes,
          sir. No, sir. Jesus wept: and no wonder, by Christ!
        </Typography>
        <Typography paragraph>
          I pull the wheezy bell of their shuttered cottage: and wait. They take
          me for a dun, peer out from a coign of vantage.
        </Typography>
      </Box>
    </>
  );
}

export default PlaceholderText;
