import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function CircuitBoardDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a complex SVG animation with a welcome message. I use GSAP's{" "}
        <Link
          href="https://greensock.com/drawsvg/"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          DrawSVG
        </Link>{" "}
        plugin to draw the paths of the circuits, which all lead to the center,
        where I reveal a screen, then use a cursor and typing effect to show the
        message.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I started with a{" "}
        <Link
          href="https://freesvg.org/1538174404"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          circuit board image
        </Link>
        , then used a center line trace tool from{" "}
        <Link
          href="https://online.rapidresizer.com/tracer.php"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Rapid Resizer
        </Link>{" "}
        to create paths for DrawSVG to use. I cleaned up the image, smoothed out
        and completed paths that the tracing algorithm missed, and made all the
        paths run from edges to the "cpu" and "screen" in the center.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A great benefit of animating inline SVG is that while there are hundreds
        of animated elements, the SVG itself is only 176 KB.
      </Typography>
    </DemoDescription>
  );
}

export default CircuitBoardDescription;
