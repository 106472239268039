import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function OFCDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        For this welcome animation (Order From Chaos), I cut the paths of the
        SVG letters into pieces with jagged edges. I styled the letter pieces to
        look like the black and white paintings of Abstract Expressionist{" "}
        <Link
          href="https://en.wikipedia.org/wiki/Franz_Kline"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Franz Kline
        </Link>
        .
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        The pieces fly in to form the word "welcome", then stretch out one more
        time before I replace the pieces with a single path of the word, so
        there's no visible seams where they meet.
      </Typography>
    </DemoDescription>
  );
}

export default OFCDescription;
