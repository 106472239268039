import React, { useEffect } from "react";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap/gsap-core";
import ScrollTop from "../../ScrollTop";
import AnimatedBackgroundRothko from "./AnimatedBackgroundRothko";
import PlaceholderText from "../../../../PlaceholderText";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflowX: "hidden",
    minHeight:
      theme.palette.animations.animatedScrollingBackground.contentMinHeight,
    visibility: "hidden",
  },
  contentContainer: {
    position: "relative",
    zIndex: "100",
    marginTop: "35px",
  },
  title: {
    paddingTop: "50px",
    paddingBottom: "50px",
    position: "relative",
    zIndex: 10,
    background: theme.palette.animations.rothko.header,
  },
  buttonGroup: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      borderLeft: "transparent !important",
    },
  },
  italicize: {
    fontStyle: "italic",
  },
  quote: {
    marginLeft: theme.spacing(2),
    fontStyle: "italic",
  },
}));

function ContentWithAnimatedBackground(props) {
  const [colorScheme, setColorScheme] = React.useState("seventeen");
  const [init, setInit] = React.useState(true);
  const buttonGroupRef = React.useRef();
  const rootRef = React.useRef();

  const handleColorScheme = (event, newColorScheme) => {
    if (newColorScheme !== null) {
      setColorScheme(newColorScheme);
      const content = rootRef.current;
      const buttons = buttonGroupRef.current.querySelectorAll("button");
      let activeButton = null;
      buttons.forEach((button) => {
        if (button.value === newColorScheme) {
          activeButton = button;
        }
      });
      const duration = 0.1;
      const color = newColorScheme === "untitled" ? "#111" : "#fafafa";
      const buttonColor =
        newColorScheme === "untitled"
          ? "rgba(10,10,10,0.38)"
          : "rgba(255,255,255,0.38)";
      const buttonBorderColor =
        newColorScheme === "untitled"
          ? "rgba(10,10,10,0.12)"
          : "rgba(255,255,255,0.12)";
      const activeButtonColor =
        newColorScheme === "untitled"
          ? "rgba(10,10,10,0.95)"
          : "rgba(255,255,255,0.95)";
      const activeButtonBackgroundColor =
        newColorScheme === "untitled"
          ? "rgba(255,255,255,0.25)"
          : "rgba(255,255,255,0.12)";
      const mainTL = gsap.timeline();
      mainTL.to(content, { color: color, duration: duration }, 0);
      mainTL.to(
        buttons,
        {
          color: buttonColor,
          borderColor: buttonBorderColor,
          backgroundColor: "transparent",
          duration: duration,
        },
        0
      );
      if (activeButton !== null) {
        mainTL.to(
          activeButton,
          {
            color: activeButtonColor,
            backgroundColor: activeButtonBackgroundColor,
            duration: duration,
          },
          0
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.to(rootRef.current, { autoAlpha: 1, duration: 0.3 });
    handleColorScheme(false, "seventeen");
    setInit(false);
  }, []);

  const classes = useStyles(props);

  return (
    <div ref={rootRef} className={classes.root}>
      <ScrollTop />
      <AnimatedBackgroundRothko colorScheme={colorScheme} init={init} />
      <div className={classes.title}>
        <Typography variant="h3" align="center">
          Animated Background
        </Typography>
      </div>
      {/* <Divider /> */}
      <Grid className={classes.contentContainer} container justify="center">
        <Grid item xs={12} md={8} lg={6}>
          <Container>
            <Typography variant="h5" align="left" gutterBottom>
              Background animation: elements change their size when the user
              scrolls, but always take up 100% of viewport.
            </Typography>
            <Typography align="left">
              Based on{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.nga.gov/features/mark-rothko.html"
                color="inherit"
              >
                Mark Rothko's
              </Link>{" "}
              color field paintings. Colors were sampled from the following
              works:{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://arthur.io/art/mark-rothko/no-17"
                color="inherit"
                className={classes.italicize}
              >
                No. 17
              </Link>
              ,{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://arthur.io/art/mark-rothko/no-61-rust-and-blue"
                color="inherit"
                className={classes.italicize}
              >
                No. 61
              </Link>
              , and{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="https://www.moma.org/collection/works/37042"
                color="inherit"
                className={classes.italicize}
              >
                Untitled (1968)
              </Link>
              . Click a button below to change the color scheme.
            </Typography>
            <div ref={buttonGroupRef} className={classes.buttonGroup}>
              <ToggleButtonGroup
                color="inherit"
                value={colorScheme}
                exclusive
                onChange={handleColorScheme}
                aria-label="change color scheme to match paintings by Mark Rothko"
              >
                <ToggleButton
                  value="seventeen"
                  aria-label="change color scheme to match the painting: '17'"
                >
                  No.17
                </ToggleButton>
                <ToggleButton
                  value="sixtyOne"
                  aria-label="change color scheme to match the painting: '61'"
                >
                  No.61
                </ToggleButton>
                <ToggleButton
                  value="untitled"
                  aria-label="change color scheme to match the painting: 'Untitled'"
                >
                  Untitled
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <Typography align="left" gutterBottom>
              This is a great way to add some flair to a blog or long blocks of
              text, without constant movement to distract while the user is
              reading. Paragraphs could be given a transparent background color
              if the effect makes the text difficult to read.
            </Typography>
            <Typography variant="h5" align="left" gutterBottom>
              Here's some text from{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="http://m.joyceproject.com/chapters/proteus.html"
                color="inherit"
                className={classes.italicize}
              >
                Ulysses
              </Link>{" "}
              to fill out the page:
            </Typography>
            <PlaceholderText />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContentWithAnimatedBackground;
