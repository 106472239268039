import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function ThreeTwoOneDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This logo animation uses GSAP's{" "}
        <Link
          href="https://greensock.com/morphsvg/"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          MorphSVG
        </Link>{" "}
        plugin to convert SVG circles into text.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        The circles fall from the top of the viewport, then squish and morph
        into letters on impact.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I fine-tuned the morph origin indices and used rotational morphs to make
        the transitions as smooth as possible. I recommend scrubbing through
        this effect slowly or adjusting the playback speed from the onscreen
        controls to get a close look at the MorphSVG magic.
      </Typography>
    </DemoDescription>
  );
}

export default ThreeTwoOneDescription;
