import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function DescriptionAccordion(props) {
  useEffect(() => {}, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "98vw",
      },
    },
    heading: {
      [theme.breakpoints.only("xs")]: {
        fontSize: theme.typography.pxToRem(19),
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: theme.typography.pxToRem(24),
      },
      fontSize: theme.typography.pxToRem(28),
    },
    content: {
      fontSize: theme.typography.pxToRem(15),
      textAlign: "left",
    },
  }));

  const Accordion = withStyles({
    root: {
      border: "none",
      boxShadow: "none",
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles((theme) => ({
    content: {},
    expanded: {},
    expandIcon: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
  }))(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      marginRight: theme.spacing(5),
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(10),
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
  }))(MuiAccordionDetails);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {props.heading ? props.heading : "description heading"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.description ? (
            props.description
          ) : (
            <Typography className={classes.content}>
              nothing to be done. i'm beginning to come round to that point of
              view.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DescriptionAccordion;
