import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Typography,
  Drawer,
} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import SiteNavigation from "./SiteNavigation";
import SiteMap from "./SiteMap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.appBar,
  },
  siteTitle: {},
  navbarDisplayFlex: {
    display: `flex`,
    alignItems: "center",
    justifyContent: `space-between`,
  },
  iconButton: {
    color: theme.palette.iconColor,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  navDrawerPaper: {
    backgroundColor: theme.palette.background.navigationDrawer,
  },
  navigationHeading: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  placeholderNavBar: {
    visibility: "hidden",
  },
}));

function NavBar(props) {
  const location = useLocation();
  const pages = SiteMap();

  let title = "";
  pages.forEach((page) => {
    if (page.path === location.pathname && page.demoTitle) {
      title = page.demoTitle;
    }
  });

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Container maxWidth="xl" className={classes.navbarDisplayFlex}>
            <IconButton
              className={classes.iconButton}
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h6"
              align="center"
              className={classes.siteTitle}
            >
              {title}
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={props.themeToggle}
              ari-label="brightness-toggle-button"
            >
              {props.brightnessIcon}
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
      {/* this hack is one of the actual suggestions for a sticky header that won't overlay the page content */}
      <Toolbar className={classes.placeholderNavBar}></Toolbar>
      <Drawer
        classes={{ paper: classes.navDrawerPaper }}
        open={open}
        onClose={handleDrawerClose}
      >
        <SiteNavigation
          closeDrawer={handleDrawerClose}
          showContent={props.showContent}
          hideContent={props.hideContent}
        />
      </Drawer>
    </div>
  );
}

export default NavBar;
