import React from "react";
import { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import CustomEase from "gsap/CustomEase";
import CustomBounce from "gsap/CustomBounce";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomBounce);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100% !important",
    maxWidth: "100% !important",
    pointerEvents: "none",
  },
  text: {
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "26px",
    },
  },
  underline: {
    height: "2px",
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
    backgroundColor: theme.palette.text.secondary,
    visibility: "hidden",
  },
}));

function TheEnd() {
  const containerRef = React.useRef();
  const textContainerRef = React.useRef();
  const underlineRef = React.useRef();

  useEffect(() => {
    // elements
    const containerElement = containerRef.current;
    const textElement = textContainerRef.current;
    const underlineElement = underlineRef.current;

    // animate
    revealText(textElement, containerElement);
    animateText(textElement, underlineElement, containerElement);
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.root}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} align="center">
        <Typography
          ref={textContainerRef}
          className={classes.text}
          variant="h4"
        >
          what will we create for you?
        </Typography>
        <div ref={underlineRef} className={classes.underline}></div>
      </Grid>
    </Grid>
  );
}

export default TheEnd;

function revealText(textElement, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.25;

  mainTL.from(textElement, { autoAlpha: 0 });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top 25%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });
}

function animateText(textElement, underline, container) {
  const scrollDuration = window.innerHeight;
  const staggerAmount = 0.1;
  const text = new SplitText(textElement, { type: "words, chars" });
  let textWidth = 47;
  text.words.forEach((word) => {
    textWidth += word.offsetWidth;
  });
  CustomBounce.create("myBounce", {
    strength: 0.4,
    squash: 1,
    squashID: "myBounce-squash",
  });
  const jumpTL = gsap.timeline();
  // jump
  jumpTL.to(
    text.chars,
    { y: "-=30", duration: 0.5, stagger: { each: staggerAmount } },
    0
  );
  jumpTL.to(text.chars, {
    y: 0,
    duration: 0.5,
    stagger: { each: staggerAmount },
    ease: "myBounce",
  });
  jumpTL.to(
    text.chars,
    {
      duration: 0.5,
      stagger: { each: staggerAmount },
      scaleY: 0.8,
      scaleX: 1.2,
      ease: "myBounce-squash",
      transformOrigin: "bottom center",
    },
    "<"
  );
  // spin
  const spinTL = gsap.timeline();
  spinTL.to(text.chars, {
    duration: jumpTL.duration() - 1.8,
    rotationY: 1440,
    transformOrigin: "50% 50%",
    stagger: staggerAmount,
  });

  const textTL = gsap.timeline();
  textTL.add(jumpTL, 0);
  textTL.add(spinTL, 0);

  const underlineTL = gsap.timeline();
  underlineTL.set(underline, {
    width: textWidth + "px",
    y: "+=5",
    autoAlpha: 1,
  });
  underlineTL.from(underline, {
    scaleX: 0,
    duration: jumpTL.duration(),
    ease: "none",
  });

  const mainTL = gsap.timeline();
  mainTL.add(textTL, 0);
  mainTL.add(underlineTL, 0);

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    pin: container,
    anticipatePin: 1,
    start: "top 1%",
    end: "+=" + scrollDuration,
    scrub: 1.66,
    toggleActions: "play reset play reset",
  });
}
