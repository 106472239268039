/*
  Makes and returns a theme
  Single boolean argument for dark mode allows 2 themes returned:
    makeTheme(true)
    makeTheme(false)

*/

import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import gsap from "gsap";

// colors
import red from "@material-ui/core/colors/red";
// import BLUE from "@material-ui/core/colors/blue";
// import BLUEGREY from "@material-ui/core/colors/blueGrey";
// import INDIGO from "@material-ui/core/colors/indigo";
import LIGHTBLUE from "@material-ui/core/colors/lightBlue";
import LIGHTGREEN from "@material-ui/core/colors/lightGreen";

function MakeTheme(darkMode) {
  // custom colors for active page navigation link
  const paleBlue = LIGHTBLUE[800];
  const screenGreen = LIGHTGREEN["A400"];
  const blockColors = makeBlockColors();
  const LCDGreen = "#009e00";

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      type: darkMode ? "dark" : "light",
      background: {
        default: darkMode ? "#212121" : "#fafafa",
        paper: darkMode ? "#212121" : "#fafafa",
        appBar: darkMode ? "#333" : "#333",
        modalAppBar: darkMode ? "#222" : "#222",
        header: darkMode ? "#222" : "#f0f0f0",
        // subHeader: darkMode ? BLUEGREY[900] : INDIGO[100],
        subHeader: darkMode ? "#2e2e2e" : "#e3e3e3",
        footer: darkMode ? "#2e2e2e" : "#e3e3e3",
        navigationDrawer: darkMode ? "#333" : "#e5e5e5",
      },
      animations: {
        siteWelcome: {
          // background: darkMode ? "#fefefe" : "#222", // used by Zoom-In
          backgroundStart: darkMode ? "#212121" : "#fafafa",
          textStart: darkMode ? "#fefefe" : "#222",
          brightGreen: "#00d100",
          green: LCDGreen,
          blue: "#0277bd",
        },
        animatedHeader: {
          container: darkMode ? "#006b00" : "#b2ebf2",
          // container: darkMode ? LCDGreen : "#b2ebf2",
          // background: darkMode ? "#479d00" : "#bbdefb",
          background: darkMode ? LCDGreen : "#b2f2e9",
          elements: darkMode ? "#212121" : "#f5f5f5",
        },
        welcomeBoxes: {
          boxes: darkMode ? "#f0f0f0" : "#333",
        },
        welcomeOFC: {
          fill: darkMode ? "#f0f0f0" : "#333",
        },
        threeTwoOne: {
          three: darkMode ? "#3d5afe" : "#3f51b5",
          two: darkMode ? "#2979ff" : "#2196f3",
          one: darkMode ? "#00b0ff" : "#03a9f4",
        },
        topBottom: {
          topLineStart: darkMode ? "#eee" : "#333",
          bottomBackgroundStart: darkMode ? "#aaa" : "#666",
        },
        upsyDaisy: {
          upsyBox: darkMode ? "#666" : "#aaa",
          daisyBox: darkMode ? "#444" : "#444",
        },
        pioneer10: {
          SVGBackground: darkMode ? "#111" : "#fafafa",
          overlays: darkMode ? "#fafafa" : "#111",
          nasaBlue: darkMode ? "#5d7fb9" : "#15418c",
          reactBlue: "#61dbfb",
          gsapGreen: "#57a818",
          diagramLeaderLinesAndPoints: darkMode ? red[400] : red[500],
          scrollDownArrows: darkMode ? "#fafafa" : "#111",
          sectionTitleBackground: darkMode
            ? "rgba(17,17,17,0.93)"
            : "rgba(250,250,250,0.93)",
        },
        elements: {
          card: darkMode ? "rgba(56,56,56, 0.85)" : "#ddd",
          grid: {
            border: darkMode ? "#333" : "#d7d7d7",
          },
          link: {
            color: darkMode ? "#90caf9" : "#1565c0",
          },
          scrollDownArrows: darkMode ? "#fafafa" : "#111",
          scrollTop: {
            backgroundColor: darkMode ? "#555" : "#b7b7b7",
            hoverColor: darkMode ? "#444" : "#a7a7a7",
            textColor: darkMode ? "#d7d7d7" : "#444",
          },
          pinnedContentCaptionBackground: darkMode
            ? "rgba(22,22,22,0.85)"
            : "rgba(200,200,200,0.85)",
        },
        animatedScrollingBackground: {
          contentMinHeight: "125vh",
        },
        circles: {
          header: darkMode ? "rgba(13,7,38,0.66)" : "rgba(250,250,250,0.66)",
          circleColor1: darkMode ? "#4527a0" : "#fbc02d",
          circleColor2: darkMode ? "#3949ab" : "#ffeb3b",
          circleColor3: darkMode ? "#283593" : "#ffe082",
          circleGradient: darkMode
            ? "radial-gradient(circle, rgba(103,58,183,1) 0%, rgba(103,58,183,1) 38%, rgba(69,78,181,0.9037990196078431) 85%, rgba(67,79,181,0.5452556022408963) 96%, rgba(63,81,181,0.1110819327731093) 100%)"
            : "radial-gradient(circle, rgba(253,216,53,1) 0%, rgba(253,216,53,1) 38%, rgba(255,255,0,1) 85%, rgba(255,255,0,1) 96%, rgba(255,255,0,1) 100%)",
        },
        rothko: {
          header: darkMode ? "transparent" : "transparent",
        },
        drop: {
          blockColor1: darkMode ? blockColors.dark[0] : blockColors.light[0],
          blockColor2: darkMode ? blockColors.dark[1] : blockColors.light[1],
          blockColor3: darkMode ? blockColors.dark[2] : blockColors.light[2],
          blockColor4: darkMode ? blockColors.dark[3] : blockColors.light[3],
          blockColor5: darkMode ? blockColors.dark[4] : blockColors.light[4],
          blockColor6: darkMode ? blockColors.dark[5] : blockColors.light[5],
          blockColor7: darkMode ? blockColors.dark[6] : blockColors.light[6],
          blockColor8: darkMode ? blockColors.dark[7] : blockColors.light[7],
          header: darkMode ? "rgba(50,50,50,0.5)" : "rgba(200,200,200,0.5)",
        },
      },
      iconColor: "#fafafa",
      dividerColor: darkMode
        ? "rgba(100,100,100,0.25)"
        : "rgba(100,100,100,0.15)",
      navigation: {
        activePage: darkMode ? screenGreen : paleBlue,
      },
      mockup: {
        background: darkMode ? "#c5c5c5" : "#c5c5c5",
        content: darkMode ? "#b2b2b2" : "#b2b2b2",
        text: {
          headline: "#666",
          subHeadline: "#666",
          body: "#666",
        },
      },
    },
    sizes: {
      headerHeight: "30vh",
      bodyMinHeight: "70vh",
      footerHeight: "100px",
      timelineControlsContainerWidth: {
        xSmallUp: "90%",
        medium: "750px",
        large: "900px",
        xLarge: "1200px",
      },
      fullscreenAnimationDemoWidth: {
        xSmallUp: "90%",
        medium: "750px",
        large: "900px",
        xLarge: "1200px",
      },
    },
    mixins: {
      toolbar: {
        "@media (min-width:0px)": {
          minHeight: 56,
        },
      },
    },
  });

  return theme;
}

// Utilities
function makeBlockColors() {
  const blockColorsDarkStart = "rgb(156,39,176)";
  const blockColorsDarkEnd = "rgb(63,81,181)";
  const interpBlockColorsDark = gsap.utils.interpolate(
    blockColorsDarkStart,
    blockColorsDarkEnd
  );
  const blockColorsLightStart = "#ffee58";
  const blockColorsLightEnd = "#ffb74d";
  const interpBlockColorsLight = gsap.utils.interpolate(
    blockColorsLightStart,
    blockColorsLightEnd
  );

  const colors = {
    dark: [
      interpBlockColorsDark(0.125),
      interpBlockColorsDark(0.25),
      interpBlockColorsDark(0.375),
      interpBlockColorsDark(0.5),
      interpBlockColorsDark(0.625),
      interpBlockColorsDark(0.75),
      interpBlockColorsDark(0.875),
      interpBlockColorsDark(1),
    ],
    light: [
      interpBlockColorsLight(0.125),
      interpBlockColorsLight(0.25),
      interpBlockColorsLight(0.375),
      interpBlockColorsLight(0.5),
      interpBlockColorsLight(0.625),
      interpBlockColorsLight(0.75),
      interpBlockColorsLight(0.875),
      interpBlockColorsLight(1),
    ],
  };

  return colors;
}

export default MakeTheme;
