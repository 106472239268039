import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1em 0",
    backgroundColor: theme.palette.background.footer,
    position: "absolute",
    zIndex: 99,
  },
  jlhLink: {
    color: theme.palette.text.primary,
  },
}));

function Footer(props) {
  const yearRef = React.useRef();

  useEffect(() => {
    const year = yearRef.current;
    var date = new Date();
    year.innerHTML = date.getFullYear();
  }, []);

  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography align="center">
          JLH - <span ref={yearRef}></span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
