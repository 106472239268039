import React from "react";
import { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    textAlign: "center",
  },
  title: {
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  card: {
    display: "block",
    width: "100%",
    backgroundColor: theme.palette.animations.elements.card,
  },
  ctaText: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px",
    },
  },
  img: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    marginLeft: "auto",
  },
}));

function ImageCTA() {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const imgRef = React.useRef();
  const cardRef = React.useRef();
  const cardActionsRef = React.useRef();
  const ctaTextRef = React.useRef();
  const buttonRef = React.useRef();

  useEffect(() => {
    // elements
    const containerElement = containerRef.current;
    const title = titleRef.current;
    const cardElement = cardRef.current;
    const ctaText = ctaTextRef.current;
    const ctaButton = buttonRef.current;
    const image = imgRef.current;

    // animate
    const mainTL = gsap.timeline();
    mainTL.add(revealElements(title, cardElement, containerElement));
    mainTL.add(parallaxElement(cardElement, containerElement));
    mainTL.add("elements and img");
    mainTL.add(
      revealCTAElements(ctaText, ctaButton, containerElement),
      "elements and img"
    );
    mainTL.add(imgEffect(image, containerElement), "elements and img");
  }, []);

  const classes = useStyles();

  return (
    <Container ref={containerRef} className={classes.root}>
      <Grid container spacing={2} justify="center">
        <Typography ref={titleRef} className={classes.title} variant="h2">
          image with cta
        </Typography>
        <Grid item xs={12} md={8} lg={7} align="center">
          <Card ref={cardRef} className={classes.card} elevation={0}>
            <div ref={imgRef}>
              <CardMedia
                className={classes.img}
                image="https://picsum.photos/id/1042/600/800"
                title="person looks into starry sky"
              />
            </div>
            <CardActions
              disableSpacing
              ref={cardActionsRef}
              className={classes.cardActions}
            >
              <Typography
                ref={ctaTextRef}
                className={classes.ctaText}
                variant="h6"
              >
                eyes up. join us today.
              </Typography>
              <Button
                ref={buttonRef}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                engage
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ImageCTA;

function imgEffect(img, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.3;
  const image = img.children[0];
  mainTL.set(img, { position: "relative", overflow: "hidden" });
  mainTL.to(image, { scale: 1.3, transformOrigin: "50% 100%" });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top 45%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });

  return mainTL;
}

function revealElements(title, card, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.2;
  const text = new SplitText(title, { type: "words" });
  mainTL.from(text.words, { autoAlpha: 0, stagger: 0.2 });
  mainTL.from(card, { autoAlpha: 0 });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top center",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });

  return mainTL;
}

function parallaxElement(element, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.4;
  mainTL.from(element, { y: "+=" + scrollDuration * 0.5 });
  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top center",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });

  return mainTL;
}

function revealCTAElements(textElement, button, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.35;

  const text = new SplitText(textElement, { type: "words" });
  mainTL.from(text.words, { autoAlpha: 0, stagger: 0.2 });
  mainTL.from(button, {
    autoAlpha: 0,
    x: "+=10",
    ease: "back.out(0.4)",
  });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top 35%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });

  return mainTL;
}
