import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import gsap from "gsap";
import GSDevTools from "gsap/GSDevTools";
import Header from "./Header";
import ScrollDownArrows from "./ScrollDownArrows";
import ScrollingGridAnimations from "./ScrollingGridAnimations";
import IconRow from "./IconRow";
import PinnedContent from "./PinnedContent";
import ImageCTA from "./ImageCTA";
import WordCloud from "./WordCloud";
import TheEnd from "./TheEnd";
import ScrollTop from "../ScrollTop";

gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "hidden",
    visibility: "hidden",
  },
  head: {
    minHeight: "100vh",
  },
}));

function Elements(props) {
  const containerRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, 0);

    // ref elements
    const container = containerRef.current;

    // setup elements
    gsap.set(document.querySelector("body"), { overflowX: "hidden" });
    gsap.set(container, { autoAlpha: 1 });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root} ref={containerRef}>
      {/* head */}
      <Grid
        className={classes.head}
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item>
          <Header text="Scrolling Effects" />
        </Grid>
        <Grid item>
          <ScrollDownArrows />
        </Grid>
      </Grid>

      {/* body */}
      <ScrollingGridAnimations />
      <IconRow />
      <PinnedContent />
      <ImageCTA />
      <WordCloud />
      <TheEnd />
      <ScrollTop />
    </div>
  );
}

export default Elements;
