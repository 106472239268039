import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as MessageSVG } from "./img/welcome-optimized.svg";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import GSDevTools from "gsap/GSDevTools";
import FullscreenAnimationDemo from "../../FullscreenAnimationDemo";
import * as Utilities from "../../../AnimationUtilities";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  messageSVG: {
    width: "80%",
    [theme.breakpoints.only("xs")]: {
      width: "92%",
    },
    maxWidth: "800px",
    height: "auto",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    "& *": {
      stroke: theme.palette.animations.siteWelcome.textStart,
    },
    "& #background": {
      fill: "none",
      stroke: "none",
    },
    "& #textCopy path": {
      visibility: "hidden",
      stroke:
        theme.palette.type === "dark"
          ? theme.palette.animations.siteWelcome.brightGreen
          : theme.palette.animations.siteWelcome.blue,
    },
  },
}));

function LineDrawing(props) {
  const messageSVGRef = React.useRef();
  const timelineControlsRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const messageSVG = messageSVGRef.current;
    const timelineControlsElement = timelineControlsRef.current;

    // SETUP
    Utilities.centerElement(messageSVG, 48);

    // animation elements
    const letterW = messageSVG.querySelectorAll("#letterW path");
    const letterEOne = messageSVG.querySelectorAll("#letterEOne path");
    const letterL = messageSVG.querySelectorAll("#letterL path");
    const letterC = messageSVG.querySelector("#letterC");
    const letterO = messageSVG.querySelector("#letterO");
    const letterM = messageSVG.querySelectorAll("#letterM path");
    const letterETwo = messageSVG.querySelectorAll("#letterETwo path");
    const wholeLetters = messageSVG.querySelectorAll("#textCopy path");

    // letter start times
    const letterStartTimes = {
      w: "draw+=" + 0.4,
      eOne: "draw+=" + 0.2,
      l: "draw+=" + 0,
      c: "draw+=" + 0.1,
      o: "draw+=" + 0.7,
      m: "draw+=" + 0.2,
      eTwo: "draw+=" + 0.3,
    };

    // ANIMATE
    const mainTL = Utilities.createFullscreenDemoTimeline(
      timelineControlsElement
    );
    mainTL.to(messageSVG, { autoAlpha: 1, duration: 0.2 }, 0.1);
    // draw letters
    mainTL.add("draw", "+=0.3");
    mainTL.add(animateLetterW(letterW, wholeLetters[0]), letterStartTimes.w);
    mainTL.add(
      animateLetterEOne(letterEOne, wholeLetters[1]),
      letterStartTimes.eOne
    );
    mainTL.add(animateLetterL(letterL, wholeLetters[2]), letterStartTimes.l);
    mainTL.add(animateLetterC(letterC, wholeLetters[3]), letterStartTimes.c);
    mainTL.add(animateLetterO(letterO, wholeLetters[4]), letterStartTimes.o);
    mainTL.add(animateLetterM(letterM, wholeLetters[5]), letterStartTimes.m);
    mainTL.add(
      animateLetterETwo(letterETwo, wholeLetters[6]),
      letterStartTimes.eTwo
    );

    // CONTROLS
    GSDevTools.create(
      Utilities.createDevTools(mainTL, timelineControlsElement)
    );
  }, []);

  const classes = useStyles();

  return (
    <FullscreenAnimationDemo allRefs={{ timelineControlsRef }}>
      <MessageSVG ref={messageSVGRef} className={classes.messageSVG} />
    </FullscreenAnimationDemo>
  );
}

// animation functions
const DrawSVGFirstTweenDuration = 0.02;
const WholeLetterFadeInDuration = 0.5;
function animateLetterW(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);
  tl.add("part 1", 0);
  tl.add("part 2", 0.3);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterEOne(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.5);
  tl.add("part 3", 0.6);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 2;
  const part3Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 96%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=" + firstTweenDuration
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterL(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.35);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 1.5;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterC(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);

  const duration = 1;

  tl.fromTo(
    letterDrawing,
    { drawSVG: "0% 0%" },
    {
      drawSVG: "0% 0.5%",
      duration: DrawSVGFirstTweenDuration,
      ease: "Power2.inOut",
    },
    "start"
  );

  tl.to(letterDrawing, {
    drawSVG: "0% 100%",
    duration: duration,
    ease: "Power2.inOut",
  });

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterO(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);

  const duration = 1.66;

  tl.fromTo(
    letterDrawing,
    { drawSVG: "0% 0%" },
    {
      drawSVG: "0% 0.5%",
      duration: DrawSVGFirstTweenDuration,
      ease: "Power2.inOut",
    },
    "start"
  );

  tl.to(letterDrawing, {
    drawSVG: "0% 100%",
    duration: duration,
    ease: "Power2.inOut",
  });

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterM(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0.4);
  tl.add("part 2", 0.3);
  tl.add("part 3", 0.3);
  tl.add("part 4", 0);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;
  const part3Duration = 0.6;
  const part4Duration = 1;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=0.1"
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=0.1"
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=0.1"
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 4"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part4Duration, ease: "Power2.inOut" },
    "part 4+=0.1"
  );

  // end
  tl.add("the end");
  tl.to(
    letterWhole,
    { autoAlpha: 1, duration: WholeLetterFadeInDuration },
    "the end"
  );
  tl.to(
    letterDrawing,
    { autoAlpha: 0, duration: WholeLetterFadeInDuration },
    "the end+=0.01"
  );

  return tl;
}

function animateLetterETwo(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.15);
  tl.add("part 3", 0.4);
  tl.add("part 4", 0.975);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;
  const part3Duration = 0.6;
  const part4Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=0.1"
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=0.1"
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=0.1"
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 4"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part4Duration, ease: "Power2.inOut" },
    "part 4+=0.1"
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

export default LineDrawing;
