import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function SunsideDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This logo animation starts with a dark blue background as sun rays cover
        the sky while the sun rises into view. When the sun makes it to the
        center of the screen, I scale the animation container to frame the image
        as a logo, with a simultaneous tween to desaturate all colors. Then I
        reveal the text, and keep the sun disk scale and ray rotation animations
        on infinite repeat.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A logo animation is a great way to introduce your brand. An effect like
        this could be used as a site intro and then be scaled down to fit into a
        header or banner.
      </Typography>
    </DemoDescription>
  );
}

export default SunsideDescription;
