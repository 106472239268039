import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { Grid } from "@material-ui/core";
import HeroImage from "../../../img/scrollers/pioneer-10/Pioneer_10_at_Jupiter-min.jpg";
import { ReactComponent as HeroTextOverlay } from "../../../img/scrollers/pioneer-10/hero-overlay-optimized.svg";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
    overflow: "hidden",
  },
  hero: {
    backgroundImage: `url(${HeroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  overlayText: {
    width: "100%",
    height: "auto",
    willChange: "transform",
    visibility: "hidden",
    "& #First, #mission-to, #Jupiter, #text-background": {
      willChange: "transform",
      position: "absolute",
    },
  },
}));

function Header(props) {
  const overlayRef = React.useRef();

  useEffect(() => {
    const overlayText = overlayRef.current;
    const textBackground = document.querySelector("#text-background");
    gsap.set(textBackground, { autoAlpha: 0.25 });

    const text = {
      first: document.querySelector("#First"),
      mission: document.querySelector("#mission-to"),
      jupiter: document.querySelector("#Jupiter"),
    };
    gsap.set("#First path, #mission-to path, #Jupiter path", {
      fill: red[500],
    });

    // timelines
    const tl = gsap.timeline();
    const firstTL = gsap.timeline();
    const missionTL = gsap.timeline();
    const jupiterTL = gsap.timeline();
    const backgroundTL = gsap.timeline();

    const delay = 0.3;

    tl.set(overlayText, { autoAlpha: 1 });
    tl.add(firstTL, 0 + delay);
    tl.add(missionTL, 0 + delay);
    tl.add(jupiterTL, 0.5 + delay);
    tl.add(backgroundTL, 1 + delay);

    // Animations
    // First
    firstTL.from(text.first, { autoAlpha: 0, xPercent: "-50", duration: 4 });

    // mission to
    missionTL.from(text.mission, {
      autoAlpha: 0,
      yPercent: "+150",
      duration: 4,
    });

    // Jupiter
    const jupiterDuration = 4;
    jupiterTL.from(text.jupiter, {
      yPercent: "-150",
      duration: jupiterDuration,
    });
    jupiterTL.from(
      text.jupiter,
      { autoAlpha: 0, duration: jupiterDuration },
      0.5
    );
  }, []);

  const classes = useStyles();

  return (
    <Grid className={classes.container} container justify="center">
      <Grid className={classes.hero} item xs={12} xl={10}>
        <HeroTextOverlay ref={overlayRef} className={classes.overlayText} />
      </Grid>
    </Grid>
  );
}

export default Header;
