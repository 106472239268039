import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function PioneerTenDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a complex scrolling infographic using GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/ScrollTrigger"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          ScrollTrigger
        </Link>{" "}
        plugin to animate multiple SVGs with many unique effects.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I used images and copy from NASA (
        <Link
          href="https://www.nasa.gov/centers/ames/missions/archive/pioneer.html"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          1
        </Link>
        ) (
        <Link
          href="https://web.archive.org/web/20041105100336/http://spaceprojects.arc.nasa.gov/Space_Projects/pioneer/PNhome.html"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          2
        </Link>
        ) to tell the story of the first spacecraft to travel through the
        asteroid belt, reach Jupiter, and fly beyond Neptune's orbit.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        This is a great example of an interactive infographic using GSAP's
        ScrollTrigger plugin to drive lots of custom animations. ScrollTrigger
        allows us to move seamlessly from one section to the next, all
        controlled by the user at their own pace.
      </Typography>
    </DemoDescription>
  );
}

export default PioneerTenDescription;
