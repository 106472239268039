import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function CraniumManDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a logo animation of a SVG head outline and gears that I animate
        as the spinning wheels of creative thought.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I started with a{" "}
        <Link
          href="https://www.flaticon.com/free-icon/thought_775497"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          SVG
        </Link>{" "}
        made by{" "}
        <Link
          href="https://www.flaticon.com/authors/smashicons"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Smashicons
        </Link>{" "}
        at{" "}
        <Link
          href="https://www.flaticon.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Flaticon
        </Link>
        {", "}then cut the frame, head, and gear outlines into paths with
        single, center line strokes, then draw the strokes with GSAP's{" "}
        <Link
          href="https://greensock.com/drawsvg/"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          DrawSVG
        </Link>{" "}
        plugin. I animate a pulsing dot with rays and debris shooting out from
        it to simulate a laser beam impact at the front end of the draw line,
        and trace the line twice: once as a "hot" line, the same color as the
        laser, then as a "cooled off" line.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I alined the gears to be plausibly interlocking, and have them try twice
        before spinning up into a smooth rotation.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        For the text, I used an engraving font to get single line strokes to
        draw, and pass a wave of color through the text to balance with the
        gears' spin.
      </Typography>
    </DemoDescription>
  );
}

export default CraniumManDescription;
