import React from "react";
import { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SVG } from "../../../img/scrollers/elements/gears.svg";
import { ReactComponent as USA_48 } from "../../../img/scrollers/elements/usa-48.svg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import * as Utilities from "./AnimationUtilities";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const USA_REGION_COLORS = {
  midWest: "#f44336",
  northEast: "#2196f3",
  south: "#4caf50",
  west: "#ffeb3b",
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    paddingTop: theme.spacing(4),
    textAlign: "center",
    width: "100% !important",
    maxWidth: "100% !important",
    pointerEvents: "none",
  },
  title: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
  },
  svg: {
    height: "60%",
    width: "auto",
  },
  captionContainer: {
    position: "relative",
  },
  caption: {
    color: "#26A6D1",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
  },
  captionMask: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    margin: "auto",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
  },
  partTwoContainer: {
    visibility: "hidden",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  partTwoContent: {
    position: "absolute",
    width: "100%",
  },
  partTwoText: {
    marginBottom: theme.spacing(5),
    position: "relative",
    zIndex: 100,
    padding: theme.spacing(1),
    borderRadius: "5px",
    backgroundColor:
      theme.palette.animations.elements.pinnedContentCaptionBackground,
  },
  map: {
    width: "94%",
    maxWidth: "1000px",
    height: "auto",
    zIndex: 0,
    "& .land": {
      fill: "none",
      stroke: theme.palette.text.primary,
    },
  },
}));

function PinnedContent() {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const partOneRef = React.useRef();
  const svgRef = React.useRef();
  const captionContainerRef = React.useRef();
  const captionRef = React.useRef();
  const captionMaskRef = React.useRef();
  const partTwoRef = React.useRef();
  const partTwoContentRef = React.useRef();
  const partTwoTextRef = React.useRef();
  const mapRef = React.useRef();

  // from Carl Schooff, via Creative Coding Club
  gsap.registerEffect({
    name: "clip",
    defaults: {
      duration: 1,
      ease: "power1.in",
      direction: "full",
    },
    extendTimeline: true,
    effect: function (targets, config) {
      let direction = {
        top: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        bottom: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        left: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        right: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
        full: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        midX: "polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)",
        midY: "polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%)",
        center: "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)",
      };

      if (window.getComputedStyle(targets[0]).clipPath === "none") {
        gsap.set(targets, { clipPath: direction["full"] });
      }

      let animation = gsap.timeline().to(targets, {
        clipPath: direction[config.direction],
        duration: config.duration,
        ease: config.ease,
      });

      return animation;
    },
  });

  useEffect(() => {
    const containerElement = containerRef.current;
    const title = titleRef.current;
    const partOneContainer = partOneRef.current;
    const svgElement = svgRef.current;
    const captionContainerElement = captionContainerRef.current;
    const captionElement = captionRef.current;
    const captionMaskElement = captionMaskRef.current;
    const partTwoContainer = partTwoRef.current;
    const partTwoContentElement = partTwoContentRef.current;
    const map = mapRef.current;

    // setup
    Utilities.centerElement(partTwoContainer);
    Utilities.centerElement(partTwoContentElement, 47);

    const mainTL = gsap.timeline();
    mainTL.add(
      revealElements(
        title,
        svgElement,
        captionContainerElement,
        containerElement
      )
    );
    mainTL.add(
      animateGears(
        containerElement,
        containerElement,
        svgElement.children,
        captionElement,
        captionMaskElement,
        partOneContainer
      )
    );
    mainTL.add(
      makePartTwo(
        containerElement,
        containerElement,
        svgElement.children,
        captionElement,
        captionMaskElement,
        partTwoContainer,
        map
      )
    );

    const scrollDuration = window.innerHeight * 4;

    ScrollTrigger.create({
      animation: mainTL,
      trigger: containerElement,
      pin: containerElement,
      anticipatePin: 1,
      start: "top 7%",
      end: "+=" + scrollDuration,
      scrub: 0.85,
      toggleActions: "play reset play reset",
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.root}
      container
      justify="center"
    >
      <Typography ref={titleRef} className={classes.title} variant="h2">
        pinned content
      </Typography>
      <Grid ref={partOneRef} item xs={12} sm={11} md={7} lg={6}>
        <SVG className={classes.svg} ref={svgRef} />
        <div ref={captionContainerRef} className={classes.captionContainer}>
          <Typography
            ref={captionRef}
            className={classes.caption}
            variant="h2"
            align="center"
          >
            working on it
          </Typography>
          <Typography
            ref={captionMaskRef}
            className={classes.captionMask}
            variant="h2"
            align="center"
          >
            working on it
          </Typography>
        </div>
      </Grid>

      {/* Part 2 */}
      <Grid
        ref={partTwoRef}
        className={classes.partTwoContainer}
        item
        xs={11}
        md={7}
        lg={6}
      >
        <div ref={partTwoContentRef} className={classes.partTwoContent}>
          <div ref={partTwoTextRef} className={classes.partTwoText}>
            <Typography align="center">
              Pinned content stays in place while the user scrolls. This is a
              great way to step through a sequence of animations.
            </Typography>
          </div>
          <USA_48 ref={mapRef} className={classes.map} />
        </div>
      </Grid>
    </Grid>
  );
}

export default PinnedContent;

function revealElements(title, svg, caption, container) {
  const mainTL = gsap.timeline();
  const text = new SplitText(title, { type: "words" });
  mainTL.from(text.words, { autoAlpha: 0, y: "+=15", stagger: 0.2 });
  mainTL.from([svg, caption], { autoAlpha: 0, stagger: 0.2 });
  return mainTL;
}

function animateGears(
  container,
  trigger,
  gears,
  caption,
  captionMask,
  partOneContainer
) {
  const gearsTL = gsap.timeline();
  const rotations = [-1440, 1440, -1440];

  gsap.set(captionMask, {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
  });

  const mainTL = gsap.timeline();
  gearsTL.to(gears, {
    ease: "none",
    duration: 1,
    transformOrigin: "50% 50%",
    rotation: (index) => {
      return rotations[index];
    },
  });
  gearsTL.clip(
    captionMask,
    { direction: "top", duration: 1, ease: "none" },
    "<"
  );
  mainTL.add(gearsTL, 0);
  mainTL.to(partOneContainer, { autoAlpha: 0 });

  return mainTL;
}

function makePartTwo(
  container,
  trigger,
  textElement,
  caption,
  captionMask,
  partTwoContainer,
  map
) {
  const mainTL = gsap.timeline();

  const states = setupMap(map);
  gsap.set(map, { autoAlpha: 0 });

  const revealTL = gsap.timeline();
  revealTL.to(partTwoContainer, { autoAlpha: 1 });
  revealTL.to(map, { autoAlpha: 1 });

  mainTL.add(revealTL);

  mainTL.add(animateMap(map, states));

  return mainTL;
}

function setupMap(map) {
  const statePaths = map.querySelectorAll("path");
  const west = [
    "AZ",
    "CO",
    "ID",
    "MT",
    "NV",
    "NM",
    "UT",
    "WY",
    "CA",
    "OR",
    "WA",
  ];
  const midWest = [
    "IL",
    "IN",
    "MI",
    "OH",
    "WI",
    "IA",
    "KS",
    "MN",
    "MO",
    "NE",
    "ND",
    "SD",
  ];
  const south = [
    "DE",
    "MD",
    "DC",
    "WV",
    "KY",
    "OK",
    "TX",
    "TN",
    "AR",
    "GA",
    "AL",
    "MS",
    "LA",
    "FL",
    "NC",
    "SC",
    "VA",
  ];
  const northEast = ["CT", "ME", "MA", "NH", "RI", "VT", "NJ", "NY", "PA"];

  const states = {
    midWest: setupStates(midWest, statePaths),
    northEast: setupStates(northEast, statePaths),
    south: setupStates(south, statePaths),
    west: setupStates(west, statePaths),
  };

  return states;

  // gsap.set(states.midWest, { fill: USA_REGION_COLORS.midWest });
  // gsap.set(states.northEast, { fill: USA_REGION_COLORS.northEast });
  // gsap.set(states.south, { fill: USA_REGION_COLORS.south });
  // gsap.set(states.west, { fill: USA_REGION_COLORS.west });
}

function setupStates(targets, states) {
  let targetStates = [];
  states.forEach((state) => {
    for (let i = 0; i < targets.length; i++) {
      if (state.id.indexOf(targets[i]) !== -1) {
        targetStates.push(state);
      }
    }
  });
  return targetStates;
}

function animateMap(map, states) {
  const mainTL = gsap.timeline();
  const stateStagger = 0.05;

  // midWest
  mainTL.to(map, {
    scale: 1.5,
    xPercent: "-5",
    yPercent: "20",
    transformOrigin: "50% 50%",
  });
  mainTL.to(states.midWest, {
    fill: USA_REGION_COLORS.midWest,
    stagger: stateStagger,
  });

  // northEast
  mainTL.to(map, {
    scale: 2.5,
    xPercent: "-90",
    yPercent: "60",
    transformOrigin: "50% 50%",
  });
  mainTL.to(
    states.northEast,
    {
      fill: USA_REGION_COLORS.northEast,
      stagger: stateStagger,
    },
    "+=0.5"
  );

  // south
  mainTL.to(map, {
    scale: 1.8,
    xPercent: "-15",
    yPercent: "-30",
    transformOrigin: "50% 50%",
  });
  mainTL.to(
    states.south,
    {
      fill: USA_REGION_COLORS.south,
      stagger: stateStagger,
    },
    "+=0.5"
  );

  // west
  mainTL.to(map, {
    scale: 1.3,
    xPercent: "40",
    yPercent: "15",
    transformOrigin: "50% 50%",
  });
  mainTL.to(
    states.west,
    {
      fill: USA_REGION_COLORS.west,
      stagger: stateStagger,
    },
    "+=0.5"
  );

  // center
  mainTL.to(
    map,
    {
      scale: 1,
      xPercent: "0",
      yPercent: "0",
      transformOrigin: "50% 50%",
    },
    "+=0.5"
  );

  // end
  const allStates = [
    ...states.northEast,
    ...states.midWest,
    ...states.south,
    ...states.west,
  ];
  const shuffledStates = Utilities.sattoloShuffleCopy(allStates);
  mainTL.to(shuffledStates, { fill: "none", stagger: 0.01 }, "+=0.5");

  return mainTL;
}
