import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function UpsyDaisyDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a simple logo animation with two sides and two effects. I use
        GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Plugins/SplitText"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          SplitText
        </Link>{" "}
        plugin to target the letters in the text, animating the left side
        characters from behind their container with an up-and-over effect, then
        have the right side characters "grow" up from below their container.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        A logo animation is a great way to introduce your brand, and could work
        as a site intro, or a banner/header animation.
      </Typography>
    </DemoDescription>
  );
}

export default UpsyDaisyDescription;
