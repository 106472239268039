import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link } from "@material-ui/core";
import ScrollDownArrows from "./ScrollDownArrows";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    visibility: "hidden",
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
  },
  bigText: {
    fontSize: "150%",
  },
  boldText: {
    fontWeight: "600",
  },
  scrollDownArrows: {
    visibility: "hidden",
  },
}));

function Intro(props) {
  const containerRef = React.useRef();
  const scrollDownArrowsRef = React.useRef();
  const scrollDownArrowsTimelineRef = React.useRef();

  useEffect(() => {
    const containerElement = containerRef.current;
    const text = new SplitText(containerElement, { type: "words" });
    const scrollDownArrowsElement = scrollDownArrowsRef.current;
    const scrollDownArrowsTimeline = scrollDownArrowsTimelineRef.current;

    const textTarget = text.words;
    gsap.set(textTarget, { autoAlpha: 0 });

    const revealContentTL = gsap.timeline();
    revealContentTL.set(containerElement, { autoAlpha: 1 });
    revealContentTL.to(textTarget, {
      autoAlpha: 1,
      stagger: 0.015,
      duration: 1.5,
      delay: 1,
    });
    revealContentTL.to(
      scrollDownArrowsElement,
      {
        autoAlpha: 1,
        duration: 0.2,
        onComplete: () => {
          scrollDownArrowsTimeline.playTimeline();
        },
      },
      "<+=1.3"
    );

    ScrollTrigger.create({
      animation: revealContentTL,
      trigger: containerElement,
      start: "top 80%",
      once: true,
    });

    const hideScrollDownArrowsTL = gsap.timeline();
    hideScrollDownArrowsTL.to(scrollDownArrowsElement, {
      autoAlpha: 0,
      immediateRender: false,
      scrollTrigger: {
        trigger: scrollDownArrowsElement,
        start: "top 33%",
        end: "top top",
        scrub: true,
        // toggleActions: "play reset play reset",
      },
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.container}
      container
      justify="center"
    >
      <Grid item xs={12} md={10} lg={7} align="center">
        <Typography variant="h6" align="left">
          <span className={classes.bigText}>Launched on 2 March 1972,</span>{" "}
          <span className={classes.boldText}>Pioneer 10</span> was the first
          spacecraft to travel through the Asteroid belt, and the first
          spacecraft to make direct observations and obtain close-up images of
          Jupiter. Famed as the most remote object ever made by man through most
          of its mission, Pioneer 10 is now over 19 billion kilometers (11
          billion miles) away.{"  "}
          <Link
            color="inherit"
            variant="subtitle2"
            href="https://web.archive.org/web/20041105100336/http://spaceprojects.arc.nasa.gov/Space_Projects/pioneer/PNhome.html"
            target="_blank"
            rel="noopener"
          >
            (Source:&nbsp;NASA)
          </Link>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        ref={scrollDownArrowsRef}
        className={classes.scrollDownArrows}
      >
        <ScrollDownArrows ref={scrollDownArrowsTimelineRef} />
      </Grid>
    </Grid>
  );
}

export default Intro;
