import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import DemoDescription from "../DemoDescription";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  descriptionParagraph: {
    color: theme.palette.text.primary,
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
}));

function SuperBallsDescription(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <DemoDescription path={props.path ? props.path : "/home"}>
      <Typography className={classes.descriptionParagraph}>
        This is a fun welcome animation that fills the screen with bouncing
        balls and a welcome message, before the whole animation container moves
        up out of the way to reveal the page behind it.
      </Typography>
      <Typography className={classes.descriptionParagraph}>
        I used GSAP's{" "}
        <Link
          href="https://greensock.com/docs/v3/Eases/CustomBounce"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          CustomBounce
        </Link>{" "}
        plugin to make the elements squish on impact, and{" "}
        <Link
          href="https://github.com/christiannaths/redacted-font"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Redacted Font
        </Link>{" "}
        by Christian Naths for the text effect on the Mockup Content page.
      </Typography>
    </DemoDescription>
  );
}

export default SuperBallsDescription;
