import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import gsap from "gsap";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.subHeader,
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  text: {
    color: theme.palette.text.primary,
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

function SubHeader(props) {
  const subHeaderTextRef = React.useRef();

  useEffect(() => {
    const subHeaderText = subHeaderTextRef.current;

    const subHeaderSplitText = new SplitText(subHeaderText, {
      type: "words, chars",
    });

    gsap.from(subHeaderSplitText.chars, {
      duration: 0.2,
      autoAlpha: 0,
      y: "+=3px",
      stagger: 0.02,
      delay: 0.3,
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} xl={10}>
        <Typography
          ref={subHeaderTextRef}
          variant="h5"
          className={classes.text}
          align="center"
        >
          {props.text
            ? props.text
            : "ineluctable modality of the visible: at least that if no more, thought through my eyes"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SubHeader;
