import React from "react";
import { useEffect } from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import * as Utilities from "./AnimationUtilities";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    textAlign: "center",
    width: "100% !important",
    maxWidth: "100% !important",
    pointerEvents: "none",
  },
  title: {
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "50px",
    },
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  text: {
    width: "100%",
    maxWidth: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    pointerEvents: "initial !important",
  },
  mediumUp: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function WordCloud() {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const textContainerRef = React.useRef();

  useEffect(() => {
    // elements
    const containerElement = containerRef.current;
    const titleElement = titleRef.current;
    const textElement = textContainerRef.current;

    // animate
    revealTitle(titleElement, containerElement);
    revealText(textElement, containerElement);
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.root}
      container
      justify="center"
    >
      <Typography ref={titleRef} className={classes.title} variant="h2">
        word cloud
      </Typography>
      <Grid item xs={12} md={9} lg={6} align="center">
        <Typography ref={textContainerRef} className={classes.text} paragraph>
          Ineluctable modality of the visible: at least that if no more, thought
          through my eyes. Signatures of all things I am here to read, seaspawn
          and seawrack, the nearing tide, that rusty boot. Snotgreen,
          bluesilver, rust: coloured signs.
          <span className={classes.mediumUp}>
            {" "}
            Limits of the diaphane. But he adds: in bodies. Then he was aware of
            them bodies before of them coloured. How? By knocking his sconce
            against them, sure. Go easy. Bald he was and a millionaire, maestro
            di color che sanno. Limit of the diaphane in. Why in? Diaphane,
            adiaphane. If you can put your five fingers through it, it is a
            gate, if not a door. Shut your eyes and see.
          </span>{" "}
          <Link
            href="http://m.joyceproject.com/chapters/proteus.html"
            color="inherit"
            target="_blank"
            rel="noopener"
            className={classes.link}
          >
            (Ulysses)
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default WordCloud;

function revealTitle(title, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 0.2;
  const text = new SplitText(title, { type: "chars" });
  mainTL.from(Utilities.sattoloShuffleCopy(text.chars), {
    autoAlpha: 0,
    stagger: 0.2,
  });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    start: "top 25%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });
}

function revealText(textElement, container) {
  const mainTL = gsap.timeline();
  const scrollDuration = window.innerHeight * 1.3;

  const text = new SplitText(textElement, { type: "words" });
  mainTL.from(Utilities.sattoloShuffleCopy(text.words), {
    autoAlpha: 0,
    stagger: 0.2,
  });

  ScrollTrigger.create({
    animation: mainTL,
    trigger: container,
    pin: container,
    anticipatePin: 1,
    start: "top 20%",
    end: "+=" + scrollDuration,
    scrub: 1,
    toggleActions: "play reset play reset",
  });
}
