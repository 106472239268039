import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as Utilities from "../../../../AnimationUtilities";
import Circle from "./Circle";

gsap.registerPlugin(ScrollTrigger);

const NUMBER_OF_CIRCLES = 11;
let ANIMATIONS = Math.ceil(window.innerHeight / 1000) + 1;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100vh",
    height: "auto",
    position: "fixed",
    top: 0,
    left: 0,
  },
}));

function AnimatedBackground(props) {
  ANIMATIONS = props.animationCount ? props.animationCount : ANIMATIONS;
  const containerRef = React.useRef();

  let circleArray = [];
  let circleRefArray = [];
  for (let i = 0; i < NUMBER_OF_CIRCLES; i++) {
    circleArray.push("key-" + i);
  }

  useEffect(() => {
    // ELEMENTS
    const containerElement = containerRef.current;
    const circleElements = circleRefArray.map((ref) => ref.current);

    // SETUP
    circleElements.forEach((element, index) => {
      Utilities.centerElement(element);
      gsap.set(element, {
        top: 0,
        left: 0,
        x: gsap.utils.random(window.innerWidth * 0.2, window.innerWidth * 0.8),
        y: gsap.utils.random(
          window.innerHeight * 0.2,
          window.innerHeight * 0.8
        ),
        autoAlpha: gsap.utils.random(0.33, 0.66),
        transformOrigin: "50% 50%",
        zIndex: index,
      });
      element.dataset.animationCount = 0;
      animationLoop(element);
    });

    // ANIMATE
    const mainTL = gsap.timeline();
    for (let i = 0; i < circleElements.length; i++) {
      const tempTL = gsap.timeline();
      for (let j = 0; j < ANIMATIONS; j++) {
        tempTL.add(chooseNextPosition(circleElements[i]));
      }
      mainTL.add(tempTL, 0);
    }

    ScrollTrigger.create({
      animation: mainTL,
      trigger: containerElement.parentElement,
      start: "top 50px",
      end: "bottom bottom",
      scrub: 1,
      toggleActions: "play reset play reset",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return (
    <div className={classes.root} ref={containerRef}>
      {circleArray.map((elementKey) => {
        const newRef = React.createRef();
        circleRefArray.push(newRef);
        return (
          <Circle
            key={elementKey}
            ref={newRef}
            size={Math.ceil(
              gsap.utils.random([
                0,
                gsap.utils.random(50, 60),
                gsap.utils.random(90, 110),
                gsap.utils.random(130, 150),
              ])
            )}
          />
        );
      })}
    </div>
  );
}

export default AnimatedBackground;

function chooseNextPosition(element, set) {
  const elementSize = element.offsetWidth / 2;
  const xPercent = gsap.utils.random(0.1, 0.9);
  const yPercent = gsap.utils.random(0.1, 0.9);
  const rect = element.getBoundingClientRect();

  const newPosition = {
    x: Math.floor(window.innerWidth * xPercent - elementSize),
    y: Math.floor(window.innerHeight * yPercent - elementSize),
  };

  const distance = getDistance(
    [rect.left, newPosition.x],
    [rect.top, newPosition.y]
  );
  const speed = 0.04;
  const minDuration = 5;
  const duration =
    distance * speed > minDuration ? distance * speed : minDuration;

  const mainTL = gsap.timeline();
  if (set) {
    mainTL.set(
      element,
      {
        x: newPosition.x,
        y: newPosition.y,
        autoAlpha: gsap.utils.random(0.33, 0.66),
        transformOrigin: "50% 50%",
      },
      gsap.utils.random(0.2, 0.4)
    );
  } else if (element.dataset.animationCount < ANIMATIONS || ANIMATIONS === -1) {
    mainTL.to(
      element,
      {
        x: newPosition.x,
        y: newPosition.y,
        duration: duration,
        transformOrigin: "50% 50%",
        ease: gsap.utils.random(["power1.inOut", "power2.inOut"]),
      },
      gsap.utils.random(0.2, 0.4)
    );
  }

  element.dataset.animationCount++;
  return mainTL;
}

function getDistance(x, y) {
  var a = x[0] - x[1];
  var b = y[0] - y[1];
  return Math.sqrt(a * a + b * b);
}

function animationLoop(element) {
  const mainTL = gsap.timeline();

  // roll for animation: opacity or scale
  const roll = gsap.utils.random(["opacity", "scale"]);
  if (roll === "opacity") {
    let newAlpha;
    if (element.style.opacity <= 0.5) {
      newAlpha = gsap.utils.random(0.66, 0.86);
    } else {
      newAlpha = gsap.utils.random(0.33, 0.45);
    }
    mainTL.to(
      element,
      {
        autoAlpha: newAlpha,
        duration: gsap.utils.random(9, 12),
        ease: "power1.inOut",
        onComplete: () => {
          animationLoop(element);
        },
        immediateRender: false,
      },
      gsap.utils.random(0.5, 1)
    );
  } else if (roll === "scale") {
    let newScale, scaleEase;
    if (element._gsap.scaleX <= 1) {
      newScale = gsap.utils.random(1.1, 1.5);
      scaleEase = "back.inOut(2)";
    } else {
      newScale = gsap.utils.random(0.8, 0.95);
      scaleEase = "back.inOut(1)";
    }
    mainTL.to(
      element,
      {
        scale: newScale,
        duration: gsap.utils.random(18, 24),
        ease: scaleEase,
        transformOrigin: "50% 50%",
        onComplete: () => {
          animationLoop(element);
        },
        immediateRender: false,
      },
      gsap.utils.random(0.5, 1)
    );
  }
}
