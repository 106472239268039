import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { Grid, Link, Card, CardMedia } from "@material-ui/core";
import DescriptionAccordion from "./DescriptionAccordion";

function AnimationDescriptionAndDemoLink(props) {
  useEffect(() => {}, []);

  const useStyles = makeStyles((theme) => ({
    root: {},
    accordionItem: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    linkItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    thumbnail: {
      borderRadius: "3px",
      border: "none",
      width: 85,
      height: 85,
      marginRight: theme.spacing(2),
      [theme.breakpoints.only("xs")]: {
        width: 65,
        height: 65,
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.only("sm")]: {
        width: 70,
        height: 70,
        marginRight: theme.spacing(1),
      },
    },
    thumbnailLightMode: {
      position: "absolute",
      visibility: theme.palette.type === "light" ? "visible" : "hidden",
    },
  }));

  const classes = useStyles();
  const thumbnailFallbackURL = "https://via.placeholder.com/100";

  return (
    <Grid container alignItems="baseline">
      <Grid item className={classes.accordionItem} xs={8} sm={8} align="left">
        <DescriptionAccordion
          heading={props.heading}
          description={props.description}
        />
      </Grid>
      <Grid item className={classes.linkItem} xs={4} sm={4} align="right">
        <NavLink
          exact
          to={props.path ? props.path : "/home"}
          className={classes.link}
        >
          <Link component="button">
            <Card elevation={0}>
              <Grid
                className={classes.modalTrigger}
                container
                alignItems="center"
              >
                <CardMedia
                  className={classes.thumbnail}
                  image={props.image ? props.image : thumbnailFallbackURL}
                  title="animation thumbnail"
                  alt="this thumbnail is a screenshot of the animation it links to"
                />
                <CardMedia
                  className={[
                    classes.thumbnail,
                    classes.thumbnailLightMode,
                  ].join(" ")}
                  image={
                    props.imageLight ? props.imageLight : thumbnailFallbackURL
                  }
                  title="animation thumbnail"
                  alt="this thumbnail is a screenshot of the animation it links to"
                />
              </Grid>
            </Card>
          </Link>
        </NavLink>
      </Grid>
    </Grid>
  );
}

export default AnimationDescriptionAndDemoLink;
