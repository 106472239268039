import React, { forwardRef, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer";
import SiteMap from "./SiteMap";

const Content = forwardRef((props, ref) => {
  const location = useLocation();
  const bodyRef = ref;
  const pages = SiteMap();

  const useStyles = makeStyles((theme) => ({
    root: {},
  }));

  useEffect(() => {
    const session = sessionStorage.getItem("jlhWelcomeAnimationHasPlayed");
    if (session !== "true") {
      sessionStorage.setItem("jlhWelcomeAnimationHasPlayed", "true");
    }
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root} id="content-Top-Level">
      <div ref={bodyRef}>
        <Switch location={location}>
          {pages.map(({ path, Component }) => (
            <Route path={path} key={path}>
              <Component />
            </Route>
          ))}
        </Switch>
      </div>
      <Footer />
    </div>
  );
});

export default Content;
