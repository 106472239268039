import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import gsap from "gsap";
import Header from "./HomePageHeader";
import SubHeader from "./SubHeader";
import HomePageLinks from "./HomePageLinks";
// import WelcomeAnimation from "./animations/Welcomes/Zoom-In/WelcomeOverlay";
import WelcomeAnimation from "./animations/Welcomes/Line-Drawing/LineDrawing";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    visibility: "hidden",
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
  gridContainer: {
    minHeight: theme.sizes.bodyMinHeight,
    position: "relative",
    paddingTop: theme.spacing(5),
  },
  welcomeMessage: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  welcomeAnimation: {
    visibility: "hidden",
  },
}));

function HomePage(props) {
  const pageRef = React.useRef();
  const containerRef = React.useRef();
  const welcomeAnimationRef = React.useRef();

  useEffect(() => {
    const pageElement = pageRef.current;
    gsap.to(pageElement, { autoAlpha: 1, duration: 0.2, delay: 0.2 });

    // handle welcome animation
    const welcomeAnimation = welcomeAnimationRef.current;
    const session = sessionStorage.getItem("jlhWelcomeAnimationHasPlayed");
    // welcomeAnimation.playTimeline();
    if (session !== "true") {
      welcomeAnimation.playTimeline();
    } else {
      welcomeAnimation.skipTimeline();
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      <WelcomeAnimation
        ref={welcomeAnimationRef}
        className={classes.welcomeAnimation}
      />
      <div ref={pageRef} className={classes.root}>
        <Header text="Jonathan Harrison" />
        <SubHeader text="creativity and code: animation, ui, ux" />
        <Container maxWidth="md">
          <Grid
            ref={containerRef}
            className={classes.gridContainer}
            container
            justify="center"
          >
            <Grid item xs={12}>
              <div className={classes.welcomeMessage}>
                <Typography>
                  Welcome to my site. Here you'll find examples of my work with{" "}
                  <Link
                    href="https://greensock.com/gsap/"
                    color="inherit"
                    target="_blank"
                    rel="noopener"
                  >
                    GSAP
                  </Link>
                  ,{" "}
                  <Link
                    href="https://reactjs.org"
                    color="inherit"
                    target="_blank"
                    rel="noopener"
                  >
                    React
                  </Link>
                  , and{" "}
                  <Link
                    href="https://material-ui.com"
                    color="inherit"
                    target="_blank"
                    rel="noopener"
                  >
                    Material UI
                  </Link>
                  . Follow the category links to check out animation demos. All
                  code, copy, and art by JLH unless otherwise stated.
                </Typography>
              </div>
              <HomePageLinks />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default HomePage;
