import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";

const navLinks = [
  {
    title: "Welcome Animations",
    path: "/welcomes",
    text: welcomeAnimationSubtext(),
  },
  {
    title: "Scrolling Animations",
    path: "/scrollers",
    text: scrollingAnimationSubtext(),
  },
  { title: "Animated Logos", path: "/logos", text: animatedLogoSubtext() },
  { title: "About", path: "/about", text: aboutSubtext(), textAlign: "center" },
];

function HomePageLinks(props) {
  function clickHandler() {
    sessionStorage.setItem("linkedFromHomePage", "true");
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4),
    },
    navigationList: {},
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
      "& h5": {
        textDecoration: "underline",
      },
    },
    listItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    rowDivider: {
      // width: "55%",
      backgroundColor: theme.palette.dividerColor,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Divider className={classes.rowDivider} />
      <List component="nav" className={classes.navigationList}>
        {navLinks.map(({ title, path, text, textAlign }) => (
          <div key={navLinks.findIndex((x) => x.title === title).toString()}>
            <NavLink
              exact
              to={path}
              className={classes.link}
              onClick={clickHandler}
            >
              <ListItem className={classes.listItem} button>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    variant: "h5",
                    gutterBottom: true,
                    style: { textDecoration: "underline" },
                  }}
                  secondary={text ? text : "some more text"}
                  secondaryTypographyProps={{
                    color: "textPrimary",
                    variant: "body1",
                    gutterBottom: true,
                    align: textAlign ? textAlign : "left",
                  }}
                  align="center"
                />
              </ListItem>
            </NavLink>
            {title !== "About" ? (
              <Divider className={classes.rowDivider} />
            ) : (
              ""
            )}
          </div>
        ))}
      </List>
    </div>
  );
}

function welcomeAnimationSubtext() {
  return "Kick off your user experience with a bang. Establish a mood before the site loads. GSAP powers high performance DOM animations, so you can wow visitors on any device.";
}

function scrollingAnimationSubtext() {
  return "Add interactivity to increase user engagement. GSAP ScrollTrigger delivers smooth animations for scrollytelling, data visualization, step effects, or page content reveal.";
}

function animatedLogoSubtext() {
  return "Bring your branding to life. Transform your designs into beautiful animations that look great on mobile and desktop.";
}

function aboutSubtext() {
  return "A bit about the site and its developer.";
}

export default HomePageLinks;
