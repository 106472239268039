import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: theme.sizes.bodyMinHeight,
    backgroundColor: theme.palette.mockup.background,
    height: "100%",
    position: "fixed",
    padding: theme.spacing(2),
    "& h1": {
      [theme.breakpoints.only("xs")]: {
        fontSize: "45px",
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "65px",
        marginBottom: theme.spacing(2),
      },
    },
    "& h2": {
      [theme.breakpoints.only("xs")]: {
        fontSize: "38px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "45px",
      },
    },
    "& h3": {
      [theme.breakpoints.only("xs")]: {
        fontSize: "30px",
      },
    },
  },
  header: {
    backgroundColor: theme.palette.mockup.content,
    height: "30vh",
    borderRadius: "15px",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  headerTextH1: {
    fontFamily: "redacted_scriptbold",
    color: theme.palette.mockup.text.headline,
  },
  headerTextH2: {
    fontFamily: "redacted_scriptregular",
    color: theme.palette.mockup.text.subHeadline,
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mockup.content,
    height: "20vh",
    borderRadius: "15px",
  },
  contentHeader: {
    fontFamily: "redacted_scriptregular",
    color: theme.palette.mockup.text.subHeadline,
  },
  contentBody: {
    fontFamily: "redacted_scriptregular",
    color: theme.palette.mockup.text.body,
    fontSize: "130%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function ContentMockup(props) {
  const containerRef = React.useRef();

  useEffect(() => {});

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.gridContainer}
      container
      spacing={2}
      justify="center"
    >
      {/* header */}
      <Grid item xs={12} align="center">
        <Paper elevation={0} className={classes.header}>
          <Typography className={classes.headerTextH1} variant="h1">
            hello folks
          </Typography>
          <Typography variant="h2" className={classes.headerTextH2}>
            fail better
          </Typography>
        </Paper>
      </Grid>
      {/* content */}
      {[
        "noting to",
        "be done",
        "begning to",
        "come round",
        "to that",
        "opinion",
      ].map((value) => (
        <Grid key={value} item xs={6} lg={4} align="left">
          <Paper elevation={0} className={classes.content}>
            <Typography variant="h3" className={classes.contentHeader}>
              {value}
            </Typography>
            <Typography className={classes.contentBody}>
              nothing to be done. im beginning to come around to that opinion.
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default ContentMockup;
