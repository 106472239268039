import React, { useEffect, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ReactComponent as MessageSVG } from "./img/welcome-optimized.svg";
import { ReactComponent as WallSVG } from "./img/simple-optimized.svg";
import gsap from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import GSDevTools from "gsap/GSDevTools";
import * as Utilities from "../../../AnimationUtilities";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(GSDevTools);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.animations.siteWelcome.backgroundStart,
    position: "fixed",
    top: 0,
    zIndex: 20000,
    overflow: "hidden",
  },
  animationContainer: {
    position: "relative",
    height: "100%",
    visibility: "hidden",
  },
  messageSVG: {
    width: "80%",
    maxWidth: "800px",
    height: "auto",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    "& *": {
      stroke: theme.palette.animations.siteWelcome.textStart,
    },
    "& #background": {
      fill: "none",
      stroke: "none",
    },
    "& #textCopy path": {
      visibility: "hidden",
      stroke:
        theme.palette.type === "dark"
          ? theme.palette.animations.siteWelcome.brightGreen
          : theme.palette.animations.siteWelcome.blue,
    },
  },
  wallSVG: {
    width: "100%",
    height: "100%",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    zIndex: 100,
    "& #background": {
      visibility: "hidden",
    },
    "& #wall path": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.animations.siteWelcome.green
          : theme.palette.animations.siteWelcome.blue,
    },
  },
}));

const LineDrawing = React.forwardRef((props, ref) => {
  const WELCOME_TL = gsap.timeline({
    paused: true,
    onStart: () => {
      window.addEventListener("scroll", lockScroll);
    },
    onComplete: () => {
      gsap.delayedCall(0.3, () => {
        window.removeEventListener("scroll", lockScroll);
      });
    },
  });

  useImperativeHandle(ref, () => ({
    playTimeline,
    skipTimeline,
  }));

  function playTimeline() {
    WELCOME_TL.play();
  }

  function skipTimeline() {
    WELCOME_TL.progress(1);
  }

  const gridContainerRef = React.useRef();
  const animationContainerRef = React.useRef();
  const messageSVGRef = React.useRef();
  const wallSVGRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const gridContainer = gridContainerRef.current;
    const animationContainer = animationContainerRef.current;
    const messageSVG = messageSVGRef.current;
    const wallSVG = wallSVGRef.current;

    // SETUP
    Utilities.centerElement(messageSVG, 48);
    Utilities.centerElement(wallSVG);

    // animation elements
    const letterW = messageSVG.querySelectorAll("#letterW path");
    const letterEOne = messageSVG.querySelectorAll("#letterEOne path");
    const letterL = messageSVG.querySelectorAll("#letterL path");
    const letterC = messageSVG.querySelector("#letterC");
    const letterO = messageSVG.querySelector("#letterO");
    const letterM = messageSVG.querySelectorAll("#letterM path");
    const letterETwo = messageSVG.querySelectorAll("#letterETwo path");
    const wholeLetters = messageSVG.querySelectorAll("#textCopy path");

    // letter start times
    const letterStartTimes = {
      w: "draw+=" + 0.4,
      eOne: "draw+=" + 0.2,
      l: "draw+=" + 0,
      c: "draw+=" + 0.1,
      o: "draw+=" + 0.7,
      m: "draw+=" + 0.2,
      eTwo: "draw+=" + 0.3,
    };

    // ANIMATE
    WELCOME_TL.to(
      [animationContainer, messageSVG],
      { autoAlpha: 1, duration: 0.2 },
      0.1
    );
    // draw letters
    WELCOME_TL.add("draw", "+=0.3");
    WELCOME_TL.add(
      animateLetterW(letterW, wholeLetters[0]),
      letterStartTimes.w
    );
    WELCOME_TL.add(
      animateLetterEOne(letterEOne, wholeLetters[1]),
      letterStartTimes.eOne
    );
    WELCOME_TL.add(
      animateLetterL(letterL, wholeLetters[2]),
      letterStartTimes.l
    );
    WELCOME_TL.add(
      animateLetterC(letterC, wholeLetters[3]),
      letterStartTimes.c
    );
    WELCOME_TL.add(
      animateLetterO(letterO, wholeLetters[4]),
      letterStartTimes.o
    );
    WELCOME_TL.add(
      animateLetterM(letterM, wholeLetters[5]),
      letterStartTimes.m
    );
    WELCOME_TL.add(
      animateLetterETwo(letterETwo, wholeLetters[6]),
      letterStartTimes.eTwo
    );

    // wipe wall
    const pieces = Utilities.sattoloShuffleCopy(
      gsap.utils.toArray(wallSVG.querySelectorAll("#wall path"))
    );
    WELCOME_TL.set(pieces, { autoAlpha: 0 });
    WELCOME_TL.set(wallSVG, { autoAlpha: 1 });
    WELCOME_TL.to(pieces, { autoAlpha: 1, stagger: 0.1 }, "+=0.5");
    WELCOME_TL.set(messageSVG, { autoAlpha: 0 });
    WELCOME_TL.set(gridContainer, { backgroundColor: "transparent" });
    WELCOME_TL.to(
      Utilities.sattoloShuffleCopy(pieces),
      { autoAlpha: 0, duration: 0.3, stagger: 0.06 },
      "+=0.2"
    );

    // the end
    WELCOME_TL.set(gridContainer, { autoAlpha: 0 });
    WELCOME_TL.set(gridContainer, { display: "none" });

    return () => {
      window.removeEventListener("scroll", lockScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={gridContainerRef}
      className={classes.gridContainer}
      container
      justify="center"
      alignItems="center"
    >
      <Grid
        ref={animationContainerRef}
        className={classes.animationContainer}
        item
        xs={12}
        align="center"
      >
        <MessageSVG ref={messageSVGRef} className={classes.messageSVG} />
        <WallSVG ref={wallSVGRef} className={classes.wallSVG} />
      </Grid>
    </Grid>
  );
});

// animation functions
const DrawSVGFirstTweenDuration = 0.02;
const WholeLetterFadeInDuration = 0.5;
function animateLetterW(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);
  tl.add("part 1", 0);
  tl.add("part 2", 0.3);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterEOne(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.5);
  tl.add("part 3", 0.6);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 2;
  const part3Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 96%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=" + firstTweenDuration
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterL(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.35);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 1.5;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=" + firstTweenDuration
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=" + firstTweenDuration
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterC(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);

  const duration = 1;

  tl.fromTo(
    letterDrawing,
    { drawSVG: "0% 0%" },
    {
      drawSVG: "0% 0.5%",
      duration: DrawSVGFirstTweenDuration,
      ease: "Power2.inOut",
    },
    "start"
  );

  tl.to(letterDrawing, {
    drawSVG: "0% 100%",
    duration: duration,
    ease: "Power2.inOut",
  });

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterO(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("start", 0);

  const duration = 1.66;

  tl.fromTo(
    letterDrawing,
    { drawSVG: "0% 0%" },
    {
      drawSVG: "0% 0.5%",
      duration: DrawSVGFirstTweenDuration,
      ease: "Power2.inOut",
    },
    "start"
  );

  tl.to(letterDrawing, {
    drawSVG: "0% 100%",
    duration: duration,
    ease: "Power2.inOut",
  });

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function animateLetterM(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0.4);
  tl.add("part 2", 0.3);
  tl.add("part 3", 0.3);
  tl.add("part 4", 0);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;
  const part3Duration = 0.6;
  const part4Duration = 1;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=0.1"
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=0.1"
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=0.1"
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 4"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part4Duration, ease: "Power2.inOut" },
    "part 4+=0.1"
  );

  // end
  tl.add("the end");
  tl.to(
    letterWhole,
    { autoAlpha: 1, duration: WholeLetterFadeInDuration },
    "the end"
  );
  tl.to(
    letterDrawing,
    { autoAlpha: 0, duration: WholeLetterFadeInDuration },
    "the end+=0.01"
  );

  return tl;
}

function animateLetterETwo(letterDrawing, letterWhole) {
  const tl = gsap.timeline();
  tl.add("part 1", 0);
  tl.add("part 2", 0.15);
  tl.add("part 3", 0.4);
  tl.add("part 4", 0.975);

  const firstTweenDuration = DrawSVGFirstTweenDuration;
  const part1Duration = 1;
  const part2Duration = 0.6;
  const part3Duration = 0.6;
  const part4Duration = 0.6;

  tl.fromTo(
    letterDrawing[0],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 1"
  );
  tl.to(
    letterDrawing[0],
    { drawSVG: "0% 100%", duration: part1Duration, ease: "Power2.inOut" },
    "part 1+=0.1"
  );

  tl.fromTo(
    letterDrawing[1],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 2"
  );
  tl.to(
    letterDrawing[1],
    { drawSVG: "0% 100%", duration: part2Duration, ease: "Power2.inOut" },
    "part 2+=0.1"
  );

  tl.fromTo(
    letterDrawing[2],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 3"
  );
  tl.to(
    letterDrawing[2],
    { drawSVG: "0% 100%", duration: part3Duration, ease: "Power2.inOut" },
    "part 3+=0.1"
  );

  tl.fromTo(
    letterDrawing[3],
    { drawSVG: "0% 0%" },
    { drawSVG: "0% 0.5%", duration: firstTweenDuration, ease: "Power2.in" },
    "part 4"
  );
  tl.to(
    letterDrawing[3],
    { drawSVG: "0% 100%", duration: part4Duration, ease: "Power2.inOut" },
    "part 4+=0.1"
  );

  // end
  tl.to(letterWhole, { autoAlpha: 1, duration: WholeLetterFadeInDuration });
  tl.set(letterDrawing, { visibility: "hidden" });

  return tl;
}

function lockScroll() {
  window.scrollTo(0, 0);
}

export default LineDrawing;
