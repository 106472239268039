import React, { useEffect, forwardRef } from "react";
import { ReactComponent as PioneerTenDiagram } from "../../../img/scrollers/pioneer-10/Pioneer-10-optimized.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import DrawSVG from "gsap/DrawSVGPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVG);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "1em",
    backgroundColor: theme.palette.animations.pioneer10.SVGBackground,
    minHeight: "100vh",
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      minHeight: "600px",
    },
    width: "100%",
    position: "relative",
    pointerEvents: "none",
  },
  titleContainer: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 1000,
    backgroundColor:
      theme.palette.animations.pioneer10.sectionTitleBackground + " !important",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  title: {
    visibility: "hidden",
  },
  diagramContainer: {
    marginTop: "2em",
    overflow: "visible",
    willChange: "transform",
  },
  svg: {
    width: "90%",
    maxWidth: "1200px",
    height: "auto",
    visibility: "hidden",
    "& #In-Use rect": {
      fill: theme.palette.animations.pioneer10.SVGBackground + " !important",
    },
    "& #In-Use .captionText": {
      fill: theme.palette.text.primary + " !important",
    },
    "& #In-Use text, #In-Use .pioneer-10-diagram-label": {
      fill: theme.palette.animations.pioneer10.overlays + " !important",
    },
    "& #In-Use .pioneer-10-diagram-label-overlay": {
      fill: theme.palette.animations.pioneer10.overlays,
    },
    "& #In-Use .pioneer-10-diagram-leader-line": {
      stroke:
        theme.palette.animations.pioneer10.diagramLeaderLinesAndPoints +
        " !important",
    },
  },
}));

const SpaceshipDiagram = forwardRef((props, ref) => {
  const containerRef = React.useRef();
  const svgRef = React.useRef();
  const titleRef = React.useRef();

  useEffect(() => {
    const containerElement = containerRef.current;
    const svgElement = svgRef.current;
    const titleElement = titleRef.current;

    // move svg below title
    const menuBar = document.querySelector(".MuiToolbar-root");
    gsap.to(svgElement, { y: "+=" + menuBar.offsetHeight });

    // ZOOM
    const zoomTweens = _getZoomTweens(svgElement);

    // DIAGRAM LABELS
    const labels = document.querySelector("#In-Use");
    const labelObjects = _getLabelObjects(labels.children);
    Object.values(labelObjects).forEach((element) => {
      gsap.set(element.captionOverlay.children, {
        scaleY: 1.05,
        transformOrigin: "50% 50%",
      });
    });

    // SPACECRAFT COMPONENTS
    const PioneerTenSpacecraft = _getSpacecraftComponents();

    // ANIMATE
    const tl = gsap.timeline();

    // Reveal Container
    const revealTL = gsap.timeline();
    revealTL.to(svgElement, {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: containerElement,
        start: "top 33%",
        end: "top 56px",
        scrub: 0.25,
        invalidateOnRefresh: true,
      },
    });

    // setup
    _setupLabels(Object.values(labelObjects));
    const labelDelay = 1;
    const captionDelay = 1;
    const hideLabelDelay = 1;

    // initial state
    gsap.set(Object.values(PioneerTenSpacecraft), { autoAlpha: 1 });

    // show title
    tl.to(titleElement, { autoAlpha: 1 });

    // Animate Components and Labels
    const highlightTimelinePosition = "<+0.25";
    // IP
    tl.add(zoomTweens.IP_Target);
    tl.add("IP Zoomed");
    tl.add(_highlightComponent(PioneerTenSpacecraft.IP, PioneerTenSpacecraft));
    tl.add(_revealLabel(labelObjects.IPLabel), "+=" + labelDelay);
    tl.add(_revealCaption(labelObjects.IPLabel), "+=" + captionDelay);
    tl.add("IP Revealed");
    tl.add(_hideLabel(labelObjects.IPLabel), "+=" + hideLabelDelay);
    tl.add(_hideCaption(labelObjects.IPLabel), "<+0.3");
    tl.add(_showAllComponents(PioneerTenSpacecraft));
    tl.add("IP End");
    // HVM
    tl.add(zoomTweens.HVM_Target, "+=0.2");
    tl.add(
      _highlightComponent(PioneerTenSpacecraft.HVM, PioneerTenSpacecraft),
      highlightTimelinePosition
    );
    tl.add("HVM Zoomed");
    tl.add(_revealLabel(labelObjects.HVMLabel), "+=" + labelDelay);
    tl.add(_revealCaption(labelObjects.HVMLabel), "+=" + captionDelay);
    tl.add("HVM Revealed");
    tl.add(_hideLabel(labelObjects.HVMLabel), "+=" + hideLabelDelay);
    tl.add(_hideCaption(labelObjects.HVMLabel), "<+0.3");
    tl.add(_showAllComponents(PioneerTenSpacecraft));
    tl.add("HVM End");
    // Main Antenna
    tl.add(zoomTweens.MA_Target, "+=0.2");
    tl.add("MA Zoomed");
    tl.add(
      _highlightComponent(PioneerTenSpacecraft.mainBody, PioneerTenSpacecraft),
      highlightTimelinePosition
    );
    tl.add(_revealLabel(labelObjects.MainAntennaLabel), "+=" + labelDelay);
    tl.add(_revealCaption(labelObjects.MainAntennaLabel), "+=" + captionDelay);
    tl.add("MA Revealed");
    tl.add(_hideLabel(labelObjects.MainAntennaLabel), "+=" + hideLabelDelay);
    tl.add(_hideCaption(labelObjects.MainAntennaLabel), "<+0.3");
    tl.add(_showAllComponents(PioneerTenSpacecraft));
    tl.add("MA End");
    // PA
    tl.add(zoomTweens.PA_Target, "+=0.2");
    tl.add("PA Zoomed");
    tl.add(
      _highlightComponent(PioneerTenSpacecraft.PA, PioneerTenSpacecraft),
      highlightTimelinePosition
    );
    tl.add(_revealLabel(labelObjects.PALabel), "+=" + labelDelay);
    tl.add(_revealCaption(labelObjects.PALabel), "+=" + captionDelay);
    tl.add("PA Revealed");
    tl.add(_hideLabel(labelObjects.PALabel), "+=" + hideLabelDelay);
    tl.add(_hideCaption(labelObjects.PALabel), "<+0.3");
    tl.add(_showAllComponents(PioneerTenSpacecraft));
    tl.add("PA End");
    // RTG
    tl.add(zoomTweens.RTG_Target, "+=0.2");
    tl.add("RTG Zoomed");
    tl.add(
      _highlightComponent(PioneerTenSpacecraft.RTG, PioneerTenSpacecraft),
      highlightTimelinePosition
    );
    tl.add(_revealLabel(labelObjects.RTGLabel), "+=" + labelDelay);
    tl.add(_revealCaption(labelObjects.RTGLabel), "+=" + captionDelay);
    tl.add("RTG Revealed");
    tl.add(_hideLabel(labelObjects.RTGLabel), "+=" + hideLabelDelay);
    tl.add(_hideCaption(labelObjects.RTGLabel), "<+0.3");
    tl.add("RTG End");

    // END
    tl.add(zoomTweens.centerTheView, "+=0.2");
    tl.add(_showAllComponents(PioneerTenSpacecraft), "<+0.1");
    tl.add("zoom back out end", "+=1");
    tl.add(_revealLabel(labelObjects.IPLabel), "zoom back out end");
    tl.add(_revealLabel(labelObjects.HVMLabel), "zoom back out end");
    tl.add(_revealLabel(labelObjects.MainAntennaLabel), "zoom back out end");
    tl.add(_revealLabel(labelObjects.PALabel), "zoom back out end");
    tl.add(_revealLabel(labelObjects.RTGLabel), "zoom back out end");
    // wait for a beat while hiding the title
    tl.to(titleElement, { autoAlpha: 0, duration: 2 });
    tl.add("THE END");

    // add ScrollTrigger for main timeline
    ScrollTrigger.create({
      animation: tl,
      trigger: containerElement,
      pin: true,
      anticipatePin: 1,
      start: "top 56px",
      end: "+=" + window.innerHeight * 6,
      scrub: 0.5,
      toggleActions: "play reset play reset",
      invalidateOnRefresh: true,
    });

    // fade container out on scroll after un-pin
    const endTL = gsap.timeline();
    endTL.to(containerElement, { autoAlpha: 0 });
    ScrollTrigger.create({
      animation: endTL,
      trigger: containerElement,
      anticipatePin: 1,
      start: "bottom 90%",
      end: "+=" + window.innerHeight * 0.5,
      scrub: 0.5,
      toggleActions: "play reset play reset",
      invalidateOnRefresh: true,
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      ref={containerRef}
      className={classes.gridContainer}
      container
      justify="center"
    >
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography
          ref={titleRef}
          className={classes.title}
          variant="h4"
          align="center"
        >
          Systems
        </Typography>
      </Grid>
      <Grid
        className={classes.diagramContainer}
        item
        xs={12}
        md={8}
        lg={7}
        xl={6}
        align="center"
      >
        <PioneerTenDiagram ref={svgRef} className={classes.svg} />
      </Grid>
    </Grid>
  );
});

// Setup Functions
function _getSpacecraftComponents() {
  const spacecraft = document.querySelector("#spacecraft");

  for (let i = 0; i < spacecraft.children.length; i++) {
    const spacecraftComponent = spacecraft.children[i];
    const id = spacecraftComponent.id;
    if (
      id !== "HVM" &&
      id !== "IP" &&
      id !== "PA" &&
      id !== "RTG-Arm-Top" &&
      id !== "RTG-Arm-Bottom"
    ) {
      spacecraftComponent.classList += "main-body";
    }
  }

  return {
    mainBody: document.querySelectorAll(".main-body"),
    RTG: document.querySelectorAll("#RTG-Arm-Top, #RTG-Arm-Bottom"),
    HVM: document.querySelectorAll("#HVM, #HVM-Cap"),
    IP: document.querySelector("#IP"),
    PA: document.querySelector("#PA"),
  };
}

function _getLabelObjects(labels) {
  // Main Antenna
  const MainAntennaLabel = {
    leaderLine: labels[0].children[0],
    labelOverlay: labels[0].children[2],
    label: labels[0].children[1],
    caption: labels[0].children[3],
    captionOverlay: labels[0].children[4],
  };
  // RTG
  const RTGLabel = {
    leaderLine: labels[1].children[0],
    labelOverlay: labels[1].children[2],
    label: labels[1].children[1],
    caption: labels[1].children[3],
    captionOverlay: labels[1].children[4],
  };
  // HVM
  const HVMLabel = {
    leaderLine: labels[2].children[0],
    labelOverlay: labels[2].children[2],
    label: labels[2].children[1],
    caption: labels[2].children[3],
    captionOverlay: labels[2].children[4],
  };
  // PA
  const PALabel = {
    leaderLine: labels[3].children[0],
    labelOverlay: labels[3].children[2],
    label: labels[3].children[1],
    caption: labels[3].children[3],
    captionOverlay: labels[3].children[4],
  };
  // IP
  const IPLabel = {
    leaderLine: labels[4].children[0],
    labelOverlay: labels[4].children[2],
    label: labels[4].children[1],
    caption: labels[4].children[3],
    captionOverlay: labels[4].children[4],
  };

  return {
    MainAntennaLabel: MainAntennaLabel,
    RTGLabel: RTGLabel,
    HVMLabel: HVMLabel,
    PALabel: PALabel,
    IPLabel: IPLabel,
  };
}

function _setupLabels(labels) {
  for (let i = 0; i < labels.length; i++) {
    const labelObject = labels[i];
    labelObject.leaderLine.classList += "pioneer-10-diagram-leader-line";
    labelObject.label.classList += "pioneer-10-diagram-label";
    labelObject.labelOverlay.classList += "pioneer-10-diagram-label-overlay";
    gsap.set(labelObject.leaderLine, { drawSVG: "0% 0%" });
    gsap.set(labelObject.label, { autoAlpha: 0 });
    gsap.set(labelObject.labelOverlay, {
      scaleX: 0,
      transformOrigin: "0% 50%",
    });
  }
}

// Animation Functions
function _highlightComponent(target, components) {
  const tl = gsap.timeline();

  let array = Object.values(components);
  let fadeTargets = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i] !== target) {
      fadeTargets.push(array[i]);
    }
  }

  tl.to(target, { autoAlpha: 1 }, 0);
  tl.to(fadeTargets, { autoAlpha: 0.3 }, 0);

  return tl;
}

function _showAllComponents(spacecraft) {
  const tl = gsap.timeline();
  tl.to(Object.values(spacecraft), { autoAlpha: 1 });
  return tl;
}

function _revealLabel(labelObject) {
  const tl = gsap.timeline();
  const overlayDuration = 0.3;

  tl.to(labelObject.leaderLine, { drawSVG: "100%" });
  tl.to(
    labelObject.labelOverlay,
    {
      scaleX: 1,
      transformOrigin: "0% 50%",
      duration: overlayDuration,
    },
    "+=0.1"
  );
  tl.set(labelObject.label, { autoAlpha: 1 });
  tl.to(labelObject.labelOverlay, {
    scaleX: 0,
    transformOrigin: "100% 50%",
    duration: overlayDuration,
  });

  return tl;
}

function _hideLabel(labelObject) {
  const tl = gsap.timeline();
  const overlayDuration = 0.3;

  tl.to(labelObject.labelOverlay, {
    scaleX: 1,
    transformOrigin: "100% 50%",
    duration: overlayDuration,
  });
  tl.set(labelObject.label, { autoAlpha: 0 });
  tl.to(labelObject.labelOverlay, {
    scaleX: 0,
    transformOrigin: "0% 50%",
    duration: overlayDuration,
  });
  tl.to(labelObject.leaderLine, { drawSVG: "0%" });

  return tl;
}

function _revealCaption(labelObject) {
  const tl = gsap.timeline();
  const captionDuration = 0.3;

  for (let i = 0; i < labelObject.captionOverlay.children.length; i++) {
    tl.to(labelObject.captionOverlay.children[i], {
      scaleX: 0,
      position: "absolute",
      transformOrigin: "100% 50%",
      duration: captionDuration,
    });
  }

  return tl;
}

function _hideCaption(labelObject) {
  const tl = gsap.timeline();
  const captionDuration = 0.3;

  tl.to(labelObject.captionOverlay.children, {
    scaleX: 1,
    transformOrigin: "100% 50%",
    duration: captionDuration,
  });

  return tl;
}

// tweens to center the view on each spacecraft component
function _getZoomTweens(svgElement) {
  const duration = 1.2;
  const scale = 1.66;
  const zoomInEase = "Power2.inOut";
  const zoomOutEase = "Power2.inOut";
  const IP_Target = gsap.to(svgElement, {
    scale: scale,
    yPercent: 35,
    xPercent: 0,
    ease: zoomInEase,
    duration: duration,
  });
  const HVM_Target = gsap.to(svgElement, {
    scale: 1.5,
    yPercent: 20,
    xPercent: -25,
    ease: zoomInEase,
    duration: duration,
  });
  const MA_Target = gsap.to(svgElement, {
    scale: 1.3,
    yPercent: 10,
    xPercent: -3,
    ease: zoomInEase,
    duration: duration,
  });
  const PA_Target = gsap.to(svgElement, {
    scale: scale,
    yPercent: 10,
    xPercent: -2,
    ease: zoomInEase,
    duration: duration,
  });
  const RTG_Target = gsap.to(svgElement, {
    scale: 1.25,
    yPercent: -30,
    xPercent: 17,
    ease: zoomInEase,
    duration: duration,
  });
  const centerTheView = gsap.to(svgElement, {
    scale: 1,
    xPercent: 0,
    yPercent: 0,
    duration: duration,
    ease: zoomOutEase,
  });

  return {
    IP_Target: IP_Target,
    HVM_Target: HVM_Target,
    MA_Target: MA_Target,
    PA_Target: PA_Target,
    RTG_Target: RTG_Target,
    centerTheView: centerTheView,
  };
}

export default SpaceshipDiagram;
