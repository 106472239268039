import React from "react";
import { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  title: {
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "37px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "45px",
    },
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  imageCard: {
    height: "100%",
  },
  imageCell: {},
  image: {
    minWidth: "150px",
    width: "100%",
    minHeight: "150px",
    height: "100%",
  },
  mediumUp: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function IconRow() {
  const containerRef = React.useRef();
  const titleRef = React.useRef();
  const imageGridContainerRef = React.useRef();

  let blocks = [];
  const numberOfBlocks = 12;
  for (let i = 0; i < numberOfBlocks; i++) {
    blocks.push(`key-${i}`);
  }
  let blockRefs = [];

  useEffect(() => {
    // elements
    const titleElement = titleRef.current;
    const imageGridContainer = imageGridContainerRef.current;
    const gridBlocks = blockRefs.map((block) => block.current);

    // animate
    animateTitle(titleElement);
    animateGridBlocks(gridBlocks, imageGridContainer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <Container ref={containerRef} className={classes.root}>
      <Grid container justify="center" spacing={1}>
        <Typography ref={titleRef} className={classes.title} variant="h2">
          staggered reveal
        </Typography>
        <Grid
          ref={imageGridContainerRef}
          container
          item
          justify="center"
          alignItems="center"
          xs={12}
          md={10}
          lg={8}
          spacing={3}
        >
          {blocks.map((elementKey, i) => {
            const newRef = React.createRef();
            blockRefs.push(newRef);
            return (
              <Grid
                item
                key={elementKey}
                ref={newRef}
                className={
                  i > 5
                    ? [classes.mediumUp, classes.imageCell].join(" ")
                    : classes.imageCell
                }
                xs={6}
                md={3}
              >
                <Card elevation={0} className={classes.imageCard}>
                  <CardMedia className={classes.image} image={makeImageURL()} />
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
}

export default IconRow;

function animateTitle(title) {
  const scrollDuration = window.innerHeight * 0.4;
  const mainTL = gsap.timeline();
  mainTL.from(title, { autoAlpha: 0, x: "-=20" });
  ScrollTrigger.create({
    animation: mainTL,
    trigger: title,
    start: "top 60%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });
}

function animateGridBlocks(blocks, blockContainer) {
  const scrollMin = 400;
  const scrollMax = window.innerHeight * 0.4;
  const scrollDuration = Math.max(scrollMin, scrollMax);
  const mainTL = gsap.timeline();
  mainTL.from(blocks, {
    autoAlpha: 0,
    y: "+=20",
    stagger: 0.1,
  });
  ScrollTrigger.create({
    animation: mainTL,
    trigger: blockContainer,
    start: "top 55%",
    end: "+=" + scrollDuration,
    scrub: 0.5,
    toggleActions: "play reset play reset",
  });
}

function makeImageURL() {
  const imageURLPrefix = "https://picsum.photos/seed/";
  const imageURLSuffix = "/150?grayscale";
  return imageURLPrefix + Math.random() + imageURLSuffix;
}
